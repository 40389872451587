/* NODE PACKAGES */
import React from'react';

const Page: React.FC<{children?: React.ReactNode}> = (props) =>
  {
  return <div id="page" className="d-block bg-transparent m-0 p-0">
    {props.children}
    </div>
  }

export default Page;
