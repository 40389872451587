/* NODE PACKAGES */
import React from 'react';
import {Form, FloatingLabel} from 'react-bootstrap';

/* CUSTOM COMPONENTS */
import {APITypedAttribute} from 'api/types';

// const validateEntity = (entity: string): boolean =>
//   {
//   const regex = /^[a-zA-Z0-9-]+$/;
//   return regex.test(entity);
//   };

const validateZipCode = (zip: string): boolean =>
  {
  const regex = /\d{5}-\d{4}|\d{5}/;
  return regex.test(zip);
  };

const validatePhoneNumber = (phone: string): boolean =>
  {
  const regex = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/;
  return regex.test(phone);
  };

const validateEmail = (email: string): boolean =>
  {
  const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  return regex.test(email);
  };

const validateDomain = (domain: string): boolean =>
  {
  const regex = /^([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
  return regex.test(domain);
  };

const validateNotEmpty = (text: string): boolean =>
  {
  const regex = /^[a-zA-Z0-9-]+/;
  return regex.test(text);
  };

interface Props
  {
  value: string | undefined;
  definition: APITypedAttribute;
  eventChange: (key: number, value: string) => void;
  disabled?: boolean;
  required?: boolean;
  validate?: (value: string) => boolean;
  }

function TextBox(props:Props)
  {
  const cssValidationTooltip = "position-absolute top-0 start-50 translate-middle text-nowrap";
  return (!props) ? null : <FloatingLabel key={`${props.definition.type}_${props.definition.attribute.id}`} controlId={`${props.definition.type}_${props.definition.attribute.id}`} label={props.definition.attribute.name} className="d-block position-relative w-100">
    <Form.Control
      type="text"
      placeholder={props.definition.attribute.name}
      value={props.value ?? ""}
      onChange={(e) => props.eventChange(props.definition.attribute.id, e.target.value)}
      disabled={props.disabled ?? false}
      required={props.required ?? false}
      isValid={props.validate && props.value ? props.validate(props.value) : !props.required}
      isInvalid={props.validate && props.value ? !props.validate(props.value) : props.required}
      />
    <Form.Control.Feedback className={cssValidationTooltip} type='valid' tooltip>Excellent!</Form.Control.Feedback>
    <Form.Control.Feedback className={cssValidationTooltip} type='invalid' tooltip>Error: Invalid Input</Form.Control.Feedback>
    </FloatingLabel>
  }

export default TextBox;
