/* NODE PACKAGES */
import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { AuthToken }from 'hooks/useAxios';

interface AuthModalProps
  {
  onAuthSuccess: () => void;
  onAuthFailure: () => void;
  }

const AuthModal: React.FC<AuthModalProps> = ({ onAuthSuccess , onAuthFailure }) =>
  {
  const [validated    , setValidated   ] = useState(false);
  const [password     , setPassword    ] = useState('');
  const [modalVisible , setModalVisible] = useState(false);

  const initializeFromCache = React.useCallback(() =>
    {
    const cachedPassword = AuthToken.use();
    if (cachedPassword)
      {
      setValidated(true);
      setPassword(cachedPassword);
      handleClose();
      }
    else
      {
      setModalVisible(true);
      }
    }, []);

  useEffect(() =>
    {
    initializeFromCache();
    },[]);

  useEffect(() =>
    {
    (validated) ? onAuthSuccess() : onAuthFailure();
    },[validated, onAuthSuccess, onAuthFailure]);

  useEffect(() =>
    {
    (password) ? AuthToken.store(password) : AuthToken.clear();
    },[password]);

  // const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => setInputField(e.target.value);

  const handleClose = () =>
    {
    setTimeout(() => {setModalVisible(false)}, 500);
    }

  const handlePass = (value:string) =>
    {
    setValidated(true);
    setPassword(value);
    handleClose();
    }

  const handleBlock = () =>
    {
    setValidated(false);
    setPassword('');
    setModalVisible(true);
    }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) =>
    {
    if (e.key === 'Enter')
      {
      const input = e.target as HTMLInputElement;
      const value = input.value;
      const response = (d:AxiosResponse) => {input.value = value; handlePass(value);}
      const error = (e:AxiosError) => {input.value = ''; handleBlock();}

      AuthToken.client.request({url: '/api/auth_check', headers: {'Authorization': `Bearer ${value}`}, timeout: 3000})
        .then(response)
        .catch(error)
        .finally(() => console.log('/api/auth_check'));
      }
    }

  return (<Modal show={modalVisible} onHide={handleClose} autoFocus={false} size='sm' centered={true} backdrop='static' keyboard={false} restoreFocus={true} enforceFocus={true} animation={true}>
    <Modal.Body>
      <Form noValidate validated={validated} onSubmit={(e) => {e.preventDefault(); e.stopPropagation();}}>
      <Form.Text><div className="p-3 text-center text-muted"><i className="bi bi-database-lock fs-1"></i></div></Form.Text>
      <Form.Control type="text" id="inputUsername" autoComplete="username" placeholder="" aria-describedby="usernameHelpBlock" disabled={true} hidden={true} />
      <Form.Control type="password" id="inputPassword" autoComplete="new-password" defaultValue={password} placeholder="Enter password" isValid={validated} isInvalid={!validated} onKeyUp={handleKeyPress} aria-describedby="passwordHelpBlock" />
      <Form.Control.Feedback type="valid"><div className="p-0 mt-2 text-center">Access Granted!</div></Form.Control.Feedback>
      <Form.Control.Feedback type="invalid"><div className="p-0 mt-2 text-center">Type a password and press Enter key.</div></Form.Control.Feedback>
      </Form>
      </Modal.Body>
    </Modal>);
  };

export default AuthModal;


// Example Usage:
//
// import React, { useState } from 'react';
// import AuthModal from './AuthModal';
//
// const App: React.FC = () =>
//   {
//   const [authenticated, setAuthenticated] = useState<boolean>(false);
//   const handleAuthSuccess = () => setAuthenticated(true); // Callback function to update authentication state
//
//   return (
//     <div className="app">
//       <h1>Your App</h1>
//       {/* Other components and content of your app */}
//
//       {/* Use AuthModal component */}
//       {!authenticated && <AuthModal onAuthSuccess={handleAuthSuccess} />}
//
//       {/* Render the rest of your app content */}
//       </div>
//     );
//   };
//
// export default App;
