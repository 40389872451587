/* NODE PACKAGES */
import React from 'react';

///////////////////////////////////////
// CUSTOM TYPE
///////////////////////////////////////

export type DragItem =
  {
  ID: number;
  title: string;
  category: string;
  }

///////////////////////////////////////
// DRAGGABLE
///////////////////////////////////////

interface Drag
  {
  item: DragItem;
  eventDragged: (id:number) => void;
  children?: React.ReactNode;
  }

function Draggable (props:Drag)
  {
  const eventDragStart = React.useCallback((e: React.DragEvent<HTMLDivElement>) =>
    {
    props.eventDragged(props.item.ID);
    }, []);

  return <div className="d-flex flex-column justify-content-between bg-transparent text-dark m-0 p-0 pb-1 border-0" style={{cursor: 'move'}} draggable onDragStart={eventDragStart}>
    <div className="d-flex justify-content-between align-items-center bg-dark text-light m-0 px-2 py-1 border-0 rounded-top-3">
      <span className="d-block m-0 p-1 border-0">{props.item.title}</span>
      <span className="d-block m-0 px-2 py-0 border border-dark rounded-2 fw-bold bg-secondary text-light"><small>{props.item.category}</small></span>
      </div>
    <div className={"d-flex flex-row justify-content-between align-items-center gap-3 m-0 p-2 border border-secondary bg-white text-dark rounded-bottom-3"}>
      {props.children ? props.children : <span className="m-0 p-2 bg-danger text-light">{props.item.ID} = {props.item.title}</span>}
      </div>
    </div>
  }

export default Draggable;

