/* NODE PACKAGES */
import _, { get } from 'lodash';
import axios, {AxiosRequestConfig, AxiosResponse, AxiosError} from 'axios';

/* TYPES */
import {APIDictionary, APIPolicy, APIRule, APIRuleAttribute, PolicyListItem, APIRegistrantFacts, APIRegistration, NamedItem,  APIRegistrarGroup, APIRegistrarGroupMembers, APIRegistrar, APIRequesterGroupMembership, APIRequesterGroup, APIRequester, APIRegistryList, APIRequestTemplate, APIMatchedRegistration} from "api/types";
import {callAPI } from "hooks";
/* UTILITIES */
import { redirect } from "common/window";

/////////////////////////////////////////////////
// DICTIONARY
/////////////////////////////////////////////////

export const getDataDictionary = (): Promise<APIDictionary> => callAPI({method: "GET", url: '/api/data_dictionary'}).then(data => data.data);


/////////////////////////////////////////////////
// POLICIES
/////////////////////////////////////////////////

// CONSTANT: ORG TYPE CATEGORICAL RANKINGS
// HELPER: Defines the ordering of policy organization types for sorting the policy list. Lower numbers appear earlier in the sorted list.
export const ORG_TYPE_ORDERING: { [key in APIPolicy['org_type']]: number } = { "policy_authority": 1, "registrar": 3, "registry": 2, };
/* const ORG_TYPE_ORDERING = { 'Individual': 0, 'Corporation': 1, 'Non-Profit': 2, 'Government': 3, 'Educational Institution': 4, 'Other': 5, } */
// HELPERS: Sorts the given policy list by organization type, using the ordering defined in ORG_TYPE_ORDERING. Policies of the same type are sorted alphabetically by name.
export const sortPolicyList = (policyList: PolicyListItem[]) => policyList.sort((a: PolicyListItem, b: PolicyListItem) => a.org_type === b.org_type ? a.name.localeCompare(b.name) : ORG_TYPE_ORDERING[a.org_type] - ORG_TYPE_ORDERING[b.org_type]);
// HELPER: Finds and returns the APIRule in the given policy that matches the provided elementID, or undefined if no match is found.
export const getRuleForElement = (policy: APIPolicy, elementID: number): APIRule | undefined => policy?.rules?.find((r: APIRule) => r.element_id === elementID);
// HELPER: Gets the attribute from the given rule that matches the provided attribute ID.
export const getAttributeFromRule = (rule: APIRule, attributeID: number): APIRuleAttribute | undefined => rule?.attributes?.find((a: APIRuleAttribute) => a.attribute_id === attributeID);
// HELPER: Filters policy list and returns policies with the designated org_type badge ("registrar")
export const filterPolicyAuthority = (policyList: PolicyListItem[]) => policyList.filter((p:PolicyListItem) => p.org_type === "policy_authority");
export const filterRegistry        = (policyList: PolicyListItem[]) => policyList.filter((p:PolicyListItem) => p.org_type === "registry");
export const filterRegistrar       = (policyList: PolicyListItem[]) => policyList.filter((p:PolicyListItem) => p.org_type === "registrar");
// HELPER: Creates a new policy and redirects to the policy page for the new ID.
export const createPolicy          = async () => createNewPolicy().then((id) => redirect(`/policy/${id}`));

// API CALLS
export const getPolicyList     = ()                     : Promise<PolicyListItem[]> => callAPI({ method: 'GET'   , url: '/api/policies'}                                                           ).then(data => sortPolicyList(data?.policies) ?? [] as PolicyListItem[]);
export const getPolicy         = (policyID : number   ) : Promise<APIPolicy>        => callAPI({ method: 'GET'   , url: `/api/policy/${policyID}`}                                                 ).then(data => data?.policy ?? {} as APIPolicy);
export const getPolicyMetadata = (policies : number[] ) : Promise<PolicyListItem[]> => callAPI({ method: 'GET'   , url: `/api/policies?policy=${(policies.join(","))}`}                            ).then(data => data?.policies ?? [] as PolicyListItem[]);
export const createNewPolicy   = ()                     : Promise<number>           => callAPI({ method: 'PUT'   , url: "/api/policy", }                                                           ).then(data => data?.id ?? -1);
export const newPolicyVersion  = (policyID : number   ) : Promise<number>           => callAPI({ method: 'POST'  , url: `/api/policy/new_version/${policyID}`}                                     ).then(data => data?.id ?? -1);
export const copyPolicy        = (policyID : number   ) : Promise<number>           => callAPI({ method: 'POST'  , url: `/api/policy/copy/${policyID}`}                                            ).then(data => data?.id ?? -1);
export const savePolicy        = (policy   : APIPolicy) : Promise<boolean>          => callAPI({ method: 'POST'  , url: `/api/policy/${policy.id}`, data: JSON.stringify(_.omit(policy, ['tlds']))}).then(data => Boolean(data));
export const deletePolicy      = (policyID : number   ) : Promise<boolean>          => callAPI({ method: 'DELETE', url: `/api/policy/${policyID}`}                                                 ).then(data => Boolean(data));


/////////////////////////////////////////////////
// REGISTRATIONS
/////////////////////////////////////////////////

export const getRegistrations           = (): Promise<APIRegistration[]>                    => callAPI({ method: 'GET', url: '/api/request_query_all' }).then(data => data?.registrations ?? []);
export const getRegistration            = (rfID: number): Promise<APIRegistration>          => callAPI({ method: 'GET', url: `/api/registration/${rfID}`, }).then(data => data?.registration ?? {});
export const createRegistration         = (): Promise<number>                               => callAPI({ method: 'POST', url: `/api/registration_new`, data: JSON.stringify({ id: 0, name: "Untitled Registration", registrant_facts_id: -1, policy_id: -1 } as APIRegistration), }).then(data => data?.id ?? -1);
export const createNewRegistration      = ():Promise<void>                                  => createRegistration().then((id) => redirect(`/registration/${id}`));
export const forkRegistrationFromPolicy = (policy_id:number): Promise<number>               => callAPI({ method: 'POST', url: `/api/registration_new`, data: JSON.stringify({ id: 0, name: "Untitled Registration", registrant_facts_id: -1, policy_id: policy_id } as APIRegistration) }).then(data => data?.id ?? -1);
export const saveRegistration           = (registration: APIRegistration): Promise<boolean> => callAPI({ method: 'POST'  , url: `/api/registration/${registration.id}`, data: JSON.stringify(_.omit(registration, ['tlds'])), }).then(data => Boolean(data));
export const saveNewRegistration        = (registration: APIRegistration): Promise<boolean> => callAPI({ method: 'POST', url: `/api/registration_new`, data: JSON.stringify(registration), }).then(data => Boolean(data));
export const deleteRegistration         = (regid: number): Promise<boolean>                 => callAPI({ method: 'DELETE', url: `/api/registration/${regid}`, }).then(data => Boolean(data));

// const copyRegistrationConfig:AxiosRequestConfiguration = { method: 'POST', url: `/api/registration/copy/${regid}`, };
// export const createNewRegistration = () => newRegistrantFacts().then(facts_id => newRegistration(facts_id).then((id) => redirect(`/registration/${id}`)));


/////////////////////////////////////////////////
// REGISTRARS
/////////////////////////////////////////////////

export const getRegistrarGroupMembers = async (): Promise<APIRegistrarGroupMembers> => callAPI({ method: 'GET', url: '/api/registrar_group_member_query_all' }).then(data => data?.registrar_group_members ?? {} as APIRegistrarGroupMembers);
export const getRegistrar             = async (regID: number): Promise<APIRegistrar> => getRegistrarGroupMembers().then(data => data?.registrars?.filter((reg) => reg.id === regID)[0] ?? {} as APIRegistrar);
export const newRegistrar             = async (): Promise<number> => callAPI({ method: 'PUT', url: `/api/registrar`, data: JSON.stringify({id: 0, name: "Untitled Registrar",} as APIRegistrar)}).then(data => data?.id ?? -1);
export const saveNewRegistrar         = async (registrar: APIRegistrar): Promise<boolean> => callAPI({ method: 'PUT', url: `/api/registrar`, data: JSON.stringify(registrar)}).then(data => Boolean(data));
export const saveRegistrar            = async (registrar: APIRegistrar): Promise<boolean> => callAPI({ method: 'POST', url: `/api/registrar/${registrar.id}`, data: JSON.stringify(registrar)}).then(data => Boolean(data));
export const deleteRegistrar          = async (regID: number): Promise<boolean> => callAPI({ method: 'DELETE', url: `/api/registrar/${regID}`}).then(data => Boolean(data));

/////////////////////////////////////////////////
// REGISTRAR GROUPS
/////////////////////////////////////////////////

export const getRegistrarGroups       = async (objID: number, objType: "RqG" | "Rr"): Promise<APIRegistrarGroup[]> => callAPI({ method: 'GET', url: (objType === "Rr") ? `/api/registrar/${objID}/registrar_groups` : `/api/requester_group/${objID}/registrar_groups`}).then(data => data ?? [] as APIRegistrarGroup[]);
export const getRegistrarGroup        = async (registrarGroupID: number): Promise<APIRegistrarGroup> => getRegistrarGroupMembers().then(data => data?.registrar_groups?.find((rrG) => rrG.id === registrarGroupID) ?? {} as APIRegistrarGroup);
export const createRegistrarGroup     = async (description: string, requesterGroupIDs: number[], registrarIDs: number[], templateIDs: number[]): Promise<boolean> => callAPI({ method: 'PUT', url: `/api/registrar_group`, data: JSON.stringify({ description: description, rqGIDs: requesterGroupIDs, rrIDs: registrarIDs, reqTempIDs: templateIDs })}).then(data => Boolean(data));
export const newRegistrarGroup        = async (): Promise<number> => callAPI({ method: 'PUT', url: `/api/registrar_group`, data: JSON.stringify({ description: "Unnamed Registrar Group", } as APIRegistrarGroup)}).then(data => data?.id ?? -1);
export const createNewRegistrarGroup  = ():Promise<void> => newRegistrarGroup().then((id) => redirect(`/registrar_group/${id}`));
export const saveRegistrarGroup       = async (registrarGroup: APIRegistrarGroup): Promise<boolean> => callAPI({ method: 'POST', url: `/api/registrar_group/${registrarGroup.id}`, data: JSON.stringify(registrarGroup)}).then(data => Boolean(data));
export const deleteRegistrarGroup     = async (registrarGroupID: number): Promise<boolean> => callAPI({ method: 'DELETE', url: `/api/registrar_group/${registrarGroupID}`}).then(data => Boolean(data));
export const joinRegistrarGroup       = async (memberType: "rqG" | "rr" | "reqTemp", memberID: number, rrGID: number, currentMembers: APIRegistrarGroup): Promise<boolean> => callAPI({ method: 'PUT', url: `/api/registrar_group_membership/${rrGID}`, data: JSON.stringify({memberType: memberType, memberID: memberID, currentMembers: currentMembers,})}).then(data => Boolean(data));
export const removeFromRegistrarGroup = async (memberType: "rqG" | "rr" | "reqTemp", memberID: number, rrGID: number): Promise<boolean> => callAPI({ method: 'DELETE', url: `/api/registrar_group_membership/${rrGID}`, data: JSON.stringify({ memberType: memberType, memberID: memberID })}).then(data => Boolean(data));

export const addMembers = async (selection: APIRegistrarGroup, currentMembers: APIRegistrarGroup): Promise<boolean> =>
  {
  const addRqGs     = selection . requester_group_ids  . map ((selectID) => joinRegistrarGroup("rqG"    , selectID, currentMembers.id, currentMembers));
  const addRrs      = selection . registrar_ids        . map ((selectID) => joinRegistrarGroup("rr"     , selectID, currentMembers.id, currentMembers));
  const addReqTemps = selection . request_template_ids . map ((selectID) => joinRegistrarGroup("reqTemp", selectID, currentMembers.id, currentMembers));
  const addAll      = addRqGs.concat(addRrs).concat(addReqTemps);
  try
    {
    await Promise.all(addAll);
    console.log("Successfully added members to registrar group.");
    return true;
    }
  catch (error:any)
    {
    console.error("Failed to add members to registrar group." , error);
    return false;
    }
  }

export const removeMembers = async (selection: APIRegistrarGroup): Promise<boolean> =>
  {
  const removeRqGs     = selection . requester_group_ids  . map ((selectID) => removeFromRegistrarGroup("rqG"    , selectID, selection.id));
  const removeRrs      = selection . registrar_ids        . map ((selectID) => removeFromRegistrarGroup("rr"     , selectID, selection.id));
  const removeReqTemps = selection . request_template_ids . map ((selectID) => removeFromRegistrarGroup("reqTemp", selectID, selection.id));
  const removeAll      = removeRqGs.concat(removeRrs).concat(removeReqTemps);

  try
    {
    await Promise.all(removeAll);
    console.log(`Successfully removed members from registrar group: ${selection.id}`);
    return true;
    }
  catch (error:any)
    {
    console.error(`Failed to remove members from registrar group: ${selection.id}`, error);
    return false;
    }
  }

/////////////////////////////////////////////////
// REQUESTERS
/////////////////////////////////////////////////

export const createNewRequester = ():Promise<void> => newRequester().then((id) => redirect(`/requester/${id}`));
export const newRequester       = (): Promise<number> => callAPI({ method: 'PUT', url: `/api/requester`, data: JSON.stringify({id: 0, name: "Unnamed Requester"} as APIRequester)}).then(data => data?.id ?? -1);
export const getRequester       = async (requesterID: number): Promise<APIRequester> => getRegistrarGroupMembers().then(data => data?.requesters?.filter(req => req.id === requesterID)[0] ?? {} as APIRequester);
export const joinRqG            = async (requesterID: number, rqGID: number, rqGJoined: APIRequesterGroupMembership[]): Promise<boolean> => (rqGJoined.some(rqG => rqG.requester_group_id === rqGID)) ? rejoinRqG(requesterID, rqGID) : joinNewRqG(requesterID, rqGID);
export const getAllRqGs         = async (): Promise<APIRequesterGroup[]> => getRegistrarGroupMembers().then(data => data?.requester_groups ?? [] as  APIRequesterGroup[]);
export const getRqGJoined       = async (requesterID: number): Promise<APIRequesterGroupMembership[]> => callAPI({ method: 'GET', url: `/api/requester/${requesterID}/memberships`}).then(data => data?.requester_group_memberships ?? [] as APIRequesterGroupMembership[]);
export const joinNewRqG         = async (requesterID: number, rqGID: number): Promise<boolean> => callAPI({ method: 'PUT', url: `/api/requester_group_membership/${requesterID}/${rqGID}`}).then(data => Boolean(data));
export const rejoinRqG          = async (requesterID: number, rqGID: number): Promise<boolean> => callAPI({ method: 'POST', url: `/api/requester_group_membership/${requesterID}/${rqGID}`}).then(data => Boolean(data));
export const leaveRqG           = async (requesterID: number, rqGID: number): Promise<boolean> => callAPI({ method: 'DELETE', url: `/api/requester_group_membership/${requesterID}/${rqGID}`}).then(data => Boolean(data));
export const saveNewRequester   = (requester: APIRequester): Promise<boolean> => callAPI({ method: 'PUT', url: `/api/requester`, data: JSON.stringify(requester)}).then(data => Boolean(data));
export const saveRequester      = (requester: APIRequester): Promise<boolean> => callAPI({ method: 'POST', url: `/api/requester/${requester.id}`, data: JSON.stringify(requester)}).then(data => Boolean(data));
export const deleteRequester    = (requesterID: number): Promise<boolean> => callAPI({ method: 'DELETE', url: `/api/requester/${requesterID}`}).then(data => Boolean(data));


/////////////////////////////////////////////////
// REQUESTER GROUPS
/////////////////////////////////////////////////

export const createRequesterGroup = ():Promise<void> => newRqG().then((id) => redirect(`/requester_group/${id}`));
export const newRqG               = (): Promise<number> => callAPI({ method: 'PUT', url: `/api/requester_group`, data: JSON.stringify({id: 0, name: "Unnamed Requester Group", obligations: "",} as APIRequesterGroup)}).then(data => data?.id ?? -1);
export const getRqG               = async (rqGID: number): Promise<APIRequesterGroup> => getRegistrarGroupMembers().then(data => data?.requester_groups?.filter(rqG => rqG.id === rqGID)[0] ?? {} as APIRequesterGroup);
export const getRqGMembers        = async (rqGID: number): Promise<APIRequesterGroupMembership[]> => callAPI({ method: 'GET', url: `/api/requester_group/${rqGID}/memberships`}).then(data => data?.requester_group_memberships ?? [] as APIRequesterGroupMembership[]);
export const saveNewRqG           = async (requesterGroup: APIRequesterGroup): Promise<boolean> => callAPI({ method: 'PUT', url: `/api/requester_group`, data: JSON.stringify(requesterGroup)}).then(data => Boolean(data));
export const saveRqG              = async (requesterGroup: APIRequesterGroup): Promise<boolean> => callAPI({ method: 'POST', url: `/api/requester_group/${requesterGroup.id}`, data: JSON.stringify(requesterGroup)}).then(data => Boolean(data));
export const deleteRqG            = async (rqGID: Number): Promise<boolean> => callAPI({ method: 'DELETE', url: `/api/requester_group/${rqGID}`}).then(data => Boolean(data));


/////////////////////////////////////////////////
// REGISTRANT FACTS
/////////////////////////////////////////////////

export const getRegistrantFactsList = (): Promise<NamedItem[]> => callAPI({ method: 'GET', url: `/api/registrant_facts_index`}).then(data => data ? data?.registrants?.sort((a:NamedItem, b:NamedItem) => a?.name?.localeCompare(b.name)) : [] as NamedItem[]);
export const newRegistrantFacts     = (): Promise<number> => callAPI({ method: 'POST', url: `/api/registrant_facts_new`, data: JSON.stringify({id: 0, name: "Untitled Registrant Facts Set", facts: []} as APIRegistrantFacts)}).then(data => data?.id ?? -1);
export const getRegistrantFacts     = (rfID: number): Promise<APIRegistrantFacts> => callAPI({ method: 'GET', url: `/api/registrant_facts/${rfID}`}).then(data => data?.registrant_facts ?? {} as APIRegistrantFacts);
export const saveRegistrantFacts    = (facts: APIRegistrantFacts): Promise<boolean> => callAPI({ method: 'POST', url: `/api/registrant_facts/${facts.id}`, data: JSON.stringify(facts)}).then(data => Boolean(data));
export const deleteRegistrantFacts  = (rfID: number): Promise<boolean> => callAPI({ method: 'DELETE', url: `/api/registrant_facts/${rfID}`}).then(data => Boolean(data));


/////////////////////////////////////////////////
// REQUEST TEMPLATES
/////////////////////////////////////////////////

export const getRequestTemplate       = (requestID: number): Promise<APIRequestTemplate> => callAPI({ method: 'GET', url: `/api/request_template/${requestID}`}).then(data => data?.request_template ?? {} as APIRequestTemplate);
export const createNewRequestTemplate = async (): Promise<void> => newRequestTemplate().then((id) => redirect(`/#/request_template/${id}`));
export const newRequestTemplate       = async (): Promise<number> => callAPI({ method: 'POST', url: `/api/request_template_new`, data: JSON.stringify({ id: 0, name: "Untitled Template", attributes: []} as APIRequestTemplate)}).then(data => data?.id ?? -1);
export const copyRequestTemplate      = ( requestTemplate: APIRequestTemplate ): Promise<boolean> => callAPI({ method: 'POST', url: `/api/request_template_new`, data: JSON.stringify(requestTemplate)}).then(data => Boolean(data));
export const saveRequestTemplate      = ( requestTemplate: APIRequestTemplate ): Promise<boolean> => callAPI({ method: 'POST', url: `/api/request_template/${requestTemplate.id}`, data: JSON.stringify(requestTemplate)}).then(data => Boolean(data));
export const deleteRequestTemplate    = async (rfid: number): Promise<boolean> => callAPI({ method: 'DELETE', url: `/api/request_template/${rfid}`}).then(data => Boolean(data));


/////////////////////////////////////////////////
// REQUEST EXECUTIONS
/////////////////////////////////////////////////

export const executeRequestQuery = async ( domain: string, contact: string, email: string ): Promise<APIMatchedRegistration[]> => callAPI({ method: 'GET', url: `/api/request_query_execute?domain=${domain}&contact=${contact}&email=${email}`}).then(data => data?.registrations ?? [] as APIMatchedRegistration[]);


/////////////////////////////////////////////////
// REGISTRY LIST
/////////////////////////////////////////////////

export const parseTextList      = (rawText: string): string[] => _.uniq(rawText.split(/[\s,]+/).filter(t => t.length > 0));
export const getRegistryLists   = async (): Promise<APIRegistryList[]> => callAPI({ method: 'GET', url: `/api/tld_lists`}).then(data => data?.tld_lists?.sort((a:APIRegistryList, b:APIRegistryList) => a?.name?.localeCompare(b.name)) ?? [] as APIRegistryList[]);
export const createRegistryList = ():Promise<void> => postRegistryList({id: 0, name: "Untitled PSL", tlds: parseTextList("domain.com")}).then((newID) => redirect(`/registry/${newID}`));
export const postRegistryList   = async (tldList: APIRegistryList): Promise<number> => callAPI({ method: 'POST', url: `/api/tld_list`, data: JSON.stringify(tldList)}).then(data => data?.tld_list_id ?? -1);
export const deleteRegistryList = async (tldID: number): Promise<number> => callAPI({ method: 'DELETE', url: `/api/tld_list/${tldID}`}).then(data => data?.tld_list_id ?? -1);

