/* NODE PACKAGES */
import React from 'react';
import {Form, FloatingLabel} from 'react-bootstrap';
/* CUSTOM COMPONENTS */
import {APITypedAttribute} from 'api/types';

const validateDate = (date: string): boolean =>
  {
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  return regex.test(date);
  };

export const timestamp_current = new Date().toISOString().substring(0, 10);

interface Props
  {
  value: string | undefined;
  eventChange: (key: number, value: string) => void;
  definition: APITypedAttribute;
  disabled?: boolean;
  required?: boolean;
  }

function DateBox(props:Props)
  {
  // React.useEffect(() =>
  //   {
  //   if (props.value === undefined)
  //     {
  //     props.eventChange(props.definition.attribute.id, timestamp_current);
  //     }
  //   }, [props.value]);

  const eventUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {props.eventChange(props.definition.attribute.id, e.target.value);}

  const cssValidationTooltip = "position-absolute top-0 start-50 translate-middle text-nowrap";
  return (!props) ? null : <FloatingLabel key={`date_${props.definition.attribute.id}`} controlId={`${props.definition.attribute.id}`} label={props.definition.attribute.name} className="d-block position-relative w-100">
    <Form.Control
      type="date"
      placeholder={props.definition.attribute.name}
      value={props.value ?? ""}
      onChange={eventUpdate}
      disabled={props.disabled}
      required={props.required ?? false}
      isValid={(props.value ? props.value.trim().length > 0 || validateDate(props.value) : !props.required) ? true : false}
      isInvalid={(props.value ? props.value.trim().length <= 0 || !validateDate(props.value) : props.required) ? true : false}
      />
    <Form.Control.Feedback className={cssValidationTooltip} type='valid' tooltip>Excellent!</Form.Control.Feedback>
    <Form.Control.Feedback className={cssValidationTooltip} type='invalid' tooltip>Error: Invalid Date</Form.Control.Feedback>
    </FloatingLabel>
  }

export default DateBox;
