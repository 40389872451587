/* NODE PACKAGES */
import React from 'react';
import {Form, FloatingLabel} from 'react-bootstrap';

/* CUSTOM COMPONENTS */
import {APITypedAttribute} from 'api/types';

interface Props
  {
  value: string | undefined;
  eventChange: (key: number, value: string) => void;
  definition: APITypedAttribute;
  rows?: number;
  disabled?: boolean;
  required?: boolean;
  }

function NoteBox(props:Props)
  {
  const cssValidationTooltip = "position-absolute top-0 start-50 translate-middle text-nowrap";
  return <FloatingLabel key={`${props.definition.type}_${props.definition.attribute.id}`} controlId={`${props.definition.type}_${props.definition.attribute.id}`} label={props.definition.attribute.name} className="d-block position-relative w-100">
    <Form.Control
      as="textarea"
      rows={props.rows ?? 3}
      placeholder={props.definition.attribute.name}
      value={props.value ?? ""}
      onChange={(e) => props.eventChange(props.definition.attribute.id, e.target.value)}
      disabled={props.disabled ?? false}
      required={props.required ?? false}
      isValid={(props.value ? props.value.trim().length > 0 : !props.required) ? true : false}
      isInvalid={(props.value ? props.value.trim().length <= 0 : props.required) ? true : false}
      />
    <Form.Control.Feedback className={cssValidationTooltip} type='valid' tooltip>Excellent!</Form.Control.Feedback>
    <Form.Control.Feedback className={cssValidationTooltip} type='invalid' tooltip>Error: Invalid Input</Form.Control.Feedback>
    </FloatingLabel>
  }

export default NoteBox;
