/* NODE PACKAGES */
import React from 'react';
import { AxiosRequestConfig } from 'axios';
/* TYPES */
import { APIRequestTemplate } from 'api/types';
/* HOOKS */
import {useAxios} from 'hooks';

export type RequestTemplateListStore =
  {
  loading: boolean;
  error:any;
  data: APIRequestTemplate[] | null;
  refresh: () => void;
  };

function useRequestTemplateListStore(): RequestTemplateListStore
  {
  const [response, error, loading, callAxios] = useAxios({ method: 'GET', url: "/api/request_templates", });
  const [data, setData] = React.useState<APIRequestTemplate[]>([]);

  React.useEffect(() =>
    {
    if (response) setData(response.request_templates?.sort((a:APIRequestTemplate, b:APIRequestTemplate) => a.name.localeCompare(b.name)) ?? []);
    }, [response]);

  const refresh = () => callAxios();

  return {loading, error, data, refresh} as RequestTemplateListStore;
  }

export default useRequestTemplateListStore;


