/* NODE PACKAGES */
import React from 'react';
import {Stack, Button} from 'react-bootstrap';
/* TYPES */
import { APIAttribute, APIRuleAttribute, APIRule, Comparison} from 'api/types';
import { RULE_ATTRIBUTES, Collection } from './PolicyRule';
/* UTILITIES */
import { getAttributeFromRule } from 'api/utility';
/* CUSTOM COMPONENTS */
import DataBox from 'components/atoms/Inputs/DataBox';

///////////////////////////////
// Policy Rule Cell
///////////////////////////////

interface PolicyRuleCellProps
  {
  rule: APIRule;
  comparisonRule?: APIRule | null;
  attribute: APIAttribute;
  onChange: (newVal: number) => void;
  }

// split this logic out of PolicyRule so that it's easier to reuse when displaying two policies side by side
export function PolicyRuleCell(props: PolicyRuleCellProps)
  {
  if (props.attribute.id !== RULE_ATTRIBUTES.COLL)
    {
    const collAtt = getAttributeFromRule(props.rule, RULE_ATTRIBUTES.COLL);
    const cssPlaceholder = "d-block m-0 p-0 border-0 bg-transparent fs-6 lh-1 font-monospace";
    if (collAtt && collAtt.value === Collection.DONOTCOLLECT)
    return <span className={cssPlaceholder}><small>&nbsp;</small></span> // return empty placeholder
    }

  if (!props.comparisonRule)
    {
    const ruleAtt = getAttributeFromRule(props.rule, props.attribute.id);
    return <DataBox ruleValue={ruleAtt?.value} attribute={props.attribute} onChange={props.onChange} />
    }

  if (props.comparisonRule)
    {
    const ruleAtt = getAttributeFromRule(props.rule, props.attribute.id);
    const comparisonRuleAtt = getAttributeFromRule(props.comparisonRule, props.attribute.id);
    const ruleValue : number = ruleAtt?.value ?? 0;
    const comparisonValue: number = comparisonRuleAtt?.value ?? 0;
    return <DataBox ruleValue={ruleValue} comparisonValue={comparisonValue} attribute={props.attribute} onChange={props.onChange} />
    }
  }



