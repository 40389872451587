/* NODE PACKAGES */
import React from 'react';
import _, { get, set, update } from 'lodash';
import { Axios } from 'axios';
/* TYPES */
import {APIDictionary, APIRequestTemplate, APIRequestTemplateItem, APIRequestTemplateRole, APITypedAttribute, APIAttributeOption, APIMatchedRegistration} from 'api/types';
import {executeRequestQuery, saveRequestTemplate, deleteRequestTemplate} from 'api/utility';
/* HOOKS */
import {useAxios, TemplateAttributes} from 'hooks';
/* UTILITY */
import { redirect } from "common/window";


const __TEMPLATE_NAME: string = "Untitled Template";
const __VALUE: string = "";
const __REQUIRED: boolean = false;
const __ROLE: APIRequestTemplateRole = "user";

export type RequestTemplateStore =
  {
  loading: boolean;
  error:any;
  data: APIRequestTemplate[] | null;
  };

interface RequestTemplateProps
  {
  selectedTemplateID: number;
  dataDictionary: APIDictionary;
  }

function useRequestTemplateStore (props: RequestTemplateProps)
  {
  const [axiosData, axiosError, axiosLoading] = useAxios({ url: `/api/request_template/${props.selectedTemplateID}`, method: 'GET', });
  const [data, setData] = React.useState<APIRequestTemplate | null>(null);
  const [dataTitle, setDataTitle] = React.useState<string>(__TEMPLATE_NAME);
  const [dataFields, setDataFields] = React.useState<Map<number, string>>(new Map<number, string>());
  const [dataRequired, setDataRequired] = React.useState<Map<number, boolean>>(new Map<number, boolean>());
  const [dataRoles, setDataRoles] = React.useState<Map<number, "user" | "administrator">>(new Map<number, "user" | "administrator">());
  const [unsavedChanges, setUnsavedChanges] = React.useState<boolean>(false);

  React.useEffect(() =>
    {
    if (!axiosData) return;
    setData(axiosData.request_template);
    setDataTitle(axiosData.request_template.name);
    setDataFields(new Map<number, string>(axiosData.request_template.attributes?.map((i: APIRequestTemplateItem) => [i.attribute_id, i.value ?? __VALUE]) ?? []));
    setDataRequired(new Map<number, boolean>(axiosData.request_template.attributes?.map((i: APIRequestTemplateItem) => [i.attribute_id, i.required ?? __REQUIRED]) ?? []));
    setDataRoles(new Map<number, "user" | "administrator">(axiosData.request_template.attributes?.map((i: APIRequestTemplateItem) => [i.attribute_id, i.role ?? __ROLE]) ?? []));
    setUnsavedChanges(false);
    }, [axiosData]);

  React.useEffect(() => console.log("» store » apiData: ", axiosData), [axiosData]);
  React.useEffect(() => console.log("» store » data: ", data), [data]);
  React.useEffect(() => console.log("» store » dataTitle: ", dataTitle), [dataTitle]);
  React.useEffect(() => console.log("» store » dataFields: ", dataFields), [dataFields]);
  React.useEffect(() => console.log("» store » dataRequired: ", dataRequired), [dataRequired]);
  React.useEffect(() => console.log("» store » dataRoles: ", dataRoles), [dataRoles]);
  React.useEffect(() => console.log("» store » unsavedChanges: ", unsavedChanges), [unsavedChanges]);

  const setTitle = (value:string) =>
    {
    if (!value.trim() || dataTitle === value) return;
    setDataTitle(value);
    updateData({name: value});
    setUnsavedChanges(true);
    }

  const setField = (key: number,value: string) =>
    {
    setDataFields(new Map(dataFields?.set(key,value)));
    updateData({attributes: compileData()});
    setUnsavedChanges(true);
    }

  const setRequired = (key: number, value: boolean) =>
    {
    setDataRequired(new Map(dataRequired?.set(key,value)));
    updateData({attributes: compileData()});
    setUnsavedChanges(true);
    }

  const setRole = (key: number, value: APIRequestTemplateRole) =>
    {
    setDataRoles(new Map(dataRoles?.set(key, value)));
    updateData({attributes: compileData()});
    setUnsavedChanges(true);
    }

  const compileData = () =>
    {
    const uniqueKeys = new Set<number>([ ...dataFields.keys(), ...dataRequired.keys(), ...dataRoles.keys()]);
    const attributes = Array.from(uniqueKeys).map((key) => ({ attribute_id: key, value: dataFields.get(key) ?? __VALUE, required: dataRequired.get(key) ?? __REQUIRED, role: dataRoles.get(key) ?? __ROLE}));
    return attributes;
    }

  const updateData = (values: Partial<APIRequestTemplate>) =>
    {
    if (!data) return;
    const template = Object.assign({}, data, values);
    setData(template as APIRequestTemplate);
    setUnsavedChanges(true);
    }

  const eventSave = () =>
    {
    if (!data) return;
    saveRequestTemplate(data).then(response => console.log("Saved: ", response ? data : "Oops, something went wrong.")).catch(error => console.error(error)).finally(() => setUnsavedChanges(false));
    }

  const eventDelete = () =>
    {
    if (!data) return
    if (window.confirm('Are you sure you wish to delete this item?')) deleteRequestTemplate(data.id).finally(() => redirect('/requests/'));
    }

  return ({data, unsavedChanges, eventSave, eventDelete, title: dataTitle, setTitle, getField: (id:number) => dataFields.get(id), setField, getRequire: (attributeID: number) => dataRequired.get(attributeID), setRequired, getRole: (attributeID: number) => dataRoles.get(attributeID), setRole});
  };

export default useRequestTemplateStore;
