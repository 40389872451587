/* NODE PACKAGES */
import React from 'react';
import {Spinner, Button, ButtonGroup, Dropdown, DropdownItem, DropdownButton, OverlayTrigger, Tooltip} from 'react-bootstrap';
/* CUSTOM COMPONENTS */
import {CheckBox} from 'components/atoms/Inputs';
import Trash from 'components/atoms/Buttons/Trash';
import Update from 'components/atoms/Buttons/Update';

export const ChecklistHeader: React.FC<{children: React.ReactNode}> = (props) =>
  {
  return <Dropdown.Header className="m-0 px-3 py-2 lh-base">{props.children}</Dropdown.Header>
  }

export const ChecklistItem: React.FC<{eventKey: string, checked: boolean, text: string, onChange: (check: boolean) => void, upgrade?: () => void, remove?: () => void, disabled?: boolean}> = (props) =>
  {
  return <DropdownItem eventKey={props.eventKey} className={"d-flex justify-content-between align-items-center gap-2 m-0 px-3 py-1 lh-base"} style={props.upgrade ? {backgroundColor: 'var(--bs-danger-bg-subtle) !important'} : {}} disabled={props.disabled}>
    <CheckBox checked={props.checked} text={props.text} onChange={props.onChange} />
    <div className="ms-auto"></div>
    {/* {props.upgrade ? <span onClick={props.upgrade}><small>{`upgrade to latest version`}</small></span> : null} */}
    {props.disabled ? null : <Update onClick={props.upgrade} />}
    {props.disabled ? null : <Trash onClick={props.remove} />}
    </DropdownItem>
  }

function Checklist (props: { title: string; tooltip?: string; children: React.ReactNode; disabled?: boolean;})
  {
  return <DropdownButton title={props.title} variant='dark' as={ButtonGroup} autoClose="outside" flip={false} disabled={props.disabled}>
    {props.children}
    </DropdownButton>
    // {props.tooltip
    //   ? <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Tooltip>{props.tooltip}</Tooltip>}><Dropdown.Toggle variant='dark' className="shadow-sm" disabled={props.disabled}>{props.title}</Dropdown.Toggle></OverlayTrigger>
    //   : <Dropdown.Toggle variant='dark' className="shadow-sm" disabled={props.disabled}>{props.title}</Dropdown.Toggle>}
  }

export default Checklist;

///////////////////////////////////////
// UI SUB-COMPONENTS
///////////////////////////////////////

const Tick:React.FC<{checked: boolean}> = (props) => (props.checked) ? <i className="bi bi-check-square text-black"></i> : <i className="bi bi-square text-secondary"></i>

const Label: React.FC<{children: React.ReactNode}> = (props) => <span>{props.children}</span>
