/* NODE PACKAGES */
import React from 'react';
import {Spinner, Table, Container, Row, Col, ButtonToolbar, Stack} from 'react-bootstrap';
/* API */
import { APIAttribute, APIDictionary, APIRegistration, APIElement, APIElementGroup, APIPolicy, APIRule, APIRuleAttribute, PolicyListItem, Comparison} from 'api/types';
import {getAttributeFromRule, getRuleForElement, newPolicyVersion, savePolicy, copyPolicy, deletePolicy, getPolicy, getPolicyMetadata, getPolicyList, saveRegistration} from 'api/utility';
/* HOOKS */
import { useRegistrationListStore } from 'hooks';
/* UTILITIES */
import { getURLHash, checkUnsavedBeforeUnload, restoreOldHashChange, redirect } from "common/window";
//import { exportPolicyToPDF } from 'common/PDFdownload';
/* CUSTOM COMPONENTS */
import CopyPasteTool from 'pages/Policies/modules/CopyPasteTool';
import ImportPolicyModal from 'pages/Policies/modules/ImportPolicyModal';
import PolicyMetaData, {PolicyMetaDataPrinter} from 'pages/Policies/modules/PolicyMetaData';
import FilterDataTool from 'pages/Policies/modules/FilterDataTool';
import ScopeCalculator from 'pages/Policies/modules/ScopeCalculator';
import ComparisonToolbar from 'pages/Policies/modules/ComparisonTool';
//import PolicyDataTable from 'pages/Policies/modules/PolicyDataTable';
import PolicyRule from 'components/organisms/PolicyRule';
import File from 'components/molecules/Menu/File';
import ColorKey from 'components/molecules/Sheet/ColorKey';
import EditableLabel from "components/atoms/EditableText/EditableLabel";
/* TEMPLATES */
import {Page, Header, Main, Section, Print, Footer, Metadata, FlexBox} from "components/atoms/Templates";
import { get, set, update } from 'lodash';
/* ASSETS */
var fileDownload = require('js-file-download');

/* const WRAPPER_FIELDS: keyof APIPolicy = ["org_name", "org_type", "prime_poc", "prime_email", "alt_poc", "alt_email", "effective_date"]; */

///////////////////////////////////////
// POLICY EDITOR
///////////////////////////////////////

interface PolicyEditorProps
  {
  policyID: number;
  dataDictionary: APIDictionary;
  unsavedChanges: (flag: boolean) => void;
  }

export interface PolicyEditorState
  {
  policy: APIPolicy | null;
  comparisonPolicy: APIPolicy | null;
  policyMetadataCache: { [key: number]: PolicyListItem };
  selectedSubjects: number[];
  isLoadingPolicy: boolean;
  rowSelectRange: [number, number] | null;
  hiddenGroupsAttributes: (APIElementGroup | APIAttribute)[];
  importPolicyModal: boolean;
  unsavedChanges: boolean;
  isSaving: boolean;
  }

export default class PolicyEditor extends React.Component<PolicyEditorProps, PolicyEditorState>
  {
  _isMounted = false;

  constructor(props: PolicyEditorProps)
    {
    super(props);
    this.state =
      {
      policy: null, // policy: Null, representing the current policy being edited.
      comparisonPolicy: null, // comparisonPolicy: Null, representing a policy to compare the current policy against.
      policyMetadataCache: {}, // policyMetadataCache: An empty object to cache metadata about policies.
      selectedSubjects: [], // selectedSubjects: An empty array to track the selected subjects for the policy.
      isLoadingPolicy: true, // isLoadingPolicy: True, indicating that the policy is currently being loaded.
      hiddenGroupsAttributes: [], // hiddenGroupsAttributes: An empty array to track any hidden groups or attributes.
      rowSelectRange: null, // rowSelectRange: Null, representing the selected range of rows.
      importPolicyModal: false, // importPolicyModal: False, indicating that the import policy modal is not currently open.
      unsavedChanges: false, // unsavedChanges: False, indicating that there are no unsaved changes to the policy.
      isSaving: false, // isSaving: False, indicating that the policy is not currently being saved.
      };
    }

  // React component lifecycle method called after the component is mounted and inserted into the DOM.
  componentDidMount()
    {
    this._isMounted = true;
    this.fetchPolicy();
    }

  // Component lifecycle method called immediately before a component is unmounted and destroyed.
  // Sets the component status to not mounted, and restores the previous window hash change handler if one was saved.
  componentWillUnmount()
    {
    this._isMounted = false;
    }

  // Called after the component updates. Compares the previous and current props and state.
  // - If the policy ID prop has changed, fetches the updated policy.
  // - Logs the ID of the comparison policy if one is set in state.
  componentDidUpdate(prevProps: PolicyEditorProps, prevState: PolicyEditorState)
    {
    if (this.props.policyID !== prevProps.policyID) this.fetchPolicy();
    }

  mapPolicyIDsToName = (policies: PolicyListItem[]) =>
    {
    const mapping: {[key: number]: PolicyListItem} = {};
    policies.forEach(p => mapping[p.id] = p);
    return mapping;
    }

  // Fetches the policy data for the given policy ID from the API, sets the component state with the policy data and related metadata, and handles setting loading state.
  fetchPolicy = () =>
    {
    const eventPolicyFetch = (newPolicy: APIPolicy | null) =>
      {
      if (newPolicy)
        {
        this.setState({ policy: newPolicy });
        getPolicyMetadata(newPolicy.subject_to).then((newMetadata: PolicyListItem[]) => this.setState({ policyMetadataCache: this.mapPolicyIDsToName(newMetadata) })).catch((error: any) => console.error(error.toJSON()));
        }
      else
        {
        this.setState({ policyMetadataCache: this.mapPolicyIDsToName([]) });
        }
      }

    if (this._isMounted)
      {
      this.setState({ isLoadingPolicy: true });
      getPolicy(this.props.policyID).then(eventPolicyFetch).catch((error: any) => console.error(error.toJSON())).finally(() => {this.setState({ isLoadingPolicy: false, unsavedChanges: false }); this.props.unsavedChanges(this.state.unsavedChanges);});
      }
    }

  // Updates the list of hidden element groups and attributes: @param newHidden - The new list of hidden element groups and attributes.
  updateHiddenGroupsAttributes = (newHidden: (APIElementGroup | APIAttribute)[]) =>
    {
    if (this.state.policy) this.setState({hiddenGroupsAttributes: newHidden });
    };

  // Adds a policy ID to the comparison policy list.
  updateSelectedSubjects = (newSubjects: number[]) =>
    {
    if (this.state.policy) this.setState({selectedSubjects: newSubjects});
    }

  // Caches the metadata for a given policy
  updateMetaDataCache = (newPolicy: PolicyListItem) =>
    {
    if (this.state.policy && !this.state.policyMetadataCache[newPolicy.id])
      {
      const newPolicyMetadataCache = Object.assign({}, this.state.policyMetadataCache);
      newPolicyMetadataCache[newPolicy.id] = newPolicy;
      this.setState({ policyMetadataCache: newPolicyMetadataCache });
      }
    }

  // Updates the comparison policy state with the provided policy.
  // @param p - The new policy to set as the comparison policy, or `null` to clear the comparison policy.
  updateComparisonPolicy = (newComparisonPolicy: APIPolicy | null) =>
    {
    if (this.state.policy) this.setState({ comparisonPolicy: newComparisonPolicy});
    }

  // Updates the current policy state with new values.
  updatePolicy(newValues: Partial<APIPolicy>)
    {
    if (this.state.policy)
      {
      const newPolicy = Object.assign({}, this.state.policy, newValues);
      this.setState({ policy: newPolicy});
      this.updateUnsavedChanges(true);
      }
    }

  // Updates the unsaved changes flag and fires this.props.unsavedChanges callback to keep global state synced with local state
  updateUnsavedChanges = (value: boolean) =>
    {
    this.setState({ unsavedChanges: value });
    this.props.unsavedChanges(value);
    }

  copyPolicyAsNewVersion = () =>
    {
    const policy = this.state.policy;
    if (!policy) return;
    this.setState({ isLoadingPolicy: true });
    newPolicyVersion(policy.id)
      .then(async (newPolicyID) =>
        {
        this.updatePolicy({"version": policy.version + 1});
        this.updatePolicy({"id": newPolicyID});
        if (this.state.unsavedChanges) await savePolicy(Object.assign({}, policy, { id: newPolicyID }));
        return newPolicyID;
        })
      .then((newPolicyID) => redirect(`/policy/${newPolicyID}`))
      .finally(() => this.setState({ isLoadingPolicy: false }));
    };

  eventFlip = (newPolicy: APIPolicy, newComparisonPolicy: APIPolicy | null) =>
    {
    this.setState({policy: newPolicy, comparisonPolicy: newComparisonPolicy});
    getPolicyMetadata(newPolicy.subject_to).then((newMetadata: PolicyListItem[]) => this.setState({ policyMetadataCache: this.mapPolicyIDsToName(newMetadata) })).catch((error: any) => console.error(error.toJSON()));
    }

  eventSave = () =>
    {
    if (!this.state.policy) return;
    this.setState({ isSaving: true });
    savePolicy(this.state.policy).then(() => this.updateUnsavedChanges(false)).finally(() => this.setState({ isSaving: false }));
    }

  eventDelete = () =>
    {
    if (!this.state.policy) return;
    if (window.confirm('Are you sure you wish to delete this item?'))
      {
      this.setState({ isLoadingPolicy: true });
      deletePolicy(this.state.policy.id)
        .then(() => redirect('/policies/'))
        .finally(() => this.setState({ isLoadingPolicy: false }));
      }
    }

  eventCopy = () =>
    {
    if (!this.state.policy) return;
    this.setState({ isLoadingPolicy: true });
    copyPolicy(this.state.policy.id).then((newPolicyID: number) => redirect(`/policy/${newPolicyID}`)).finally(() => this.setState({ isLoadingPolicy: false }));
    }

  eventImport = () =>
    {
    this.setState({ importPolicyModal: true });
    }

  eventExport = () =>
    {
    if (this.state.policy) fileDownload(JSON.stringify(this.state.policy), `${this.state.policy.name}.policy`);
    }

  eventPrintPDF = () => window.print();

  trace = () =>
    {
    console.group("PolicyEditor.states:");
    console.log("policy = ", this.state.policy?.id, this.state.policy?.name);
    console.log("comparisonPolicy = ", this.state.comparisonPolicy);
    console.log("rowSelectRange = ", this.state.rowSelectRange);
    console.log("hiddenGroupsAttributes = ", this.state.hiddenGroupsAttributes);
    console.groupEnd();
    }

  /* Renders the component UI. */
  render()
    {
    if (!this.state.policy || this.state.isLoadingPolicy) return <Spinner animation="border" variant="primary" className="position-absolute top-50 start-50 translate-middle" />;
    else
      {
      const dict = this.props.dataDictionary;
      const policy = this.state.policy;
      const comparisonPolicy = this.state.comparisonPolicy;
      const intersection = comparisonPolicy ? <ul className="list-unstyled fs-6"><h6 className="text-muted">Intersection:</h6>{comparisonPolicy.name.split(" ∩ ").map((i, index) => <li key={`intersection-${index}`}>{i}</li>)}</ul> : null;
      const rowSelectRange = this.state.rowSelectRange;
      const hiddenGroupsAttributes = this.state.hiddenGroupsAttributes;
      const flattenedElements: [APIElementGroup | null, APIElement][] = flattenDictElements(dict);

      /* EVENTS */

      // Check if a given value is within a specified numerical range (inclusive).
      const isWithinRange = (value: number, min: number, max: number) =>
        {
        const [rangeMin, rangeMax] = min < max ? [min, max] : [max, min];
        return value >= rangeMin && value <= rangeMax;
        }

      // Maps over the flattened list of dictionary element groups and elements to render a table row for each element. Each row contains a PolicyRule component to display the rule data for that element. Includes logic to handle row selection, comparison rules, hidden groups/attributes etc.
      const policyRules = flattenedElements.map((item, i) =>
        {
        let eg = item[0];
        let el = item[1];
        let comparisonRule = comparisonPolicy ? getRuleForElement(comparisonPolicy, el.id) ?? null : undefined;
        let rule = getRuleForElement(policy, el.id) ?? { id: 0, element_id: el.id, attributes: [] }; // undefined means we're not comparing, null means we're comparing but we're missing a rule
        let group = (eg !== null) ? { rows: eg.elements.length, name: eg.name } : undefined;
        let isSelected = rowSelectRange !== null && isWithinRange(i, rowSelectRange[0], rowSelectRange[1]);
        let isHidden = isCellHidden(hiddenGroupsAttributes, getElementsGroup(dict, el));
        const eventSelectBegin = () => this.setState({ rowSelectRange: [i, i] });
        const eventSelectEnd = () => {if (rowSelectRange) this.setState({ rowSelectRange: [rowSelectRange[0], i] });}
        const eventSelectClear = () => this.setState({ rowSelectRange: null });
        const eventChange = (elementID: number, attributeID: number, newVal: number) => this.updatePolicy(updatePolicyRuleAttributeValue(policy, elementID, attributeID, newVal));
        const eventChangeNote = (newNote: string) => this.updatePolicy(updatePolicyRuleField(policy, el.id, "notes", newNote));

        return <PolicyRule
          key={el.id}
          dictionary={dict}
          group={group}
          element={el}
          rule={rule}
          comparisonRule={comparisonRule}
          hidden={isHidden}
          hiddenGroupsAttributes={hiddenGroupsAttributes}
          selected={isSelected}
          onSelectBegin={eventSelectBegin}
          onSelectEnd={eventSelectEnd}
          onSelectClear={eventSelectClear}
          onChange={eventChange}
          onNoteChange={eventChangeNote} />
        });

      const policyNotes = this.props.dataDictionary.element_groups.map((eg) => eg.elements.map((el, i) =>
        {
        let rule = getRuleForElement(policy, el.id);
        return (rule && rule.notes) ? <li key={el.id} className="mb-3"><div><strong>{`${eg.name}: ${el.name}`}</strong></div><div className="d-block m-0 p-0 text-wrap">{rule.notes}</div></li> : null;
        }).filter(i => i !== null)).filter(i => i.length > 0);

      const PolicyDataTable = <Table responsive size="sm" className="m-0 p-0" style={{captionSide: 'bottom'}}>
        <tbody className="m-0 p-0">
          {policyRules}
          </tbody>
        <caption className="font-monospace m-0 p-0">
          <div className="text-start fw-bold lh-1 border-bottom border-1 m-0 px-0 pt-4 pb-1">Policy Notes: </div>
          <ul className="d-block m-0 px-4 py-2 border-0 fst-italic">
            {policyNotes.length ? policyNotes : <li className="m-0 p-0">No policy notes found.</li>}
            </ul>
          </caption>
        </Table>;

      return <Page>
        <Header>
          <div><EditableLabel value={policy.name} onValueChange={(newValue: string) => this.updatePolicy({ name: newValue })} /> <small className="text-muted">{`(v.${this.state.policy.version})`}</small></div>
          <div className="text-start mx-auto my-0 p-0 border-0">{intersection}</div>
          <div className="ms-auto"></div>
          <ColorKey />
          <File
            savingFlag={this.state.isSaving}
            dirtyFlag={this.state.unsavedChanges}
            eventSave={this.eventSave.bind(this)}
            eventDelete={this.eventDelete.bind(this)}
            eventCopy={this.eventCopy.bind(this)}
            eventImport={this.eventImport.bind(this)}
            eventExport={this.eventExport.bind(this)}
            eventPrintPDF={this.eventPrintPDF.bind(this)} />
          </Header>
        <Main>
          <Section>
            <Container>
              <Row>
                <Col>
                  <ButtonToolbar className="d-flex flex-nowrap gap-2">
                    <FilterDataTool
                      dictionary={this.props.dataDictionary}
                      hiddenGroupsAttributes={hiddenGroupsAttributes}
                      updateHiddenGroupsAttributes={this.updateHiddenGroupsAttributes.bind(this)} />
                    <ComparisonToolbar
                      dictionary={this.props.dataDictionary}
                      policy={this.state.policy}
                      comparisonPolicy={this.state.comparisonPolicy}
                      selectedSubjects={this.state.selectedSubjects}
                      policyMetadataCache={this.state.policyMetadataCache}
                      updateSelectedSubjects={this.updateSelectedSubjects.bind(this)}
                      updateComparisonPolicy={this.updateComparisonPolicy.bind(this)}
                      updatePolicy={this.updatePolicy.bind(this)}
                      updateMetaDataCache={this.updateMetaDataCache.bind(this)}
                      eventFlip={this.eventFlip.bind(this)}
                      />
                    </ButtonToolbar>
                  </Col>
                </Row>
              </Container>
            </Section>
          <Section>
            <Stack direction="vertical" gap={3}>
              <ScopeCalculator dictionary={dict.scope_attributes} policy={policy} comparisonPolicy={comparisonPolicy} updatePolicy={this.updatePolicy.bind(this)} />
              {PolicyDataTable}
              </Stack>
            </Section>
          <Metadata>
            <PolicyMetaData policy={policy} updatePolicy={this.updatePolicy.bind(this)} eventCopyAsNewVersion={this.copyPolicyAsNewVersion.bind(this)} />
            </Metadata>
          </Main>
        <Footer />
        <Print>
          <div className="page-break">
            <PolicyMetaDataPrinter policy={policy} />
            </div>
          <div className="text-center page-break">
            <Table size="sm" borderless>
              <tbody>
                <tr>
                  <td className="col-6">
                    <h4 className="text-center">{policy.name}</h4>
                    {intersection}
                    </td>
                  <td className="col-6">
                    <h4>Policy Scope</h4>
                    <ScopeCalculator dictionary={dict.scope_attributes} policy={policy} comparisonPolicy={comparisonPolicy} updatePolicy={this.updatePolicy.bind(this)} />
                    </td>
                  </tr>
                </tbody>
              </Table>
            <div className="m-0 p-0">&nbsp;</div>
              <h4>Policy Rules</h4>
              {PolicyDataTable}
              </div>
          </Print>
        <CopyPasteTool range={rowSelectRange} dataDictionary={this.props.dataDictionary} policy={policy} updatePolicy={(policy: APIPolicy) => this.updatePolicy(policy)} />
        <ImportPolicyModal policyID={this.props.policyID} open={this.state.importPolicyModal} closeFunc={() => this.setState({ importPolicyModal: false })} />
        </Page>
        }
    }
  } // </PolicyEditor>


// Retrieve the element group to which an element belongs; @returns The element group that contains the given element, or the first group if not found.
function getElementsGroup(dict: APIDictionary, element: APIElement)
  {
  let desiredGroup = dict.element_groups.find(eg => eg.elements.some(el => el === element));
  if (desiredGroup === undefined) desiredGroup = dict.element_groups[0]; // Default if no group is found
  return desiredGroup;
  }

// Update a specific field of a policy rule in the given policy; if the rule with the specified elementID does not exist, a new rule is created.
function updatePolicyRuleField<T extends keyof APIRule>(policy: APIPolicy, elementID: number, fieldName: T, value: APIRule[T])
  {
  policy = Object.assign({}, policy); // Create a shallow copy of the policy object to avoid mutations
  policy.rules = policy.rules.slice(); // Create a shallow copy of the rules array to avoid mutations
  const ruleIndex = policy.rules.findIndex(r => r.element_id === elementID); // Find the index of the rule with the specified elementID in the policy rules array
  let rule: APIRule; // Declare a variable to hold the rule object

  // Check if the rule with the specified elementID exists;
  // If the rule does not exist, create a new rule object and add it to the policy rules array; Otherwise, clone the existing rule object, Replace the existing rule in the policy rules array with the cloned rule;
  if (ruleIndex === -1)
    {
    rule = { id: 0, element_id: elementID, attributes: [] };
    policy.rules.push(rule);
    }
  else
    {
    rule = Object.assign({}, policy.rules[ruleIndex]);
    policy.rules[ruleIndex] = rule;
    }

  // Update the specified field in the rule with the provided value
  rule[fieldName] = value;

  // Return a new APIPolicy object with the updated rules array
  return policy;
  }


//////////////////////////////////////////////////


// HELPER FUNCTIONS

// Flattens the data dictionary element groups into a list of element group and element pairs.
// The first item in each pair is the element's group or null if the element is not the first in a group.
// The second item is the element itself.
// @returns Array containing pairs of element group and element, or null and element if not in a group.
export function flattenDictElements(dict: APIDictionary)
  {
  const flattenedElements: [APIElementGroup | null, APIElement][] = [];
  dict.element_groups.forEach(eg => eg.elements.forEach((e, i) => {(i === 0) ? flattenedElements.push([eg, e]) : flattenedElements.push([null, e]);}));
  return flattenedElements;
  }

// Update the value of an attribute in a policy rule within the given policy; if the rule or attribute does not exist, it will be created.
export function updatePolicyRuleAttributeValue(policy: APIPolicy, elementID: number, attributeID: number, newVal: number): APIPolicy
  {
  policy = Object.assign({}, policy); // Create a shallow copy of the policy object to avoid mutations
  policy.rules = policy.rules?.slice() ?? []; // Create a shallow copy of the rules array to avoid mutations
  const ruleIndex = policy.rules?.findIndex(r => r.element_id === elementID) ?? -1; // Find the index of the rule with the specified elementID in the policy rules array
  let rule: APIRule; // Declare a variable to hold the rule object

  // Check if the rule with the specified elementID exists
  // - if the rule does not exist, create a new rule object; add the new rule to the policy rules array
  // - if the rule exists, clone the existing rule object; replace the existing rule in the policy rules array with the cloned rule
  if (ruleIndex === -1)
    {
    rule = { id: 0, element_id: elementID, attributes: [] };
    policy.rules.push(rule);
    }
  else
    {
    rule = Object.assign({}, policy.rules[ruleIndex]);
    policy.rules[ruleIndex] = rule;
    }

  rule.attributes = rule.attributes.slice(); // Create a shallow copy of the attributes array within the rule to avoid mutations
  const attributeIndex = rule.attributes.findIndex(a => a.attribute_id === attributeID); // Find the index of the attribute with the specified attributeID in the rule attributes array
  let attribute: APIRuleAttribute; // Declare a variable to hold the attribute object

  // Check if the attribute with the specified attributeID exists: if the attribute does not exist, add a new rule.attribute; if the attribute already exists, replace the existing attribute object with a cloned rule.attribute;
  if (attributeIndex === -1)
    {
    attribute = { attribute_id: attributeID, value: newVal };
    rule.attributes.push(attribute);
    }
  else
    {
    attribute = Object.assign({}, rule.attributes[attributeIndex]);
    rule.attributes[attributeIndex] = attribute;
    }

  attribute.value = newVal; // Update the value of the specified attribute with the provided new value
  return policy; // Return a new APIPolicy object with the updated rules and attribute values
  }

// Update the attributes of a policy rule by calling the updatePolicyRuleField function
export const updatePolicyRuleAttributes = (policy: APIPolicy, elementID: number, newAttributes: APIRuleAttribute[]) => updatePolicyRuleField(policy, elementID, "attributes", newAttributes);

// Check if a given group or attribute is hidden based on a list of hidden groups and attributes
export const isCellHidden = (hiddenCells: (APIElementGroup | APIAttribute)[], groupOrAttribute: APIElementGroup | APIAttribute) => hiddenCells.some((hiddenThing) => hiddenThing === groupOrAttribute);

// given an orig list and an overlay list, return a new list that is overwritten with the selectedAtts from overlay. Used for pasting specific columns.
export function mergeAttributes(orig: APIRuleAttribute[], overlay: APIRuleAttribute[], selectedAtts: number[])
  {
  return orig.filter(att => !selectedAtts.includes(att.attribute_id)).concat(overlay.filter(att => selectedAtts.includes(att.attribute_id)));
  }

