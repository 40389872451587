/* NODE PACKAGES */
import React from 'react';
import {Spinner, Button, ButtonGroup, Dropdown, DropdownItem, DropdownButton, OverlayTrigger, Tooltip} from 'react-bootstrap';
import { set } from 'react-hook-form';

///////////////////////////////////////
// CHECKBOX COMPONENT
///////////////////////////////////////

interface CheckboxProps
  {
  checked: boolean;
  text: string;
  onChange: (check: boolean) => void;
  }

///////////////////////////////////////
// UI COMPONENT
///////////////////////////////////////

function CheckBox (props: {checked: boolean, text: string, onChange: (check: boolean) => void})
  {
  const [checkbox, setCheckbox] = React.useState<boolean>(props.checked);

  React.useEffect(() => {setCheckbox(props.checked);}, [props.checked]);

  const cssCheckbox = "d-flex flex-row justify-content-start align-items-center gap-2 m-0 p-1 border-0 w-100 font-monospace lh-base";
  return <div className={cssCheckbox} onClick={() => {props.onChange(checkbox); setCheckbox(prev => !prev);}}>
    <Tick checked={checkbox} />
    <Label>{props.text ?? "<unknown>"}</Label>
    </div>
  }

export default CheckBox;

///////////////////////////////////////
// UI SUB-COMPONENTS
///////////////////////////////////////

const Tick:React.FC<{checked: boolean}> = (props) => (props.checked) ? <i className="bi bi-check-square"></i> : <i className="bi bi-square"></i>

const Label: React.FC<{children: React.ReactNode}> = (props) => <span>{props.children}</span>

