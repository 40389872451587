/* NODE PACKAGES */
import React from 'react';
import {Form, FloatingLabel} from 'react-bootstrap';

/* CUSTOM COMPONENTS */
import {APITypedAttribute} from 'api/types';

const validateEmail = (email: string): boolean =>
  {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
  };

interface Props
  {
  value: string | undefined;
  definition: APITypedAttribute;
  eventChange: (key: number, value: string) => void;
  disabled?: boolean;
  required?: boolean;
  }

function EmailBox (props: Props)
  {
  const cssValidationTooltip = "position-absolute top-0 start-50 translate-middle text-nowrap";
  return <FloatingLabel key={`email_${props.definition.attribute.id}`} controlId={`${props.definition.attribute.id}`} label={props.definition.attribute.name} className="d-block position-relative w-100">
    <Form.Control
      type="email"
      placeholder={props.definition.attribute.name}
      value={props.value ?? ""}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.eventChange(props.definition.attribute.id, e.target.value)}
      disabled={props.disabled ?? false}
      required={props.required ?? false}
      isValid={(props.value ? validateEmail(props.value) : !props.required) ? true : false}
      isInvalid={(props.value ? !validateEmail(props.value) : props.required) ? true : false}
      />
    <Form.Control.Feedback className={cssValidationTooltip} type='valid' tooltip>Excellent!</Form.Control.Feedback>
    <Form.Control.Feedback className={cssValidationTooltip} type='invalid' tooltip>Error: Invalid Email</Form.Control.Feedback>
    </FloatingLabel>
  }

export default EmailBox;
