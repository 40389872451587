/* NODE PACKAGES */
import React from'react';
import {Container} from 'react-bootstrap';

interface SectionProps
  {
  className?: string,
  children?: React.ReactNode
  }

const Section: React.FC<SectionProps> = (props) =>
  {
  const cssSection = props.className ? props.className : "d-print-none d-block m-0 px-0 py-5";

  return <section className={cssSection}><Container>{props.children}</Container></section>
  }

export default Section;
