/* NODE PACKAGES */
import React from'react';
import {Container} from 'react-bootstrap';

const Metadata: React.FC<{children?: React.ReactNode}> = (props) =>
  {
  return <section className="d-print-none d-block m-0 px-0 py-5 metadata"><Container>{props.children}</Container></section>
  }

export default Metadata;
