/* NODE PACKAGES */
import React from 'react';
import {ButtonGroup, Dropdown, OverlayTrigger, Tooltip, Popover, Stack} from'react-bootstrap';
/* TYPES */
import { APIAttribute, APIAttributeOption, Comparison, NULL_VALUE} from 'api/types';
/* UTILITIES */
import { contrastColor } from 'common/colors';
/* STYLES */
import './DataBox.css';

///////////////////////////////////////
// TOGGLE BUTTON
///////////////////////////////////////

type CustomToggleButtonProps =
  {
  readonly id: number;
  readonly tooltip: string;
  readonly selection: APIAttributeOption;
  readonly comparison?: Comparison;
  }

const cssDataBoxBS:string = 'data_attribute d-flex justify-content-between align-items-center gap-0 m-0 p-2 rounded-3';
const cssDataBoxText:string = "text_contrast text-start text-nowrap font-monospace lh-1 fw-bold w-75 overflow-hidden";
const CustomToggleButton = React.memo((props: CustomToggleButtonProps) =>
  {
  const name            : string      = props.selection?.name ?? "";
  const description     : string      = props.selection?.description ?? "";
  const cssStyle        : string      = props.selection?.className ?? "";
  const comparison      : Comparison  = props.comparison ?? null;
  const ttText          : string      = (comparison) ? comparison : description ?? props.tooltip;
  const cssClassName    : string      = `${cssDataBoxBS} ${comparison ?? cssStyle}`;
  const tooltip         : JSX.Element = <Tooltip id={`tt-${props.id}`}>{ttText}</Tooltip>;

  return <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={tooltip}>
    <Dropdown.Toggle variant="outline-dark" className={cssClassName}><span className={cssDataBoxText}><small>{name}</small></span></Dropdown.Toggle>
    </OverlayTrigger>
  });


///////////////////////////////////////
// MENU OPTIONS
///////////////////////////////////////

type CustomMenuProps =
  {
  options: APIAttributeOption[],
  onChange: (newValue: number) => void,
  }

const cssMenuBS:string = "border-0 m-0 p-0";
const cssMenuOptionBS:string = "data_option font-monospace fs6 lh-1 fw-bold m-0 p-2";
const cssMenuOptionText:string = "text_contrast";
const CustomMenu = React.memo((props: CustomMenuProps) =>
  {
  const isListEmpty: boolean = (props.options.length <= 0);
  return (isListEmpty)
    ? null
    : <Dropdown.Menu variant="light" className={cssMenuBS} aria-description='Data attribute options'>
        {props.options.map((opt: APIAttributeOption) => <Dropdown.Item key={opt.value} className={`${cssMenuOptionBS} ${opt.className}`} onClick={() => props.onChange(opt.value)}><span className={cssMenuOptionText}><small>{opt.name}</small></span></Dropdown.Item>)}
        </Dropdown.Menu>
  });


///////////////////////////////////////
// DATA SELECT COMPONENT
///////////////////////////////////////

interface DataSelectBoxProps
  {
  ruleValue: number | undefined; // The currently selected attribute value.
  comparisonValue?: number; // The comparison type between this attribute and another.
  attribute: APIAttribute | undefined; // The attribute to select a value for.
  alternateDropdown?: APIAttributeOption[]; // Optional alternate dropdown options to display.
  onChange: (newValue: number) => void; // Callback when the selected value changes.
  }

// Dropdown attribute selectbox: displays the attribute name and color; allows selecting between the attribute's values or alternate options; calls onChange callback when selection changes; changes styles based on comparison prop;
function DataBox (props:DataSelectBoxProps)
  {
  if (props.attribute === undefined)
    {
    console.log('DataSelectBox: Data attribute undefined! Props: ', props);
    return null;
    }

  const id:number = props.attribute?.id ?? 0;
  const options: APIAttributeOption[] = props.alternateDropdown || props.attribute.values;
  const ruleAttribute: APIAttributeOption = options?.find((v) => v.value === props.ruleValue) ?? options[0];
  const comparisonAttribute: APIAttributeOption = options?.find((v) => v.value === props.comparisonValue) ?? options[0];
  const comparison:Comparison = (props.comparisonValue === undefined) ? null : getComparisonFromRuleValues(props.ruleValue ?? 0, props.comparisonValue ?? 0);
  const ttText:string = props.attribute?.name ?? null;

  return <Stack direction="vertical" gap={1}>
    <Dropdown id={`databox-${id}`} as={ButtonGroup} size="sm" align="end" aria-description='Select a value for the data attribute'>
    <CustomToggleButton id={id} tooltip={ttText} selection={ruleAttribute} comparison={comparison} />
    <CustomMenu options={options} onChange={props.onChange} />
    </Dropdown>
    <ComparisonLabel comparison={comparison} comparisonSelection={comparisonAttribute} />
    </Stack>
  };

export default React.memo(DataBox);

///////////////////////////////
// Comparison Helper Function
///////////////////////////////

const cssDataLabelBS:string = 'data_label text_contrast m-0 px-2 py-0 rounded-3 text-nowrap text-black text-opacity-50 fst-italic overflow-hidden';
const cssTextbox:string = "text_contrast text-start text-nowrap fs-6 lh-1 font-monospace fw-bold w-100 overflow-hidden";
function ComparisonLabel (props: { comparison: Comparison, comparisonSelection: APIAttributeOption; })
  {
  const comparisonName: string = props.comparisonSelection?.name ?? "(null)";

  return (!props.comparison)
    ? null
    : <div className={cssDataLabelBS}><span className={cssTextbox}><small>{comparisonName}</small></span></div>;
  }

function getComparisonFromRuleValues (ruleValue: number, comparisonValue: number): Comparison
  {
  switch (true)
    {
    case ((ruleValue                  ) === comparisonValue): return 'equal';
    case ((ruleValue | comparisonValue) === comparisonValue): return 'subset';
    case ((ruleValue | comparisonValue) === ruleValue      ): return 'superset';
    default: return 'incompatible';
    }
  }


/*
Development Notes:
This component is used to display the values of a policy rule.
*/

// Deprecated Code:

// const comparisonResults = React.useMemo(() => props.comparison ?? "", [props.comparison]);
// const attributeOption = React.useMemo<APIAttributeOption | undefined>(() => props.attribute?.values.find((v) => v.value === props.value) ?? props.attribute?.values[0], [props.attribute, props.value]);
// const attributeName = React.useMemo(() => attributeOption?.name, [attributeOption]);
// const toggleButtonStyle = React.useMemo(() => ({ backgroundColor: `#${attributeOption?.color}`, color: contrastColor(attributeOption?.color ?? "red") ?? "red", width: "96px" }), [attributeOption]);
// const bsFontStyle = React.useMemo(() => "position-relative font-monospace fw-bold border-0 m-0 p-2", []);
// const cssDisplayText = React.useMemo(() => "d-block position-relative text-start w-75 overflow-hidden", []);

// <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={toolTip}>{props.comparison ? <Dropdown.Toggle className={bsFontStyle} style={toggleButtonStyle}><span className={cssDisplayText}>{attributeName}</span><ComparisonBadge result={comparisonResults} /></Dropdown.Toggle> : <Dropdown.Toggle className={bsFontStyle} style={toggleButtonStyle}><span className={cssDisplayText}>{attributeName}</span></Dropdown.Toggle>} </OverlayTrigger>

// function ComparisonBadge (props: {result: string}): React.ReactNode
//   {
//   const cssSubset = React.useMemo(() => "d-inline-block position-absolute top-50 end-0 z-3 translate-middle-y badge badge-pill bg-transparent border-0 text-white text-opacity-75 font-monospace me-0 px-0 py-0 fs-5 lh-1", []);
//   const cssSuperset = React.useMemo(() => "d-inline-block position-absolute top-50 end-0 z-3 translate-middle-y badge badge-pill bg-transparent border-0 text-white text-opacity-75 font-monospace me-0 px-0 py-0 fs-5 lh-1", []);
//   const cssIncompatible = React.useMemo(() => "d-inline-block position-absolute top-50 end-0 z-3 translate-middle-y badge badge-pill bg-transparent border-0 text-white text-opacity-75 font-monospace me-0 px-0 py-0 fs-5 lh-1", []);
//   const comparisonIcons: {[key: string]: React.ReactNode} =
//     {
//     'equal': null,
//     'subset': <Badge className={cssSubset}> &laquo; </Badge>,
//     'superset': <Badge className={cssSuperset}> &raquo; </Badge>,
//     'incompatible': <Badge className={cssIncompatible}> &times; </Badge>,
//     };

//   return comparisonIcons[props.result] || null;
//   }

