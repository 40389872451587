/* NODE PACKAGES */
import React from 'react';
import _ from 'lodash';
/* CUSTOM COMPONENTS */
import {APIDictionary, APIRegistration, APIRuleAttribute} from 'api/types';
import {getRegistration, saveRegistration, deleteRegistration} from "api/utility";
/* HOOKS */
import {useAxios} from 'hooks';
/* UTILITY */
import { redirect } from "common/window";

export type RegistrationStore =
  {
  data: APIRegistration | null;
  unsavedChanges: boolean;
  singletonScopes: number[];
  updateID(id: number): void;
  refreshData: () => void;
  updateData: (newValues: Partial<APIRegistration>) => void;
  clearData: () => void;
  eventSave: () => void;
  eventDelete: () => void;
  getScopeAttribute(attID: number): APIRuleAttribute | undefined;
  updateScopeAttribute(attID: number, newValue: number): void;
  };

interface RegistrationStoreProps
  {
  registrationID: number;
  dataDictionary: APIDictionary;
  }

function useRegistrationStore(props:RegistrationStoreProps): RegistrationStore
  {
  const [registrationID, setRegistrationID] = React.useState<number>(props.registrationID);
  const [APIdata, APIerror, APIloading, APIcall] = useAxios({ method: 'GET', url: `/api/registration/${registrationID}`, });
  const [data, setData] = React.useState<APIRegistration | null>(null);
  const [unsavedChanges, setUnsavedChanges] = React.useState<boolean>(false);
  const singletonScopes: number[] = [1, 2, 4];

  React.useEffect(() =>
    {
    if (registrationID === -1) setData(null);
    else refreshData();
    }, [registrationID]);

  React.useEffect(() =>
    {
    if (!APIdata || APIerror || APIloading) return;
    setData(APIdata.registration);
    }, [APIdata]);

  function updateID (id:number)
    {
    setRegistrationID(id);
    }

  function refreshData ()
    {
    APIcall();
    }

  function updateData (newValues: Partial<APIRegistration>)
    {
    if (!data) return;
    const newRegistration = Object.assign({}, data, newValues);
    setData(newRegistration as APIRegistration);
    setUnsavedChanges(true);
    }

  function clearData ()
    {
    setData(null);
    setUnsavedChanges(true);
    }

  function eventSave ()
    {
    if (data) saveRegistration(data);
    setUnsavedChanges(false);
    }

  function eventDelete ()
    {
    if (!data) return;
    if (window.confirm('Are you sure you wish to delete this item?')) deleteRegistration(data.id).finally(() => redirect('/registrations/'));
    }

  function getScopeAttribute (attID: number): APIRuleAttribute | undefined
    {
    return data?.scope_attributes.filter((regAtt) => regAtt.attribute_id === attID).at(0);
    }

  function updateScopeAttribute (attID: number, newValue: number)
    {
    const copyScopeAttributes : APIRuleAttribute[] = data?.scope_attributes?.slice() ?? [];
    const existingIndex       : number             = copyScopeAttributes.findIndex((att:APIRuleAttribute) => att.attribute_id === attID);
    const newScopeAttribute   : APIRuleAttribute   = { attribute_id: attID, value: newValue };
    (existingIndex === -1) ? copyScopeAttributes.push(newScopeAttribute) : copyScopeAttributes[existingIndex] = newScopeAttribute;
    updateData({scope_attributes: copyScopeAttributes });
    }

  return {data, unsavedChanges, singletonScopes, updateID, refreshData, updateData, clearData, eventSave, eventDelete, getScopeAttribute, updateScopeAttribute} as RegistrationStore;
  }

export default useRegistrationStore;
