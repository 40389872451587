/* NODE PACKAGES */
import React from 'react';
import {Spinner, Alert, Container, Row, Col, Button, ButtonGroup, Dropdown, DropdownButton, FloatingLabel, Form} from 'react-bootstrap';
/* API */
import { APIRequestTemplate, APIDictionary, APIRequestTemplateItem, APIRequestTemplateRole, APIMatchedRegistration, APIPolicy, APIRegistrantFacts, APITypedAttribute, APIAttributeOption} from 'api/types';
import {executeRequestQuery, saveRequestTemplate, deleteRequestTemplate } from 'api/utility';
/* UTILITY */
import { redirect } from "common/window";
/* HOOKS */
import {useRequestTemplateStore, TemplateAttributes} from 'hooks';
/* TEMPLATES */
import {Page, Header, Main, Section, FlexBox, Footer, Metadata} from "components/atoms/Templates";
/* CUSTOM COMPONENTS */
import File from 'components/molecules/Menu/File';
import EditableLabel from 'components/atoms/EditableText/EditableLabel';
import {TextBox, DomainBox, EmailBox, DateBox, NoteBox, SelectBox, CheckBox} from 'components/atoms/Inputs';
import Droppable from 'components/molecules/Drag&Drop/Droppable';
import Draggable, {DragItem} from 'components/molecules/Drag&Drop/Draggable';
import ChecklistDropdown, {ChecklistItem, ChecklistHeader} from 'components/molecules/Menu/Checklist';


/////////////////////////////////////
// CUSTOM TYPES & DEFAULT CONSTANTS
/////////////////////////////////////

export enum RoleCategory
  {
  USER = "user",
  ADMIN = "administrator",
  }



////////////////////////////
// REQUEST TEMPLATE EDITOR
////////////////////////////

interface Props
  {
  selectedRequestTemplateID: number;
  dataDictionary: APIDictionary;
  unsavedChanges: (flag: boolean) => void;
  }

function TemplateEditor (props: Props)
  {
  // CUSTOM HOOKS: STATE MANAGEMENT
  const requestTemplate = useRequestTemplateStore({selectedTemplateID: props.selectedRequestTemplateID, dataDictionary: props.dataDictionary});
  const [draggables, setDraggables] = React.useState<DragItem[] | null>(null);
  const [draggedItem, setDraggedItem] = React.useState<number | null>(null);
  const optionalAttributes: TemplateAttributes[] = [TemplateAttributes.UI_Contact, TemplateAttributes.UI_Email, TemplateAttributes.UI_RequesterID, TemplateAttributes.UI_Organization, TemplateAttributes.Rule_Location, TemplateAttributes.Rule_Sensitivity, TemplateAttributes.Rule_Logging, TemplateAttributes.Rule_Processing, TemplateAttributes.UI_ProcessingNotes];

  // EFFECTS: DATA MANAGEMENT

  // set global flag to trigger "unsaved changes" prompt on hash changes
  React.useEffect(() =>
    {
    props.unsavedChanges(requestTemplate.unsavedChanges);
    }, [requestTemplate.unsavedChanges]);

  React.useEffect(() =>
    {
    if (!requestTemplate.data) return;
    let draggableList:DragItem[] = Array.from(optionalAttributes).filter((item) => getDefinition(item) !== undefined).map((item) => getDefinition(item)).map((definition) =>
      {
      let attributeID = definition ? definition.attribute.id : 0;
      let attributeName = definition?.attribute.name ?? "";
      let attributeRole = requestTemplate.getRole(attributeID) ?? RoleCategory.USER;
      let item: DragItem = { ID: attributeID, title: attributeName, category: attributeRole, }
      return item;
      });

    setDraggables(draggableList);
    }, [requestTemplate.data]);

  // CALLBACKS: DRAG & DROP EVENTS

  const updateCategory = React.useCallback((status:string) =>
    {
    if (draggables === null || draggedItem === null) return;
    requestTemplate.setRole(draggedItem, status as APIRequestTemplateRole);
    setDraggables(draggables.map((task:DragItem) => task.ID === draggedItem ? {ID: draggedItem, title: getDefinition(draggedItem)?.attribute.name ?? "</>", category: status} as DragItem : task));
    setDraggedItem(null);
    }, [draggables, draggedItem]);

  const eventDragged = React.useCallback((id:number) =>
    {
    setDraggedItem(id);
    }, []);

  const requestOptions = React.useMemo(() => draggables?.filter(i => i.category === RoleCategory.USER) ?? [], [draggables]);
  const templateOptions = React.useMemo(() => draggables?.filter(i => i.category === RoleCategory.ADMIN) ?? [], [draggables]);


  // CALLBACKS:
  const getDefinition = React.useCallback((id:number) => props.dataDictionary.request_attributes?.filter(field => field.attribute.id === id).at(0) ?? {} as APITypedAttribute, [props.dataDictionary.request_attributes]);
  const getOptionName = React.useCallback((id:number) => getDefinition(id)?.attribute?.values.filter(i => i.value === parseInt(requestTemplate.getField(id) ?? "")).at(0)?.name ?? "", [getDefinition]);

  // MEMOS: DATA MANAGEMENT

  const memoRequestOptions = requestOptions.map((item:DragItem) =>
    {
    const generateRandomID = Math.floor(100000 + Math.random() * 900000).toString();
    const checkRuleUniqueIDField = requestTemplate.getField(TemplateAttributes.Rule_UniqueID) === "1" ? true : false;
    const attributeValue: string | undefined = (item.ID !== TemplateAttributes.Rule_RequesterID) ? requestTemplate.getField(item.ID) : checkRuleUniqueIDField ? generateRandomID : requestTemplate.getField(item.ID);
    const definition: APITypedAttribute = getDefinition(item.ID);
    const hasOptions = Boolean(definition.attribute.values.length);
    switch (true)
      {
      case (hasOptions):
        return <Draggable key={`task-${item.ID}`} item={item} eventDragged={eventDragged}>
          <SelectBox value={attributeValue ?? ""} eventChange={requestTemplate.setField} definition={definition} disabled={false} required={requestTemplate.getRequire(item.ID) ? true : false} />
          <div><CheckBox checked={requestTemplate.getRequire(item.ID) ? true : false} text={"Required"} onChange={(check:boolean) => requestTemplate.setRequired(item.ID, check)} /></div>
          </Draggable>
      case (item.ID === TemplateAttributes.UI_Email):
        return <Draggable key={`task-${item.ID}`} item={item} eventDragged={eventDragged}>
          <EmailBox value={attributeValue ?? ""} eventChange={requestTemplate.setField} definition={definition} disabled={false} required={requestTemplate.getRequire(item.ID) ? true : false} />
          <div><CheckBox checked={requestTemplate.getRequire(item.ID) ? true : false} text={"Required"} onChange={(check:boolean) => requestTemplate.setRequired(item.ID, check)} /></div>
          </Draggable>
      default:
        return <Draggable key={`task-${item.ID}`} item={item} eventDragged={eventDragged}>
          <TextBox value={attributeValue ?? ""} eventChange={requestTemplate.setField} definition={definition} disabled={false} required={requestTemplate.getRequire(item.ID) ? true : false} />
          <div><CheckBox checked={requestTemplate.getRequire(item.ID) ? true : false} text={"Required"} onChange={(check:boolean) => requestTemplate.setRequired(item.ID, check)} /></div>
          </Draggable>;
      }
    });

  const memoTemplateOptions = templateOptions.map((item:DragItem) =>
    {
    const attributeValue: string | undefined = requestTemplate.getField(item.ID);
    const definition: APITypedAttribute = getDefinition(item.ID);
    const hasOptions = Boolean(definition.attribute.values.length);
    switch (true)
      {
      case (hasOptions):
        return <Draggable key={`task-${item.ID}`} item={item} eventDragged={eventDragged}>
          <SelectBox value={attributeValue ?? ""} eventChange={requestTemplate.setField} definition={definition} disabled={false} required={requestTemplate.getRequire(item.ID) ? true : false} />
          <div><CheckBox checked={requestTemplate.getRequire(item.ID) ? true : false} text={"Required"} onChange={(check:boolean) => requestTemplate.setRequired(item.ID, check)} /></div>
          </Draggable>
      case (item.ID === TemplateAttributes.UI_Email):
        return <Draggable key={`task-${item.ID}`} item={item} eventDragged={eventDragged}>
          <EmailBox value={attributeValue ?? ""} eventChange={requestTemplate.setField} definition={definition} disabled={false} required={requestTemplate.getRequire(item.ID) ? true : false} />
          <div><CheckBox checked={requestTemplate.getRequire(item.ID) ? true : false} text={"Required"} onChange={(check:boolean) => requestTemplate.setRequired(item.ID, check)} /></div>
          </Draggable>
      default:
        return <Draggable key={`task-${item.ID}`} item={item} eventDragged={eventDragged}>
          <TextBox value={attributeValue ?? ""} eventChange={requestTemplate.setField} definition={definition} disabled={false} required={requestTemplate.getRequire(item.ID) ? true : false} />
          <div><CheckBox checked={requestTemplate.getRequire(item.ID) ? true : false} text={"Required"} onChange={(check:boolean) => requestTemplate.setRequired(item.ID, check)} /></div>
          </Draggable>
      }
    });

  // RENDER
  const gridColumn = "d-flex flex-column justify-content-start align-items-stretch gap-3 m-0 p-0";
  return (!requestTemplate.data ? <Spinner animation="border" variant="primary" className="position-absolute top-50 start-50 translate-middle" /> : <Page>
    <Header>
      <EditableLabel value={requestTemplate.title} onValueChange={requestTemplate.setTitle} />
      <div className="ms-auto"></div>
      <Alert variant="warning" className="d-block m-0 ms-auto my-0 px-3 py-2 fs-6 lh-base"> Under Construction </Alert>
      <Button variant='dark' onClick={() => redirect(`#/request_execution/${props.selectedRequestTemplateID}`)}><i className="bi bi-app-indicator"></i> Execute</Button>
      <File
        eventSave={() => requestTemplate.eventSave()}
        eventDelete={() => requestTemplate.eventDelete()}
        dirtyFlag={requestTemplate.unsavedChanges} />
      </Header>
    <Main>
      <Section>
        <Container>
          <Row className="m-0 p-0 gap-3 mb-3">
            <Col>
              <h4> Build: </h4>
              </Col>
            <Col>
              <h4> Configure: </h4>
              </Col>
            </Row>
          <Row className="m-0 p-0 gap-3 mb-3">
            <Col className={gridColumn}>
              <h6> Required: Request Fields </h6>
              <DateBox value={requestTemplate.getField(TemplateAttributes.UI_Date)} eventChange={requestTemplate.setField} definition={getDefinition(TemplateAttributes.UI_Date)} required={true} />
              <DomainBox value={requestTemplate.getField(TemplateAttributes.UI_Domain)} eventChange={requestTemplate.setField} definition={getDefinition(TemplateAttributes.UI_Domain)} required={true} />
              </Col>
            <Col className={gridColumn}>
              <h6> Configure template requirements: </h6>
              <ButtonGroup id="EditorToolbar" className="rounded-0 border-0 m-0 p-0">
                <TemplateConfigurationTool title="Require" attributes={[TemplateAttributes.Rule_UniqueID]} getField={requestTemplate.getField} updateField={requestTemplate.setField} getDefinition={getDefinition} />
                </ButtonGroup>
              </Col>
            </Row>
          <Row className="m-0 p-0 gap-3 mb-3">
            <Col className={gridColumn}>
              <h6> Optional: Request Fields (User Control) </h6>
              <Droppable category={RoleCategory.USER} updateCategory={updateCategory}>
                {memoRequestOptions}
                </Droppable>
              </Col>
            <Col className={gridColumn}>
              <h6> Optional: Template Fields (Administrator Control) </h6>
              <Droppable category={RoleCategory.ADMIN} updateCategory={updateCategory}>
                {memoTemplateOptions}
                </Droppable>
              </Col>
            </Row>
          <Row className="m-0 p-0 gap-3 mb-3">
            <Col className={gridColumn}>
              <h6> Data included in search results: </h6>
              <SearchFilterTags getField={requestTemplate.getField} getDefinition={getDefinition} />
              <form className="needs-validation" noValidate>
                <div><button disabled={true} className="btn btn-primary" type="submit">Submit form</button></div>
                </form>
              </Col>
            <Col className={gridColumn}>
              <h6> Configure search results filter: </h6>
              <ButtonGroup id="EditorToolbar" className="rounded-0 border-0 m-0 p-0">
                <TemplateConfigurationTool title="Filter" attributes={[TemplateAttributes.Rule_DNS, TemplateAttributes.Rule_Email, TemplateAttributes.Rule_Forensic, TemplateAttributes.Rule_Name, TemplateAttributes.Rule_Org, TemplateAttributes.Rule_Phone, TemplateAttributes.Rule_RegOp]} getField={requestTemplate.getField} updateField={requestTemplate.setField} getDefinition={getDefinition} />
                </ButtonGroup>
              </Col>
            </Row>
          <Row className="m-0 p-0 gap-3 mb-3">
            <Col className={gridColumn}>
              <h6> Request Headers: </h6>
              <TextBox value={requestTemplate.getField(TemplateAttributes.Header_Description)} eventChange={requestTemplate.setField} definition={getDefinition(TemplateAttributes.Header_Description)} />
              <TextBox value={requestTemplate.getField(TemplateAttributes.Header_AccredAuth)} eventChange={requestTemplate.setField} definition={getDefinition(TemplateAttributes.Header_AccredAuth)} />
              </Col>
            <Col className={gridColumn}>
              <h6> Template Descriptions: </h6>
              <TextBox value={requestTemplate.getField(TemplateAttributes.Expected_Fields)} eventChange={requestTemplate.setField} definition={getDefinition(TemplateAttributes.Expected_Fields)} />
              <TextBox value={requestTemplate.getField(TemplateAttributes.Identity_Service)} eventChange={requestTemplate.setField} definition={getDefinition(TemplateAttributes.Identity_Service)} />
              <TextBox value={requestTemplate.getField(TemplateAttributes.Accredting_Enforcement)} eventChange={requestTemplate.setField} definition={getDefinition(TemplateAttributes.Accredting_Enforcement)} />
              <TextBox value={requestTemplate.getField(TemplateAttributes.Purposes_Protection)} eventChange={requestTemplate.setField} definition={getDefinition(TemplateAttributes.Purposes_Protection)} />
              <TextBox value={requestTemplate.getField(TemplateAttributes.Jurisdictions)} eventChange={requestTemplate.setField} definition={getDefinition(TemplateAttributes.Jurisdictions)} />
              </Col>
            </Row>
          </Container>
        </Section>
      <Metadata>
        <Container>
          <Row className="m-0 p-0 gap-3 mb-3">
            <Col className={gridColumn}>
              <h6>Organization:</h6>
              <TextBox value={requestTemplate.getField(TemplateAttributes.Metadata_OrgName)} eventChange={requestTemplate.setField} definition={getDefinition(TemplateAttributes.Metadata_OrgName)} />
              <SelectBox value={requestTemplate.getField(TemplateAttributes.Metadata_OrgType)} eventChange={requestTemplate.setField} definition={getDefinition(TemplateAttributes.Metadata_OrgType)} />
              </Col>
            <Col className={gridColumn}>
              <h6>Primary Contact:</h6>
              <TextBox value={requestTemplate.getField(TemplateAttributes.Metadata_PrimePOC)} eventChange={requestTemplate.setField} definition={getDefinition(TemplateAttributes.Metadata_PrimePOC)} />
              <EmailBox value={requestTemplate.getField(TemplateAttributes.Metadata_PrimeEmail) ?? ""} eventChange={requestTemplate.setField} definition={getDefinition(TemplateAttributes.Metadata_PrimeEmail)} />
              </Col>
            <Col className={gridColumn}>
              <h6>Alternate Contact:</h6>
              <TextBox value={requestTemplate.getField(TemplateAttributes.Metadata_AltPOC)} eventChange={requestTemplate.setField} definition={getDefinition(TemplateAttributes.Metadata_AltPOC)} />
              <EmailBox value={requestTemplate.getField(TemplateAttributes.Metadata_AltEmail) ?? ""} eventChange={requestTemplate.setField} definition={getDefinition(TemplateAttributes.Metadata_AltEmail)} />
              </Col>
            </Row>
          <Row className="m-0 p-0 gap-3 mb-3">
            <Col className={gridColumn}>
              <h6>Version:</h6>
              <TextBox value={requestTemplate.getField(TemplateAttributes.Metadata_Version)} eventChange={requestTemplate.setField} definition={getDefinition(TemplateAttributes.Metadata_Version)} />
              <DateBox value={requestTemplate.getField(TemplateAttributes.Metadata_Updated) ?? ""} eventChange={requestTemplate.setField} definition={getDefinition(TemplateAttributes.Metadata_Updated)} />
              <TextBox value={requestTemplate.getField(TemplateAttributes.Metadata_Completion)} eventChange={requestTemplate.setField} definition={getDefinition(TemplateAttributes.Metadata_Completion)} />
              </Col>
            <Col className={gridColumn}>
              <h6>Publication:</h6>
              <DateBox value={requestTemplate.getField(TemplateAttributes.Metadata_Date) ?? ""} eventChange={requestTemplate.setField} definition={getDefinition(TemplateAttributes.Metadata_Date)} />
              <TextBox value={requestTemplate.getField(TemplateAttributes.Metadata_Status)} eventChange={requestTemplate.setField} definition={getDefinition(TemplateAttributes.Metadata_Status)} />
              <TextBox value={requestTemplate.getField(TemplateAttributes.Metadata_Distribution)} eventChange={requestTemplate.setField} definition={getDefinition(TemplateAttributes.Metadata_Distribution)} />
              </Col>
            <Col className={gridColumn}>&nbsp;</Col>
            </Row>
          </Container>
        </Metadata>
      </Main>
      <Footer />
    </Page>);
  }

export default TemplateEditor;



////////////////////////////
// UI SUB-COMPONENTS
////////////////////////////

interface TemplateEditorToolButton
  {
  title: string;
  attributes: TemplateAttributes[];
  getDefinition: (id: number) => APITypedAttribute;
  getField: (attributeID: number) => string | undefined;
  updateField: (key: number, value: string) => void;
  }

// manages template attributes as dropdown menu list
function TemplateConfigurationTool (props: TemplateEditorToolButton)
  {
  const options = Array.from(props.attributes).map(attr =>
    {
    const definition = props.getDefinition(attr);
    const isCheckbox = Boolean(definition.attribute.values.length === 2 && definition.attribute.values.find(v => v.name === "Include")); // is this a binary option? is at least one option === "Include"??
    const label = definition.attribute.name;
    const key = `rule_${attr}`;
    if (isCheckbox) // if option is binary, render as a checkbox.
      {
      const label = definition.attribute.name;
      const state = Boolean(props.getField(attr) === "1" ? true : false);
      const action = () => props.updateField(attr, state ? "0" : "1");
      return (!definition) ? null : <ChecklistItem key={key} eventKey={key} checked={state} text={label} onChange={action} />
      }
    else // if not a binary option, render as set of choices.
      {
      const items = definition.attribute.values.map((a) =>
        {
        const value: string = `${a.name}`;
        const state: boolean = props.getField(attr) === `${a.value}`;
        const action: () => void = () => props.updateField(attr, `${a.value}`);
        return (!definition) ? null : <Dropdown.Item key={`rule_${attr}${a.value}`} eventKey={key} active={state} onClick={action} className="m-0 p-2">{value}</Dropdown.Item>
        });

      return <React.Fragment key={key}><ChecklistHeader>{label}</ChecklistHeader> {items} </React.Fragment>
      }
    });

  return <ChecklistDropdown title={props.title}>{options}</ChecklistDropdown>
  }

// maintains a set of tags to show what is included in the search results
export function SearchFilterTags (props: {getField: (attributeID: number) => string | undefined, getDefinition: (id: number) => APITypedAttribute})
  {
  const rulesInclude = Array.from([TemplateAttributes.Rule_DNS, TemplateAttributes.Rule_Email, TemplateAttributes.Rule_Forensic, TemplateAttributes.Rule_Name, TemplateAttributes.Rule_Org, TemplateAttributes.Rule_Phone, TemplateAttributes.Rule_RegOp]).map(attr =>
    {
    const definition = props.getDefinition(attr);
    const fieldName = definition.attribute.name ?? "";
    const checked = props.getField(attr) === "1" ? true : false;
    return checked ? fieldName : "";
    }).filter(i => i !== "");

  const rule = props.getField(TemplateAttributes.Rule_Location) ?? "0";
  const definition = props.getDefinition(TemplateAttributes.Rule_Location);
  const set = definition.attribute.values.filter((a) => a.value !== 0 && a.value <= parseInt(rule) || (a.value === 0 && parseInt(rule) === a.value)).map(i => i.name);
  const locationIncludes = set.map((locale:string) => locale);
  const results = rulesInclude.concat(locationIncludes);

  return <ul className="d-flex flex-wrap align-content-start gap-2 m-0 p-0">{results.map((i, index) => <li key={`searchResults-${i}`} className="d-inline-block m-0 px-3 py-2 rounded-3 bg-secondary bg-opacity-25 text-dark shadow-sm fw-bold text-center lh-1">{i}</li>)}</ul>
  }



/* Developer Summary:

The code @web/src/pages/Requests/TemplateEditor.tsx:1-415 is a React component that renders a user interface for editing a request template. The purpose of this code is to provide a way for users to view and modify various fields and attributes associated with a request template.

The component takes an input prop called props, which is an object containing data related to the request template being edited. This includes a dataDictionary object that likely contains definitions and metadata for the different fields and attributes.

The main output of this component is the rendered user interface, which consists of various input fields, dropdowns, and other UI elements for displaying and modifying the request template data.

To achieve its purpose, the code follows a typical React component structure. It imports necessary dependencies and defines a functional component called TemplateEditor. Inside the component, there is a series of nested JSX elements that represent the different sections and UI elements of the template editor.

The component renders several DataCard components, which seem to be custom components for displaying data in a card-like format. Within these DataCard components, there are various input fields and dropdowns for editing different attributes of the request template.

For example, there is a section for editing the "Version" attributes, which includes input fields for "Version," "Updated," and "Completion." Another section is for editing the "Publication" attributes, with input fields for "Date," "Status," and "Distribution."

The component also includes a Print component, which likely handles printing or exporting the request template data in some way.

The logic flow of the component involves rendering the appropriate UI elements based on the data in the props object. It uses functions like getAttributeDefinition to retrieve metadata for specific attributes, and eventUpdateField to handle updates to the input fields.

Overall, this code provides a user interface for viewing and modifying various fields and attributes related to a request template. It takes in data about the template, renders input fields and dropdowns for editing different sections of the template, and likely has functionality for saving or exporting the modified template data.

*/

/*
////////////////////////////////////////////////
SCRATCH NOTES:
////////////////////////////////////////////////

const getOption = React.useCallback((id:number) =>
  {
  if (!draggables) return;
  return draggables.find(item => item.ID === id);
  }, []);

const addOption = React.useCallback((option:DragItem) =>
  {
  if (!draggables) return;
  let findIndex = draggables.findIndex((item) => item.ID === option.ID);
  if (findIndex === -1)
    {
    let temp = draggables;
    temp.push(option);
    setDraggables(temp);
    }
  }, [draggables]);

const removeOption = React.useCallback((option:DragItem) =>
  {
  if (!draggables) return;
  let findIndex = draggables.findIndex((item) => item.ID === option.ID);
  if (findIndex !== -1)
    {
    let temp = draggables;
    delete temp[findIndex];
    setDraggables(temp);
    }
  }, [draggables]);

*/
