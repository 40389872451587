/* NODE PACKAGES */
import React from 'react';
import { AxiosRequestConfig } from 'axios';
/* API */
import { APIRegistration } from 'api/types';
/* HOOKS */
import {useAxios} from 'hooks';

export type RegistrationListStore =
  {
  readonly loading: boolean;
  readonly error:any;
  readonly data: APIRegistration[] | null;
  readonly refresh: () => void;
  };

function useRegistrationListStore(): RegistrationListStore
  {
  const [response, error, loading, callAxios] = useAxios({url: '/api/request_query_all', method: 'GET'});
  const [data, setData] = React.useState<APIRegistration[] | null>(null);

  React.useEffect(() =>
    {
    if (response) setData(response.registrations?.sort((a:APIRegistration, b:APIRegistration) => a.name.localeCompare(b.name)) ?? []);
    }, [response]);

  React.useEffect(() => console.log(data), [data]);

  const refresh = () => callAxios();

  return {loading, error, data, refresh} as RegistrationListStore;
  }

export default useRegistrationListStore;
