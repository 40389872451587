/* NODE PACKAGES */
import { useState } from 'react';
import {Offcanvas, Button, Nav, ListGroup, ListGroupItem} from 'react-bootstrap';
/* MODULAR CSS */
import 'components/atoms/Inputs/DataBox.css';

// Functional component representing a legend table
function ColorKey ()
  {
  const [checked, setChecked] = useState(false);

  const colorLegendSet = "d-flex justify-content-start align-items-center gap-1";
  const gridColumn = "d-flex flex-column justify-content-start align-items-start gap-2 m-0 p-3 bg-white bg-opacity-25 text-start";
  const cssHeader = "fw-light text-dark mx-0 mt-4 mb-2 p-0";
  const cssDescription = "fw-normal text-dark text-opacity-50 fst-normal m-0 p-0";

  return (<>
    <Button variant="outline-dark" className="m-0 p-2 lh-1 text-nowrap" onClick={() => setChecked(!checked)}>Color Key</Button>
    <Offcanvas show={checked} onHide={() => setChecked(!checked)} keyboard={true} placement='start'>
      <Offcanvas.Header className="bg-light" closeButton><Offcanvas.Title>Help Sheet</Offcanvas.Title></Offcanvas.Header>
      <Offcanvas.Body className={gridColumn}>

        <div><h4>Color Key</h4><h6 className={cssDescription}> Reference for data attribute colors </h6></div>

        <h4 className={cssHeader}>Policy Rules</h4>
        <h6 className={cssDescription}> Comparisons </h6>
        <div className={colorLegendSet}>
          <span className={`dot equal`} title="Equal">&nbsp;</span>
          <span className={`dot superset`} title="Superset">&nbsp;</span>
          <span className={`dot subset`} title="Subset">&nbsp;</span>
          <span className={`dot incompatible`} title="Incompatible">&nbsp;</span>
          </div>

        <h6 className={cssDescription}> Collect </h6>
        <div className={colorLegendSet}>
          <span className={`dot rule_coll_1`} title="">&nbsp;</span>
          <span className={`dot rule_coll_2`} title="">&nbsp;</span>
          <span className={`dot rule_coll_3`} title="">&nbsp;</span>
          <span className={`dot rule_coll_4`} title="">&nbsp;</span>
          <span className={`dot rule_coll_5`} title="">&nbsp;</span>
          <span className={`dot rule_coll_6`} title="">&nbsp;</span>
          <span className={`dot rule_coll_7`} title="">&nbsp;</span>
          </div>

        <h6 className={cssDescription}> Validation </h6>
        <div className={colorLegendSet}>
          <span className={`dot rule_val_01`} title="">&nbsp;</span>
          <span className={`dot rule_val_02`} title="">&nbsp;</span>
          <span className={`dot rule_val_03`} title="">&nbsp;</span>
          <span className={`dot rule_val_04`} title="">&nbsp;</span>
          <span className={`dot rule_val_05`} title="">&nbsp;</span>
          <span className={`dot rule_val_06`} title="">&nbsp;</span>
          <span className={`dot rule_val_07`} title="">&nbsp;</span>
          <span className={`dot rule_val_08`} title="">&nbsp;</span>
          <span className={`dot rule_val_09`} title="">&nbsp;</span>
          <span className={`dot rule_val_10`} title="">&nbsp;</span>
          </div>

        <h6 className={cssDescription}> Sensitivity </h6>
        <div className={colorLegendSet}>
          <span className={`dot rule_sens_01`} title="">&nbsp;</span>
          <span className={`dot rule_sens_02`} title="">&nbsp;</span>
          <span className={`dot rule_sens_03`} title="">&nbsp;</span>
          <span className={`dot rule_sens_04`} title="">&nbsp;</span>
          <span className={`dot rule_sens_05`} title="">&nbsp;</span>
          <span className={`dot rule_sens_06`} title="">&nbsp;</span>
          <span className={`dot rule_sens_07`} title="">&nbsp;</span>
          <span className={`dot rule_sens_08`} title="">&nbsp;</span>
          <span className={`dot rule_sens_09`} title="">&nbsp;</span>
          <span className={`dot rule_sens_10`} title="">&nbsp;</span>
          </div>

        <h6 className={cssDescription}> Store </h6>
        <div className={colorLegendSet}>
          <span className={`dot rule_store_1`} title="">&nbsp;</span>
          <span className={`dot rule_store_2`} title="">&nbsp;</span>
          <span className={`dot rule_store_3`} title="">&nbsp;</span>
          </div>

        <h4 className={cssHeader}>Scope</h4>
        <h6 className={cssDescription}> Scope: Person </h6>
        <div className={colorLegendSet}>
          <span className={`dot scope_person_1`} title="">&nbsp;</span>
          <span className={`dot scope_person_2`} title="">&nbsp;</span>
          <span className={`dot scope_person_3`} title="">&nbsp;</span>
          <span className={`dot scope_person_4`} title="">&nbsp;</span>
          <span className={`dot scope_person_5`} title="">&nbsp;</span>
          <span className={`dot scope_person_6`} title="">&nbsp;</span>
          </div>

        <h6 className={cssDescription}> Scope: Protection </h6>
        <div className={colorLegendSet}>
          <span className={`dot scope_protection_1`} title="">&nbsp;</span>
          <span className={`dot scope_protection_2`} title="">&nbsp;</span>
          <span className={`dot scope_protection_3`} title="">&nbsp;</span>
          <span className={`dot scope_protection_4`} title="">&nbsp;</span>
          <span className={`dot scope_protection_5`} title="">&nbsp;</span>
          <span className={`dot scope_protection_6`} title="">&nbsp;</span>
          </div>

        <h6 className={cssDescription}> Scope: Nexus </h6>
        <div className={colorLegendSet}>
          <span className={`dot scope_nexus_1`} title="">&nbsp;</span>
          <span className={`dot scope_nexus_2`} title="">&nbsp;</span>
          <span className={`dot scope_nexus_3`} title="">&nbsp;</span>
          <span className={`dot scope_nexus_4`} title="">&nbsp;</span>
          <span className={`dot scope_nexus_5`} title="">&nbsp;</span>
          <span className={`dot scope_nexus_6`} title="">&nbsp;</span>
          </div>

        <h6 className={cssDescription}> Scope: Personal </h6>
        <div className={colorLegendSet}>
          <span className={`dot scope_personal_1`} title="">&nbsp;</span>
          <span className={`dot scope_personal_2`} title="">&nbsp;</span>
          <span className={`dot scope_personal_3`} title="">&nbsp;</span>
          <span className={`dot scope_personal_4`} title="">&nbsp;</span>
          <span className={`dot scope_personal_5`} title="">&nbsp;</span>
          <span className={`dot scope_personal_6`} title="">&nbsp;</span>
          <span className={`dot scope_personal_7`} title="">&nbsp;</span>
          </div>

        </Offcanvas.Body>
      </Offcanvas>
    </>);
  }

export default ColorKey;

/*
Developer Notes:

*/
