/* NODE PACKAGES */
import React from "react";
import {Spinner, Alert, Button, Container, FloatingLabel, Form, InputGroup, FormGroup, ButtonToolbar, ButtonGroup} from 'react-bootstrap';
/* TYPES */
import { APIRegistryList} from "api/types";
/* HOOKS */
import { useAxios } from "hooks";
/* UTILITY */
import {postRegistryList, deleteRegistryList, parseTextList} from "api/utility";
import { redirect } from "common/window";
/* CUSTOM COMPONENTS */
import EditableLabel from "components/atoms/EditableText/EditableLabel";
import File from 'components/molecules/Menu/File';
/* TEMPLATES */
import {Page, Header, Main, Section, Footer, Metadata, Print} from "components/atoms/Templates";

///////////////////////////////////////
// PSL DATA STORES
///////////////////////////////////////

function useRegistryStore (selectedID:number = 0)
  {
  const [request, error, loading] = useAxios({url: "/api/tld_lists", method: "GET"});
  const [unsavedChanges, setUnsavedChanges] = React.useState<boolean>(false);
  const [data, setData] = React.useState<Set<string>>(() => new Set());
  const [text, setText] = React.useState<string>("Untitled PSL");
  const [id, setID] = React.useState<number>(selectedID);

  React.useEffect(() =>
    {
    if (!request || error || loading) return;
    const dataList:APIRegistryList = request.tld_lists.find((list:APIRegistryList) => list.id === selectedID);
    const dataSet = new Set(dataList.tlds?.sort() ?? []);
    setID(dataList.id); // set ID
    setText(dataList.name); // set name
    setData(dataSet); // set TLDs
    }, [request]);

  const array = React.useMemo(() =>
    {
    return Array.from(data.values()).sort();
    }, [data]);

  const reset = (items:string[] = []) =>
    {
    setData(prev => new Set(items));
    }

  const add = (item:string) =>
    {
    setData(prev => new Set(prev).add(item));
    setUnsavedChanges(true);
    }

  const remove = (item:string) =>
    {
    setData(prev =>
      {
      const next = new Set(prev);
      next.delete(item);
      return next;
      });

    setUnsavedChanges(true);
    }

  const editTitle = (value:string) =>
    {
    setText(value);
    setUnsavedChanges(true);
    }

  const fileSave = (): void =>
    {
    postRegistryList({id: id, name: text, tlds: array}).then((response) => console.log(response)).finally(() => setUnsavedChanges(false));
    }

  const fileDelete = (): void =>
    {
    deleteRegistryList(id).finally(() => redirect('/registries/'));
    }

  return {error: error, loading: loading, id: id, title: text, data: data, array: array, unsavedChanges: unsavedChanges, rename: editTitle, add: add, remove: remove, reset: reset, save: fileSave, delete: fileDelete};
  }

///////////////////////////////////////
// TLD TAG UI
///////////////////////////////////////

const TagItem: React.FC<{name:string, reset: () => void, delete: () => void}> = (props) =>
  {
  return (<div className="d-flex flex-row justify-content-between align-items-center gap-0 m-0 p-0 border-0 fs-6 lh-1 bg-transparent shadow-sm text-dark rounded" style={{cursor: 'pointer'}}>
    <span onClick={props.reset} className="d-block bg-white m-0 px-3 py-2 border-0 rounded-3 rounded-start">{props.name ?? "</>"}</span>
    <span onClick={props.delete} className="bg-light text-dark text-center m-0 px-2 py-2 border-0 rounded-end"><i className="bi bi-x"></i></span>
    </div>);
  }

const TagList: React.FC<{children:React.ReactNode}> = (props) =>
  {
  return (<div className="d-flex justify-content-start align-items-center flex-wrap gap-3 mx-0 my-4 p-0">{props.children}</div>);
  }

const TagInput: React.FC<{value: string, data: Set<string>, addTag: (tag: string) => void, removeTag: (tag: string) => void, onChange: (e: React.ChangeEvent<HTMLInputElement>) => void, clear: () => void}> = (props) =>
  {
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) =>
    {
    if (e.key === 'Enter')
      {
      e.preventDefault();
      console.log(e.currentTarget.value);
      if (!props.data.has(props.value))
        {
        props.addTag(e.currentTarget.value);
        props.clear();
        }
      }
    }

  //const cssValidationTooltip = "position-absolute top-0 start-50 translate-middle text-nowrap";
  const validationFeedback = "text-center";
  return (<InputGroup>
    <Form.Control type="text" placeholder=".example" value={props.value} onKeyDown={handleKeyDown} onChange={props.onChange} isValid={!props.data.has(props.value)} isInvalid={props.data.has(props.value)} />
    <Button variant="dark" className="rounded-end" disabled={props.data.has(props.value)} onClick={() => {props.addTag(props.value); props.clear();}}><i className="bi bi-plus-lg"></i></Button>
    <Form.Control.Feedback type='valid' className={validationFeedback}>Valid!</Form.Control.Feedback>
    <Form.Control.Feedback type='invalid' className={validationFeedback}>Invalid: Entry already added to set.</Form.Control.Feedback>
    </InputGroup>);
  }

///////////////////////////////////////
// PSL EDITOR
///////////////////////////////////////

interface Props
  {
  selectedID: number;
  unsavedChanges: (flag: boolean) => void;
  }

function RegistryListEditor (props: Props)
  {
  const PSL = useRegistryStore(props.selectedID);
  const [tag, setTag] = React.useState<string>("");

  // set global flag to trigger "unsaved changes" prompt on hash changes
  React.useEffect(() =>
    {
    props.unsavedChanges(PSL.unsavedChanges);
    }, [PSL.unsavedChanges]);

  return (PSL.loading ? <Spinner animation="border" variant="primary" className="position-absolute top-50 start-50 translate-middle" /> : PSL.error || !PSL.data ? <Alert variant='warning'>{"Data is not available at this time"}</Alert> :
    <Page>
      <Header>
        <EditableLabel value={PSL.title} onValueChange={PSL.rename} />
        <File eventSave={PSL.save} eventDelete={PSL.delete} dirtyFlag={PSL.unsavedChanges} />
        </Header>
      <Main>
        <Section>
          <TagInput value={tag} data={PSL.data} addTag={PSL.add} removeTag={PSL.remove} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTag(e.target.value)} clear={() => setTag("")} />
          <TagList>{PSL.array.map((item:string, index:number) => <TagItem key={`tag_${index}`} name={item} reset={() => {setTag(item); PSL.remove(item);}} delete={() => PSL.remove(item)} />)}</TagList>
          </Section>
        </Main>
      <Footer />
      </Page>);
  }

export default RegistryListEditor;

/* Developer Notes:

The PSLEditor.tsx code is a React component written in TypeScript that provides a user interface for managing and editing a Public Suffix List (PSL). A PSL is a list of top-level domains (TLDs) and their associated suffixes, which are used by web browsers and other applications to determine the effective domain of a given URL.

* Purpose: The purpose of this code is to allow users to view, edit, and save changes to a PSL. It provides a way to add, remove, and manage the list of TLDs and suffixes that make up the PSL.

* Input: The code takes a single input parameter, selectedID, which is the ID of the PSL that the user wants to edit.

* Output: The main output of this component is the rendered user interface, which displays the current state of the PSL and allows the user to make changes. Additionally, the component can produce updated PSL data as a result of the user's interactions, which can be saved to a server or database.

* Logic and Algorithm: The component follows a typical React component lifecycle. It fetches the PSL data based on the provided selectedID and stores it in the component's state using custom hooks (useTitle, useDataSet, and usePSLStore). These hooks manage the state of the PSL's title, the list of TLDs, and other related data.

The component renders the user interface, which includes an editable label for the PSL title, a file menu for saving and deleting the PSL, and a section for managing the list of TLDs. The TagInput component allows the user to enter new TLDs, while the TagList component displays the current list of TLDs, with each TLD represented by a TagItem component that can be removed.

When the user interacts with the UI, such as editing the title or adding/removing TLDs, the component updates its state accordingly. These state changes trigger a re-render of the component, reflecting the user's changes in the UI.

The component also includes logic for saving and deleting the PSL. When the user saves the PSL, the component sends a request to the server with the updated PSL data. When the user deletes the PSL, the component sends a request to the server to remove the PSL from the database.

* Important Logic Flows and Data Transformations: One important logic flow in the code is the handling of unsaved changes. The component keeps track of whether the user has made any unsaved changes to the PSL data using the unsavedChanges state variable. This variable is updated whenever the user modifies the title or the list of TLDs, and it is used to determine whether the "Save" button in the file menu should be enabled or disabled.

Another notable aspect of the code is the use of custom hooks (useTitle, useDataSet, and usePSLStore) to manage the component's state and logic. These hooks encapsulate the state management and data transformation logic, making the main component code more readable and maintainable.

Overall, the PSLEditor.tsx component serves as a user-friendly interface for managing and editing Public Suffix Lists, providing a comprehensive set of tools and features to facilitate this process.

*/
