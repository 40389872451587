/* NODE PACKAGES */
import {ButtonGroup, Dropdown, DropdownToggle} from 'react-bootstrap';
/* CUSTOM COMPONENTS */
import {HelpGuideMenuItem} from 'components/molecules/Sheet/HelpGuide';

interface FileMenuProps
  {
  eventSave?: () => void,
  eventDelete?: () => void,
  eventCopy?: () => void,
  eventEdit?: () => void,
  eventExecute?: () => void,
  eventImport?: () => void,
  eventExport?: () => void,
  eventPrintPDF?: () => void,
  eventHelp?: () => void,
  dirtyFlag?: boolean,
  savingFlag?: boolean,
  }

const File: React.FC<FileMenuProps> = (props) =>
  {
  const cssDropdownItem = "d-flex justify-content-between align-items-center lh-base";

  return (!props) ? null : <Dropdown as={ButtonGroup} align="end" id="dropdown-basic">
    <DropdownToggle variant={props.dirtyFlag ? "light" : "dark"}>{"File"}</DropdownToggle>
    <Dropdown.Menu variant='dark' flip={false}>
    <Dropdown.Item className={props.eventSave ? cssDropdownItem : "d-none"} onClick={props.eventSave} disabled={!props.dirtyFlag}> Save <i className="bi bi-floppy"></i></Dropdown.Item>
    <Dropdown.Item className={props.eventCopy ? cssDropdownItem : "d-none"} onClick={props.eventCopy} disabled={props.dirtyFlag}> Copy <i className="bi bi-copy"></i></Dropdown.Item>
    <Dropdown.Item className={props.eventDelete ? cssDropdownItem : "d-none"} onClick={props.eventDelete}> <span className="text-danger">Delete</span> <i className="bi bi-trash3 text-danger"></i></Dropdown.Item>
    <Dropdown.Divider className={(props.eventSave || props.eventCopy || props.eventDelete) && (props.eventImport || props.eventImport || props.eventPrintPDF) ? "d-block" : "d-none"} />
    <Dropdown.Item className={props.eventImport ? cssDropdownItem : "d-none"} onClick={props.eventImport}> Import Data <i className="bi bi-database-up"></i></Dropdown.Item>
    <Dropdown.Item className={props.eventExport ? cssDropdownItem : "d-none"} onClick={props.eventExport}> Export Data <i className="bi bi-database-down"></i></Dropdown.Item>
    <Dropdown.Item className={props.eventPrintPDF ? cssDropdownItem : "d-none"} onClick={props.eventPrintPDF}> Export PDF <i className="bi bi-printer"></i></Dropdown.Item>
    <Dropdown.Divider />
    <HelpGuideMenuItem />
    </Dropdown.Menu>
    </Dropdown>
  }

export default File;

/* Developer Notes:

*/
