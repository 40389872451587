/* NODE PACKAGES */
import React from 'react';
import {Spinner, Button, ButtonGroup, Dropdown, DropdownItem, DropdownButton, OverlayTrigger, Tooltip} from 'react-bootstrap';

interface TrashProps
  {
  text?: string;
  onClick?: () => void | undefined;
  }

function Trash (props: TrashProps)
  {
  return props.onClick ? <span className="btn btn-sm btn-outline-danger m-0 ms-auto p-1 lh-1" onClick={props.onClick}><i className="bi bi-trash"></i> {props.text ?? null}</span> : null;
  }

export default Trash;
