/* NODE PACKAGES */
import React from 'react';
import { AxiosRequestConfig } from 'axios';
/* TYPES */
import { NamedItem } from 'api/types';
/* HOOKS */
import {useAxios} from 'hooks';

const GetRegistrantFacts:AxiosRequestConfig = { method: 'GET', url: '/api/registrant_facts_index', }

export type RegistrantFactsListStore =
  {
  loading: boolean;
  error:any;
  data: NamedItem[] | null;
  refresh: () => void
  };

function useRegistrantFactsListStore(): RegistrantFactsListStore
  {
  const [response, error, loading, callAxios] = useAxios(GetRegistrantFacts);
  const [data, setData] = React.useState<NamedItem[] | null>(null);

  React.useEffect(() =>
    {
    if (response) setData(response.registrants?.sort((a:NamedItem, b:NamedItem) => a.name.localeCompare(b.name)) ?? []);
    }, [response]);

  const refresh = () => callAxios();

  return {loading, error, data, refresh} as RegistrantFactsListStore;
  }

export default useRegistrantFactsListStore;

