/* NODE PACKAGES */
import React from'react';
import {Container} from 'react-bootstrap';

const Header: React.FC<{children?: React.ReactNode}> = (props) =>
  {
  const cssHeader = "d-print-none d-block m-0 px-0 py-5 fs-3 lh-1";
  const cssContainer = "d-flex justify-content-between align-items-center gap-3";

  return <header className={cssHeader}><Container className={cssContainer}>{props.children}</Container></header>
  }

export default Header;
