import React from 'react';
import _ from 'lodash';
/* UTILITIES */
import { getAttributeFromRule } from 'api/utility';
/* TYPES */
import { APIAttribute, APIDictionary, APIElement, APIElementGroup, APIRule, APIRuleAttribute, Comparison} from 'api/types';
/* CUSTOM COMPONENTS */
import Note from 'components/atoms/EditableText/Note';
import { isCellHidden } from 'pages/Policies/PolicyEditor';
import { PolicyRuleCell } from 'components/organisms/PolicyRuleCell';

///////////////////////////////////////
// CONSTANTS
///////////////////////////////////////

export const RULE_ATTRIBUTES =
  {
  COLL: 1,
  VAL: 2,
  V3RQ: 3,
  SENS: 4,
  DG: 5,
  STORE: 6
  };

export enum Collection { NULL = 0, COLLECT = 1, DONOTCOLLECT = 2, COLLECT_DONOTCOLLECT = 3, OPTIONAL = 4, COLLECT_OPTIONAL = 5, OPTIONAL_DONOTCOLLECT = 6, ANY = 7, };

//export const DONT_COLLECT = 2;
//const VAL_V3 = 8;
//const SENS_S0 = 1;


///////////////////////////////////////
// POLICY RULE
///////////////////////////////////////

interface PolicyRuleProps
  {
  dictionary: APIDictionary;
  rule: APIRule;
  comparisonRule?: APIRule | null;
  group?: { name: string; rows: number; };
  element: APIElement;
  hidden: boolean;
  hiddenGroupsAttributes: (APIElementGroup | APIAttribute)[];
  selected: boolean;
  onChange: (elementID: number, attributeID: number, newVal: number) => void;
  onNoteChange: (newNote: string) => void;
  onSelectBegin?: () => void;
  onSelectEnd?: () => void;
  onSelectClear?: () => void;
  onCopyClick?: () => void;
  onPasteClick?: () => void;
  }

class PolicyRule extends React.Component<PolicyRuleProps>
  {
  render()
    {
    // extractions: props
    const elementID       : number  = this.props.rule.element_id;
    const elementName     : string  = (this.props.element.rr_controlled ? "*" : "") + this.props.element.name;
    const elementCategory : string  = this.props.dictionary.element_groups.find(eg => eg.elements?.find(el => el.id === elementID)?.element_category_name)?.elements?.[0]?.element_category_name ?? "Uncategorized";
    const elementLabel    : string  = elementCategory + ": " + elementName;

    // extractions: data table
    const headerRow: JSX.Element | null = !this.props.group
      ? null
      : <tr className="m-0 p-0 border-2 border-start-0 border-end-0 border-dark rounded-top">
        <td align="left" valign="bottom" className="m-0 p-2 fs-6 fw-bolder lh-1 font-monospace text-dark text-nowrap text-break">{this.props.group?.name}</td>
        {this.props.dictionary.rule_attributes.map(att => (isCellHidden(this.props.hiddenGroupsAttributes, att) ? null : <td key={att.id} align="center" valign="bottom" className="bg-dark bg-opacity-10 m-0 p-2 fs-6 lh-1 font-monospace text-nowrap text-break">{att.name}</td>))}
        <td align="right" valign="bottom" className="m-0 p-2 fs-6 lh-1 font-serif text-muted text-nowrap text-break"><i className="bi bi-sticky"></i></td>
        </tr>;

    const elementCells: JSX.Element | null = this.props.hidden
      ? null
      : <td id="element" align="left" valign='middle' style={{ backgroundColor: this.props.selected ? "var(--bs-yellow)" : "transparent", userSelect: "none", cursor: "vertical-text", fontStyle: this.props.element.rr_controlled ? "italic" : "normal", }} onMouseDown={(e) => this.props.onSelectBegin?.()} onMouseUp={(e) => this.props.onSelectEnd?.()} onMouseMove={(e) => { if (e.buttons === 1) this.props.onSelectEnd?.(); }}><small className="font-monospace text-wrap text-break lh-1">{elementLabel}</small></td>;

    const PolicyRuleCells = this.props.hidden
      ? null
      : this.props.dictionary.rule_attributes.map((att, index) => isCellHidden(this.props.hiddenGroupsAttributes, att) ? null :
        <td key={`policyCell-${att.id}`} align="left" valign='middle' style={{backgroundColor: 'var(--bs-gray-200)', maxWidth: 'fit-content'}}>
          <PolicyRuleCell rule={this.props.rule} comparisonRule={this.props.comparisonRule} attribute={att} onChange={(newVal) => this.props.onChange(this.props.rule.element_id, att.id, newVal)} />
          </td>);

    const noteCells: JSX.Element | null = this.props.hidden
      ? null
      : <td id="notes" align="right" valign='middle'><Note value={this.props.rule.notes ?? null} onChange={(note) => this.props.onNoteChange(note)} /></td>;

    // RENDER
    return PolicyRuleCells === undefined ? null :
      <React.Fragment key={this.props.rule.id}>
        {headerRow}
        <tr>
          {elementCells}
          {PolicyRuleCells}
          {noteCells}
          </tr>
        </React.Fragment>
    }
  // Need this to prevent entire policy table from rendering when only one rule is changed.
  shouldComponentUpdate(nextProps: PolicyRuleProps)
    {
    return !_.isEqual(this.props.rule, nextProps.rule) || (this.props.comparisonRule !== nextProps.comparisonRule) || (this.props.selected !== nextProps.selected) || (this.props.hiddenGroupsAttributes !== nextProps.hiddenGroupsAttributes);
    }
  }

export default PolicyRule;

/* Development Notes:

undefined - we are not currently comparing policies
APIRule - we are comparing against this rule
null - we are comparing, but the target policy is missing this rule

const PolicyRule = ({
  dictionary,
  rule,
  element,
  comparisonRule = { id: 0, element_id: rule.element_id, attributes: [] },
  comparisonFilter,
  hidden,
  hiddenGroupsAttributes,
  selected,
  onChange,
  onNoteChange,
  onSelectBegin,
  onSelectEnd,
}: PolicyRuleProps) => {
  const elementName = `${element.rr_controlled ? '*' : ''}${element.name}`;
  const categoryName = useMemo(
    () =>
      dictionary.element_groups
        .map(eg => eg.elements.find(el => el.id === rule.element_id)?.element_category_name ?? null)
        .filter(i => i !== null)
        .join(', '), // Assuming multiple categories can be joined with a comma
    [dictionary.element_groups, rule.element_id]
  );
  const nameCategoryElement = `${categoryName}: ${elementName}`;

  const comparisonColumns = useMemo(() => {
    return dictionary.rule_attributes.map(att => {
      let comparison: Comparison = null;
      const ruleAtt = getAttributeFromRule(rule, att.id);
      const attValue = ruleAtt?.value ?? 0;
      if (comparisonRule && attValue) {
        const comparisonRuleAtt = getAttributeFromRule(comparisonRule, att.id);
        const comparisonValue = comparisonRuleAtt?.value ?? 0;

        switch (true) {
          case attValue === comparisonValue:
            comparison = 'equal';
            break;
          case (attValue | comparisonValue) === comparisonValue:
            comparison = 'subset';
            break;
          case (attValue | comparisonValue) === attValue:
            comparison = 'superset';
            break;
          default:
            comparison = 'incompatible';
            break;
        }
      }
      return comparison;
    });
  }, [dictionary.rule_attributes, rule, comparisonRule]);

  const policyColumns = useMemo(() => {
    return comparisonFilter && comparisonColumns.every(i => i === 'equal')
      ? undefined
      : dictionary.rule_attributes.map((att, index) => {
          return isCellHidden(hiddenGroupsAttributes, att) ? null : (
            <PolicyRuleCell
              key={att.id}
              rule={rule}
              attribute={att}
              comparison={comparisonColumns[index]}
              onChange={newVal => onChange(rule.element_id, att.id, newVal)}
            />
          );
        });
  }, [comparisonFilter, comparisonColumns, dictionary.rule_attributes, hiddenGroupsAttributes, rule, onChange]);

  if (!policyColumns) return null;

  return (
    <React.Fragment key={rule.id}>
      <tr>
        {!hidden && (
          <td
            id="element-category-column"
            style={{
              backgroundColor: selected ? 'lightblue' : 'white',
              userSelect: 'none',
              cursor: 'vertical-text',
              fontStyle: element.rr_controlled ? 'italic' : 'normal'
            }}
            onMouseDown={onSelectBegin}
            onMouseUp={onSelectEnd}
            onMouseMove={e => {
              if (e.buttons === 1) onSelectEnd?.();
            }}
          >
            <small className="text-wrap text-break lh-1">{nameCategoryElement}</small>
          </td>
        )}
        {!hidden && policyColumns}
        {!hidden && <td id="notes"><Note value={rule.notes ?? ''} onChange={onNoteChange} /></td>}
      </tr>
    </React.Fragment>
  );
};

export default React.memo(PolicyRule, (prevProps, nextProps) => {
  return (
    _.isEqual(prevProps.rule, nextProps.rule) &&
    prevProps.comparisonRule === nextProps.comparisonRule &&
    prevProps.selected === nextProps.selected &&
    prevProps.hiddenGroupsAttributes === nextProps.hiddenGroupsAttributes
  );
});

*/