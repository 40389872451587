/* NODE PACKAGES */
import _, { parseInt } from 'lodash';
import React from 'react';
import { Spinner, Table, Form, OverlayTrigger, Tooltip} from 'react-bootstrap';
/* CUSTOM COMPONENTS */
import { APIAttribute, APIAttributeOption, APIDictionary, APIRegistration, APIRuleAttribute, APIRegistrantFactItem, APIRegistrantFacts, APIRule, APIPolicy, APIRegistryList , APIElementGroup, APIElement} from 'api/types';
import { getAttributeFromRule, getRuleForElement } from 'api/utility';
import { updatePolicyRuleAttributeValue } from 'pages/Policies/PolicyEditor';
import { PolicyRuleCell } from 'components/organisms/PolicyRuleCell';
import { RULE_ATTRIBUTES, Collection} from 'components/organisms/PolicyRule';
import { TLDItem } from 'components/molecules/Toolbars/Scope';
import DataBox from 'components/atoms/Inputs/DataBox';
//import {RegistrationEditorData} from 'hooks/useRegistrationData';
import {PolicyStore, RegistrationStore, RegistrantFactsStore} from 'hooks';

///////////////////////////////////////
// CONSTANTS
///////////////////////////////////////

const REG_ATTRIBUTES = {V3RQ: 14, DG: 15 };
const ANY = "15";
const GROUP_REGSCOPE = 2;
const ELEMENT_PSL = 4;

///////////////////////////////////////
// REGISTRATION TABLE DATA
///////////////////////////////////////

interface RegistrationEditorTableProps
  {
  dictionary: APIDictionary;
  registrantFacts: RegistrantFactsStore;
  registration: RegistrationStore;
  policy: PolicyStore;
  }

/*
///////////////////////////////////////
// SUMMARY
///////////////////////////////////////

The RegistrationEditorTable.tsx component and its sub-components create a complex table for editing and displaying registration data. Here's a summary of what it does:

  * It renders a table with rows for each element in the data dictionary, grouped by element groups.
  * The table displays information about registrant facts, policy rules, and registration details for each element.
  * It allows editing of registrant facts, validation requests (VRQ), and sensitivity requests (SRQ).
  * It shows policy rules for each element, including collection status, validation, and sensitivity.
  * The component calculates and displays registration values, validation, and sensitivity based on the policy rules and registrant facts.

The main calculations in simple terms:

  * Registration Value: It filters the registrant fact based on the collection status from the policy rule.
  * Registration Validation: It combines the validation rule from the policy with the VRQ from the registrant fact, using bitwise OR operation. It displays the stricter of the two values.
  * Registration Sensitivity: Similar to validation, it combines the sensitivity rule from the policy with the SRQ from the registrant fact, displaying the stricter value.

These calculations ensure that the displayed registration data adheres to both the policy rules and the registrant's requests, always showing the more restrictive option when there's a difference between policy and registrant preferences.
*/

export function RegistrationDataPrint (props: RegistrationEditorTableProps)
  {
  function getAttribute (id: number, rule: APIRule): APIAttributeOption | null
    {
    const attribute_validation: APIRuleAttribute | undefined = getAttributeFromRule(rule, id);
    return attribute_validation ? props.dictionary.rule_attributes?.filter(a => a.id === attribute_validation.attribute_id).at(0)?.values.at(attribute_validation.value) ?? null : null;
    };

  // Retrieves the registration scope attribute for the given element ID.
  function getRegistrationScopeAttribute (elementID:number): string
    {
    const findPSLValue = () =>
      {
      return props.registration.data?.tlds?.name ?? "<none>";
      }

    const findScopeValue = () =>
      {
      const joinTable: { [key: number]: number; } = { 84: 10, 5: 11, 6: 12, 85: 13 };
      const attValue: APIAttribute | undefined = props.dictionary.scope_attributes.filter(sa => sa.id === joinTable[elementID]).find(sa => sa.id === joinTable[elementID]);
      const existingValue: number = props.registration.data?.scope_attributes?.find(sa => sa.attribute_id === joinTable[elementID])?.value ?? -1;
      return attValue?.values.find(val => val.value === existingValue)?.name ?? "ø";
      }

    return (elementID === ELEMENT_PSL) ? findPSLValue() : findScopeValue ();
    }

  const regValidationDefinition: APIAttribute = props.dictionary.rule_attributes.find(att => att.id === RULE_ATTRIBUTES.VAL ) ?? { id: 0, name: "", values: [] }
  const regSensitivtyDefinition: APIAttribute = props.dictionary.rule_attributes.find(att => att.id === RULE_ATTRIBUTES.SENS) ?? { id: 0, name: "", values: [] }

  // Computes the table data for the registration editor table. The resulting array of objects is used to render the rows of the registration editor table.
  // Maps over the `elementGroups` array, which contains `APIElementGroup` objects. For each element group, it maps over the `elements` array, which contains `APIElement` objects. For each element, it creates an object with the following properties:
  // - `index`: The index of the element within the group.
  // - `group`: The `APIElementGroup` object for the current group.
  // - `element`: The `APIElement` object for the current element.
  // - `fact`: The `APIRegistrantFactItem` object for the current element, or `undefined` if not found.
  // - `rule`: The `APIRule` object for the current element, obtained from the `props.policy.getRule()` method.
  // - `scope`: The value of Scope Attribute, obtained by "getRegistrationScopeAttribute()" method.
  // - `collect`: The value of the `RULE_ATTRIBUTES.COLL` attribute for the current rule, or 0 if the rule is not found.
  // - `key`: A unique key for the current row, generated from the group and element IDs.
  const data_extraction = React.useMemo(() => props.dictionary.element_groups.map((group: APIElementGroup) => group.elements.map((element: APIElement, index: number) =>
    {
    const fact    : APIRegistrantFactItem  = props.registrantFacts.getRegistrantFact(element.id) ?? { element_id: element.id, value: element.name, V3RQ: ANY, DG: ANY };
    const rule    : APIRule                = props.policy.getRule(element.id) ?? { id: 0, element_id: element.id, attributes: [] };
    const scope   : string                 = getRegistrationScopeAttribute(element.id);
    const collect : number                 = (rule) ? getAttribute(RULE_ATTRIBUTES.COLL, rule)?.value ?? 0 : 0;
    const key     : string                 = `rowData${group.id}${element.id}`;

    return {index, group, element, fact, rule, scope, collect, key};
    })), [props.registrantFacts.data, props.policy.data]);

  // Renders the data table generated by mapping over the `data_extraction` array, which contains objects representing the data for each row in the table. For each row, it renders:
  return (!props.registration.data)
    ? <Spinner animation="border" variant="primary" className="position-absolute top-50 start-50 translate-middle" />
    : <Table responsive size="sm" className="font-monospace lh-base m-0 p-0" style={{captionSide: 'bottom'}}>
      {data_extraction.map((data_table) => data_table.map((data_row) =>
        <tbody key={`header-${data_row.key}`}>
          <DataTableHeader index={data_row.index} groupName={data_row.group.name} rule_attributes={props.dictionary.rule_attributes} />
          <tr>
            <RegistrantFactCell groupID={data_row.group.id} element={data_row.element} regFact={data_row.fact} scope={data_row.scope} registrantFacts={props.registrantFacts} />
            <VrqCell element={data_row.element} regFact={data_row.fact} definition={props.dictionary.registration_attributes} registrantFacts={props.registrantFacts} />
            <SrqCell element={data_row.element} regFact={data_row.fact} definition={props.dictionary.registration_attributes} registrantFacts={props.registrantFacts} />
            <td className="border"></td>
            <PolicyRules rule={data_row.rule} definitions={props.dictionary.rule_attributes} policy={props.policy} />
            <td className="border"></td>
            <RegistrationValue groupID={data_row.group.id} scope={data_row.scope} regFact={data_row.fact} collect={data_row.collect} />
            <RegistrationValidation collect={data_row.collect} rule={data_row.rule} regFact={data_row.fact} definition={regValidationDefinition} />
            <RegistrationSensitivity collect={data_row.collect} rule={data_row.rule} regFact={data_row.fact} definition={regSensitivtyDefinition} />
            </tr>
          </tbody>))}
        </Table>
  }

//////////////////////////////////////////////////////
// REGISTRATION TABLE HEADERS (repeats for each group)
//////////////////////////////////////////////////////

function DataTableHeader (props: {index:number, groupName:string, rule_attributes: APIAttribute[]})
  {
  return props.index !== 0 ? null :
    <React.Fragment>
      <tr>
        <td colSpan={3} className="text-secondary">Registrant Facts </td>
        <td></td>
        <td colSpan={props.rule_attributes.length} className="text-secondary">Policy</td>
        <td></td>
        <td colSpan={3} className="text-secondary">Registration</td>
        </tr>
      <tr className="m-0 p-0 border-2 border-start-0 border-end-0 border-dark">
        <td>{props.groupName}</td>
        <td>Vrq</td>
        <td>Srq</td>
        <td className="border"></td>
        {props.rule_attributes.map(att => <td key={`attrName-${att.id}`} className="bg-dark bg-opacity-10">{att.name}</td>)}
        <td className="border"></td>
        <td>Value</td>
        <td>Val</td>
        <td>Sens</td>
        </tr>
      </React.Fragment>
  }

///////////////////////////////////////
// COLUMN: REGISTRANT FACT
///////////////////////////////////////

const styleRegistrationFactCell = {maxWidth: '175px', textOverflow: "ellipsis"};
function RegistrantFactCell (props: {groupID:number, element: APIElement, regFact: APIRegistrantFactItem, registrantFacts: RegistrantFactsStore, scope:string})
  {
  const value: string = (props.groupID === GROUP_REGSCOPE) ? props.scope : props.regFact?.value ?? props.element?.name;
  return <td className="overflow-hidden text-break" style={styleRegistrationFactCell}><div><small>{props.element.name}:</small></div><div><small>{value}</small></div></td>
  }

///////////////////////////////////////
// COLUMN: VALIDATION REQUEST
///////////////////////////////////////

function VrqCell (props: {element: APIElement, regFact:APIRegistrantFactItem | undefined, definition:APIAttribute[], registrantFacts: RegistrantFactsStore,})
  {
  const value: number | undefined = props.regFact?.V3RQ ? parseInt(props.regFact.V3RQ) : undefined;
  const attribute: APIAttribute | undefined = props.definition.find((att: {id: number}) => att.id === REG_ATTRIBUTES.V3RQ);
  return <td><DataBox ruleValue={value} attribute={attribute} onChange={() => {}} /></td>
  }

///////////////////////////////////////
// COLUMN: SENSITIVITY REQUEST
///////////////////////////////////////

function SrqCell (props: {element: APIElement, regFact:APIRegistrantFactItem | undefined, definition:APIAttribute[], registrantFacts: RegistrantFactsStore,})
  {
  const value: number | undefined = props.regFact?.DG ? parseInt(props.regFact?.DG) : undefined;
  const attribute:APIAttribute | undefined = props.definition.find((att: { id: number; }) => att.id === REG_ATTRIBUTES.DG);

  return <td><DataBox ruleValue={value} attribute={attribute} onChange={() => {}} /></td>
  }

///////////////////////////////////////
// COLUMN: POLICY RULES
///////////////////////////////////////

function PolicyRules (props: {rule:APIRule, definitions:APIAttribute[], policy: PolicyStore})
  {
  return props.definitions.map(att => <td key={`policyRuleCell-${att.id}`} className="policy-rule"><PolicyRuleCell key={`policyRuleCell-${att.id}`} rule={props.rule} attribute={att} onChange={() => {}} /></td>);
  }

///////////////////////////////////////
// COLUMN: REGISTRATION VALUE
///////////////////////////////////////

const styleRegistrationValueCell = {maxWidth: '175px', textOverflow: "ellipsis"};
function RegistrationValue (props: {groupID:number, scope: React.ReactNode, regFact: APIRegistrantFactItem | undefined, collect: number})
  {
  const UNSET   = "ø";
  const MISSING = "MISSING";
  const EMPTY   = null;
  const FACT    = props.regFact?.value ?? "";

  function filterFact ()
    {
    if (props.groupID === GROUP_REGSCOPE) return props.scope;
    switch (props.collect)
      {
      case Collection.NULL: return UNSET;
      case Collection.COLLECT: return props.regFact?.value ? FACT : MISSING;
      case Collection.DONOTCOLLECT: return EMPTY;
      case Collection.OPTIONAL: return props.regFact?.value ? FACT : EMPTY;
      // todo: build other cases
      default: return EMPTY;
      }
    }

  return <td align="left" className="fst-italic overflow-hidden text-break" style={styleRegistrationValueCell}><small>{filterFact()}</small></td>
  }

///////////////////////////////////////
// COLUMN: REGISTRATION VALIDATION
///////////////////////////////////////

function RegistrationValidation (props: {collect:number, rule:APIRule, regFact: APIRegistrantFactItem | undefined, definition:APIAttribute})
  {
  let regValidationValue = props.rule.attributes.find(att => att.attribute_id === RULE_ATTRIBUTES.VAL)?.value ?? 0;
  const parsedV3RQ = parseInt(props.regFact?.V3RQ ?? "0");
  regValidationValue = ((parsedV3RQ | regValidationValue) === regValidationValue && (parsedV3RQ !== parseInt(ANY)))
    ? parsedV3RQ
    : props.rule.attributes.find(att => att.attribute_id === RULE_ATTRIBUTES.V3RQ)?.value ?? 0;
  const validationValue = props.definition.values.filter((v) => v.value === regValidationValue).at(0)?.name ?? "";

  return <td>{(props.collect !== Collection.DONOTCOLLECT) ? <span><small>{validationValue}</small></span> : null}</td>
  }

///////////////////////////////////////
// COLUMN: REGISTRATION SENSITIVITY
///////////////////////////////////////

function RegistrationSensitivity (props: {collect:number, rule:APIRule, regFact: APIRegistrantFactItem | undefined, definition:APIAttribute})
  {
  let regSensToDisplay = props.rule.attributes.find(att => att.attribute_id === RULE_ATTRIBUTES.SENS)?.value ?? 0;
  const parsedDG = parseInt(props.regFact?.DG ?? "0");
  regSensToDisplay = ((parsedDG | regSensToDisplay) === regSensToDisplay && (parsedDG !== parseInt(ANY)))
    ? parsedDG
    : props.rule.attributes.find(att => att.attribute_id === RULE_ATTRIBUTES.DG)?.value ?? 0;
  const sensitivityValue = props.definition.values.filter((v) => v.value === regSensToDisplay).at(0)?.name ?? "";

  return <td>{(props.collect !== Collection.DONOTCOLLECT) ? <span><small>{sensitivityValue}</small></span> : null}</td>
  }

