/* NODE PACKAGES */
import React, {/*Profiler*/} from 'react';
import {Spinner, Badge, Container, Stack} from 'react-bootstrap';

/* CUSTOM COMPONENTS */
import { NamedItem, APIDictionary, PolicyListItem,} from 'api/types';
import RegistrationData from 'pages/Registrations/modules/RegistrationEditorTable';
import {RegistrationDataPrint} from 'pages/Registrations/modules/RegistrationDataPrint';
import File from 'components/molecules/Menu/File';
import {Page, Header, Main, Section, Footer, Print} from "components/atoms/Templates";
import {usePolicyListStore, PolicyListStore, usePolicyStore, PolicyStore, useRegistrationStore, RegistrationStore, useRegistrantFactsStore, RegistrantFactsStore, useRegistrantFactsListStore, RegistrantFactsListStore, RegistryListStore, useRegistryListStore} from 'hooks';
import ScopeCalculator from 'pages/Registrations/modules/ScopeCalculator';
import ColorKey from 'components/molecules/Sheet/ColorKey';
import { set } from 'lodash';

///////////////////////////////////////
// REGISTRATION EDITOR
///////////////////////////////////////

interface RegistrationEditorProps
  {
  registrationID: number;
  dataDictionary: APIDictionary;
  unsavedChanges: (flag: boolean) => void;
  }

function RegistrationEditor(props: RegistrationEditorProps)
  {
  const [unsavedChanges, setUnsavedChanges] = React.useState<boolean>(false);
  // DATA/STATE MANGEMENT (CUSTOM HOOKS)
  const registration:RegistrationStore = useRegistrationStore(props);
  const policy:PolicyStore = usePolicyStore({selectedPolicyID: registration.data?.policy_id ?? -1, dictionary: props.dataDictionary});
  const registrantFacts:RegistrantFactsStore = useRegistrantFactsStore({registrantFactsID: registration.data?.registrant_facts_id ?? -1, dictionary: props.dataDictionary});
  const policyList:PolicyListStore = usePolicyListStore();
  const registrantFactsList:RegistrantFactsListStore = useRegistrantFactsListStore();
  const registryList: RegistryListStore = useRegistryListStore();

  // MEMOS

  // EFFECTS

  // state traces
  // React.useEffect(() => console.log("RegistrationEditor[registration]:", registration.data), [registration.data]);
  // React.useEffect(() => console.log("Registration Editor[registrantFacts]: ", registrantFacts.data), [registrantFacts.data]);
  // React.useEffect(() => console.log("Registration Editor[policy]: ", policy.data), [policy.data]);
  // React.useEffect(() => console.log("RegistrationEditor[policyList]: ", policyList.data), [policyList.data]);
  // React.useEffect(() => console.log("RegistrationEditor[registrantFactsList]: ", registrantFactsList.data), [registrantFactsList.data]);

  // set global flag to trigger "unsaved changes" prompt on hash changes
  React.useEffect(() =>
    {
    props.unsavedChanges(unsavedChanges);
    }, [unsavedChanges]);

  React.useEffect(() =>
    {
    console.log("RegistrationEditor[unsavedChanges]:", unsavedChanges);
    setUnsavedChanges(registration.unsavedChanges || policy.unsavedChanges || registrantFacts.unsavedChanges);
    }, [registration.unsavedChanges, policy.unsavedChanges, registrantFacts.unsavedChanges])

  React.useEffect(() =>
    {
    if (!registration.data?.policy_id) return;
    if (policyList.data?.findIndex(i => i.id === registration.data?.policy_id) !== -1) policy.updateID(registration.data.policy_id);
    }, [registration.data?.policy_id]);

  React.useEffect(() =>
    {
    if (!registration.data?.id) return;
    if (registrantFactsList.data?.findIndex((item:NamedItem) => item.id === registration.data?.registrant_facts_id) !== -1) registrantFacts.updateID(registration.data.registrant_facts_id);
    }, [registration.data?.registrant_facts_id]);

  React.useEffect(() =>
    {
    if (!registration.data?.name) return;
    const registrationName = (registrantFacts.data?.name || policy.data?.name) ? ((registrantFacts.data?.name ?? "*") + (" | ") + (policy.data?.name ?? "*")) : "Untitled Registration";
    if (registration.data?.name !== registrationName) {registration.updateData({name: registrationName}); save();}
    }, [registrantFacts.data?.name, policy.data?.name]);

  const save = () => {policy.eventSave(); registrantFacts.eventSave(); registration.eventSave();}

  // RENDER
  return ((!registration.data)
    ? <Spinner animation="border" variant="primary" className="position-absolute top-50 start-50 translate-middle" />
    : <Page>
        <Header>
          <span>{registration.data?.name}</span>
          <div className="ms-auto"></div>
          <a className="link link-secondary fs-6 lh-1" href={`/#/registration_old/${registration?.data?.id}`}>{"(Editor v1.0)"}</a>
          <ColorKey />
          <File eventSave={save} eventDelete={registration.eventDelete} eventPrintPDF={() => window.print()} dirtyFlag={unsavedChanges} />
          </Header>
        <Main>
          <Section>
            {/* <Profiler id="ScopeCalculator" onRender={onRender}> */}
            <ScopeCalculator registration={registration} policy={policy} policyList={policyList} registrantFacts={registrantFacts} registrantFactsList={registrantFactsList} dictionary={props.dataDictionary} />
            {/* </Profiler> */}
            </Section>
          <Section>
            {/* <Profiler id="DataTable" onRender={onRender}> */}
            <RegistrationData dictionary={props.dataDictionary} registrantFacts={registrantFacts} registration={registration} policy={policy} registryList={registryList} />
            {/* </Profiler> */}
            </Section>
          </Main>
        <Footer />
        <Print>
          <RegistrationDataPrint dictionary={props.dataDictionary} registrantFacts={registrantFacts} registration={registration} policy={policy} />
          </Print>
        </Page>);

  // function onRender (id:any, phase:any, actualDuration:any, baseDuration:any, startTime:any, commitTime:any)
  //   {
  //   console.groupCollapsed("Profiler: ", id)
  //   console.log("phase: ", phase);
  //   console.log("actualDuration(s): ", actualDuration / 1000);
  //   console.log("baseDuration(s): ", baseDuration / 1000);
  //   console.log("startTime(timestamp): ", startTime);
  //   console.log("commitTime(timestamp): ", commitTime);
  //   console.log("time difference(s): ", (commitTime - startTime)/1000);
  //   console.groupEnd();
  //   }
  }

export default RegistrationEditor;


