/* NODE PACKAGES */
import React from "react";

export interface EditableLabelProps
  {
  value: string;
  onValueChange: (newValue: string) => void;
  }

const EditableLabel: React.FC<EditableLabelProps> = (props) =>
  {
  const [text, setText] = React.useState(props.value);
  const spanRef = React.useRef<HTMLSpanElement>(null);

  React.useEffect(() =>
    {
    if (!props.value || !spanRef.current) return;
    textEdited(props.value);
    spanRef.current.textContent = props.value;
    }, [props.value]);

  const textEdited = React.useCallback((newText: string) =>
    {
    const cleanedText = newText.replace(/\r?\n|\r/g, '').trim();
    setText(cleanedText);
    }, [props.value]);

  const handleInput = React.useCallback((e: React.SyntheticEvent<HTMLSpanElement>) =>
    {
    textEdited(e.currentTarget.textContent ?? "");
    }, []);

  // select all text on click event
  const selectContents = (el: any) =>
    {
    let range = document.createRange();
    range.selectNodeContents(el);
    let sel = window.getSelection()!;
    sel.removeAllRanges();
    sel.addRange(range);
    }

  const handleKeyDown = React.useCallback((e: React.KeyboardEvent<HTMLSpanElement>) =>
    {
    if (e.key === 'Enter')
      {
      e.preventDefault();
      e.currentTarget.blur();
      }
    }, []);

  const handleBlur = React.useCallback((e: React.FocusEvent<HTMLSpanElement>) =>
    {
    props.onValueChange(text)
    }, [text]);

  return (<span ref={spanRef} onInput={handleInput} onKeyDown={handleKeyDown} onBlur={handleBlur} contentEditable={true} />);
  };

export default React.memo(EditableLabel);
