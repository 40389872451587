/* NODE PACKAGES */
import React from 'react';
import {Spinner, Alert, Card, Container, Row, Col, Stack, Form, InputGroup, Button, ToggleButtonGroup, ToggleButton, ListGroup, ListGroupItem} from 'react-bootstrap';
/* API */
import { APIAttribute, APIDictionary, APIElement, APIElementGroup, APIPolicy, APIRule, APIRuleAttribute, APIRegistrar, APIRequestTemplate, APIRegistryList, PolicyListItem, APIRequester, APIRegistrarGroup, APIRequesterGroup, APIRegistration} from 'api/types';
import {createNewRegistration, createNewRequestTemplate, createNewRequester, createRequesterGroup, createNewRegistrarGroup, createPolicy, createRegistryList} from 'api/utility';
// COMMON
import { redirect } from "common/window";
import { timeDifference } from "common/time";
// HOOKS
import {useAxios, RegistrarStore, useRegistrarGroupMembersStore, useRegistrationListStore, RegistrationListStore, useRequestTemplateListStore, useRegistryListStore, usePolicyListStore, PolicyListStore} from 'hooks';
/* TEMPLATES */
import {Page, Header, Main, Section, Footer, Metadata} from "components/atoms/Templates";

///////////////////////////////////////
// DASHBOARD
///////////////////////////////////////

interface DashboardProps
  {
  dataDictionary: APIDictionary;
  }

const Dashboard: React.FC<DashboardProps> = (props) =>
  {
  const policyData = usePolicyListStore();
  const requestTemplates = useRequestTemplateListStore();
  const registrations = useRegistrationListStore();
  const tldLists = useRegistryListStore();
  const registrarGroupMembers = useRegistrarGroupMembersStore();

  const policyCount = React.useMemo<string>(() => policyData.data?.length ? `${policyData.data?.length} Collection Policies` : "", [policyData.data]);
  const requestTemplateCount = React.useMemo<string>(() => requestTemplates.data?.length ? `${requestTemplates.data?.length} Templates` : "", [requestTemplates.data]);
  const registrationCount = React.useMemo<string>(() => registrations.data ? `${registrations.data.length} Registrations` : "", [registrations.data]);
  const registryListCount = React.useMemo<string>(() => tldLists.data?.length ? `${tldLists.data?.length} Registry Lists` : "", [tldLists.data]);
  const requestersCount = React.useMemo<string>(() => registrarGroupMembers.requesters?.length ? `${registrarGroupMembers.requesters?.length} Requesters` : "", [registrarGroupMembers.requesters]);
  const requesterGroupsCount = React.useMemo<string>(() => registrarGroupMembers.requesterGroups?.length ? `${registrarGroupMembers.requesterGroups?.length} Requester Groups` : "", [registrarGroupMembers.requesterGroups]);
  const registrarsCount = React.useMemo<string>(() => registrarGroupMembers.registrars?.length ? `${registrarGroupMembers.registrars?.length} Registrars` : "", [registrarGroupMembers.registrars]);
  const registrarGroupsCount = React.useMemo<string>(() => registrarGroupMembers?.registrarGroups?.length ? `${registrarGroupMembers.registrarGroups.length} Registrar Groups` : "", [registrarGroupMembers.registrarGroups]);

  const cssCard = "d-block w-100 h-100 m-0 p-3 border border-1 border-dark border-opacity-10 bg-light shadow-sm pointer";
  const cssIcon = "m-0 p-2 border border-2 border-dark border-opacity-75 rounded-circle bg-transparent bg-gradient shadow-sm text-dark text-opacity-75 fs-5 lh-1 fw-light text-center";
  const cssFlexRowStart = "d-flex flex-row justify-content-start align-items-start gap-2 m-0 p-0 border-0";
  const cssFlexColumnStart = "d-flex flex-column justify-content-start align-items-start gap-2 m-0 p-0 border-0";

  return (<Page>
    <Header>
      <div className="mx-auto text-center m-0 px-0 py-5">
        <h2>Project Jake: Rethinking Domain Registration Data</h2>
        <h4 className="fs-3 lh-1 fst-italic fw-lighter">Developing voluntary technical solutions to complex data-related public policy challenges.</h4>
        </div>
      </Header>
    <Main>
      <Section>
        <Container className="m-0 p-0">
          <Row className="g-2">
            <Col>
              <Container>
                <Row xs={1} sm={2} className="g-3">
                  <Col><Card className={cssCard} onClick={() => redirect("/policies/")}>
                    <Card.Body className={cssFlexRowStart}>
                      <span className={cssIcon}><i className="bi bi-diagram-3"></i></span>
                      <Container>
                        <Card.Title>Collection Policies</Card.Title>
                        <Card.Text> Create, view and edit Policy rules. Make comparisons between policy sets. </Card.Text>
                        <span className="text-muted"><small>{policyCount}</small></span>
                        </Container>
                      </Card.Body>
                    </Card></Col>
                  <Col><Card className={cssCard} onClick={() => redirect("/requests/")}>
                    <Card.Body className={cssFlexRowStart}>
                      <span className={cssIcon}><i className="bi bi-pencil-square"></i></span>
                      <div>
                        <Card.Title>Requests</Card.Title>
                        <Card.Text> Create, view and edit request forms to be submitted to a registrar. </Card.Text>
                        <span className="text-muted"><small>{requestTemplateCount}</small></span>
                        </div>
                      </Card.Body>
                    </Card></Col>
                  <Col><Card className={cssCard} onClick={() => redirect("/registrations/")}>
                    <Card.Body className={cssFlexRowStart}>
                      <span className={cssIcon}><i className="bi bi-list-check"></i></span>
                      <div>
                        <Card.Title>Registrations</Card.Title>
                        <Card.Text> Create, view and edit Registrants. Filter Registration data using collection policies. </Card.Text>
                        <span className="text-muted"><small>{registrationCount}</small></span>
                        </div>
                      </Card.Body>
                    </Card></Col>
                  <Col><Card className={cssCard} onClick={() => redirect("/registries/")}>
                    <Card.Body className={cssFlexRowStart}>
                      <span className={cssIcon}><i className="bi bi-globe"></i></span>
                      <div>
                        <Card.Title>Registry Lists</Card.Title>
                        <Card.Text> Create, view and edit sets of Registry Lists. </Card.Text>
                        <span className="text-muted"><small>{registryListCount}</small></span>
                        </div>
                      </Card.Body>
                    </Card></Col>
                  <Col><Card className={cssCard} onClick={() => redirect("/registrars/")}>
                    <Card.Body className={cssFlexRowStart}>
                      <span className={cssIcon}><i className="bi bi-card-list"></i></span>
                      <div>
                        <Card.Title>Requesters</Card.Title>
                        <Card.Text>  Create and edit requesters. </Card.Text>
                        <span className="text-muted"><small>{requestersCount}</small></span>
                        </div>
                      </Card.Body>
                    </Card></Col>
                  <Col><Card className={cssCard} onClick={() => redirect("/registrars/")}>
                    <Card.Body className={cssFlexRowStart}>
                      <span className={cssIcon}><i className="bi bi-collection"></i></span>
                      <div>
                        <Card.Title>Requester Groups</Card.Title>
                        <Card.Text> Create and edit collections of requesters. </Card.Text>
                        <span className="text-muted"><small>{requesterGroupsCount}</small></span>
                        </div>
                      </Card.Body>
                    </Card></Col>
                  <Col><Card className={cssCard} onClick={() => redirect("/registrars/")}>
                    <Card.Body className={cssFlexRowStart}>
                      <span className={cssIcon}><i className="bi bi-card-list"></i></span>
                      <div>
                        <Card.Title>Registrars</Card.Title>
                        <Card.Text> Create and edit registrars. </Card.Text>
                        <span className="text-muted"><small>{registrarsCount}</small></span>
                        </div>
                      </Card.Body>
                    </Card></Col>
                  <Col><Card className={cssCard} onClick={() => redirect("/registrars/")}>
                    <Card.Body className={cssFlexRowStart}>
                      <span className={cssIcon}><i className="bi bi-collection"></i></span>
                      <div>
                        <Card.Title>Registrar Groups</Card.Title>
                        <Card.Text> Create and edit collections of registrars. </Card.Text>
                        <span className="text-muted"><small>{registrarGroupsCount}</small></span>
                        </div>
                      </Card.Body>
                    </Card></Col>
                  </Row>
                </Container>
              </Col>
            <Col md={3}>
              <Card className="d-block m-0 p-0 border-0 bg-transparent">
                <Card.Body>
                  <Card.Title className={cssFlexRowStart}><span><i className="bi bi-book"></i></span> <span>Help Guide</span></Card.Title>
                  <p>Browse help topics from the User Guide </p>
                  <ul className="text-start m-0 px-4 py-0 lh-base">
                    <li><a className="link-dark" target="_blank" rel="noreferrer" href="https://eri-md.github.io/ERI-MD/#about.md">About Project Jake</a></li>
                    <li><a className="link-dark" target="_blank" rel="noreferrer" href="https://eri-md.github.io/ERI-MD/#dashboard.md">Dashboard</a></li>
                    <li><a className="link-dark" target="_blank" rel="noreferrer" href="https://eri-md.github.io/ERI-MD/#policy.md">Collection Policy Editor</a></li>
                    <li><a className="link-dark" target="_blank" rel="noreferrer" href="https://eri-md.github.io/ERI-MD/#requests.md">Request Templates Editor</a></li>
                    <li><a className="link-dark" target="_blank" rel="noreferrer" href="https://eri-md.github.io/ERI-MD/#registration.md">Registration Editor</a></li>
                    <li><a className="link-dark" target="_blank" rel="noreferrer" href="https://eri-md.github.io/ERI-MD/#registrars.md">Registrar Editors</a></li>
                    <li><a className="link-dark" target="_blank" rel="noreferrer" href="https://eri-md.github.io/ERI-MD/#psl.md">Registry List Editor</a></li>
                    </ul>
                  </Card.Body>
                </Card>
              <Card className="d-block m-0 p-0 border-0 bg-transparent">
                <Card.Body>
                <Card.Title className={cssFlexRowStart}><span><i className="bi bi-info-circle"></i></span> <span>Resources</span></Card.Title>
                  <p>Browse references for further information.</p>
                  <ul className="text-start m-0 px-4 py-0 lh-base">
                    <li><a className="link-dark" target="_blank" rel="noreferrer" href="https://www.edgemoorresearch.org/">Edgemoor Research Institute</a></li>
                    <li><a className="link-dark" target="_blank" rel="noreferrer" href="https://www.icann.org/rdrs-en">ICANN RDRS</a></li>
                    </ul>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </Section>
      </Main>
    <Footer />
    </Page>);
  }

export default Dashboard;
