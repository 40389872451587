/* NODE */
import React from 'react';
import {Spinner, Form, Container, Row, Col, Dropdown, DropdownItem, DropdownButton, InputGroup, Modal, Button, ButtonGroup, Stack, DropdownDivider} from 'react-bootstrap';
/* TYPES */
import { NamedItem, APIDictionary, APIRegistration, PolicyListItem, APIPolicy, APIRegistryList, APIRuleAttribute, Comparison, APITypedAttribute, APIAttribute} from 'api/types';
import {newRegistrantFacts, saveRegistrantFacts, deleteRegistrantFacts} from 'api/utility';
/* HOOKS */
import { PolicyListStore, PolicyStore, RegistrationStore, RegistrantFactsStore, RegistrantFactsListStore} from 'hooks';
/* CUSTOM COMPONENTS */
import Scope from 'components/molecules/Toolbars/Scope';

///////////////////////////////////////
// EDIT REGISTRANT FACT MODAL
///////////////////////////////////////

export function EditRegistrantFactModal(props: { show: boolean; registrantFacts: RegistrantFactsStore; handleClose: () => void; handleShow: () => void; handleSave: (name: string) => void; })
  {
  const [name, setName] = React.useState("");

  React.useEffect(() =>
    {
    setName(props.registrantFacts.data?.name ?? "");
    }, [props.registrantFacts.data]);

  return (<Modal show={props.show} onHide={props.handleClose} centered={true} autoFocus={true}>
    <Modal.Header closeButton><Modal.Title>Name the Registrant Fact:</Modal.Title></Modal.Header>
    <Modal.Body>
      <Form.Group>
        <Form.Label>Name:</Form.Label>
        <Form.Control type="text" value={name} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)} />
        </Form.Group>
      </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={props.handleClose}>Close</Button>
      <Button variant="primary" onClick={() => props.handleSave(name)}>Save Changes</Button>
      </Modal.Footer>
    </Modal>);
  }

///////////////////////////////////////
// SCOPE CALCULATOR
///////////////////////////////////////

const ScopeCalculator: React.FC<{ registration: RegistrationStore; policy: PolicyStore; registrantFacts: RegistrantFactsStore; registrantFactsList: RegistrantFactsListStore; policyList: PolicyListStore; dictionary: APIDictionary; }> = (props) =>
  {
  const [showEditFactModal, setShowEditFactModal] = React.useState(false);

  // state traces
  // React.useEffect(() => console.log("registration: ", props.registration.data), [props.registration.data]);
  // React.useEffect(() => console.log("policy: ", props.policy.data), [props.policy.data]);
  // React.useEffect(() => console.log("registrant fact: ", props.registrantFacts.data), [props.registrantFacts.data]);

  function handleClose ()
    {
    setShowEditFactModal(false);
    }

  function handleShow ()
    {
    setShowEditFactModal(true);
    }

  function onEditFactModal (name:string)
    {
    const hasChanged = Boolean(name !== props.registrantFacts.data?.name);
    if (props.registrantFacts.data && hasChanged) props.registrantFacts.updateData({name: name ?? "Untitled Registrant Fact"});
    handleClose();
    }

  function eventChangePolicy (e: React.ChangeEvent<HTMLSelectElement>)
    {
    props.registration.updateData({ policy_id: parseInt(e.target.value) });
    }

  function eventChangeRegistrantFact (e: React.ChangeEvent<HTMLSelectElement>)
    {
    props.registration.updateData({ registrant_facts_id: parseInt(e.target.value) });
    }

  function eventAddNewRegistrantFact ()
    {
    newRegistrantFacts().then((id: number) => { props.registration.updateData({ registrant_facts_id: id }); props.registrantFacts.updateID(id); }).finally(() => {props.registrantFactsList.refresh(); setShowEditFactModal(true);});
    }

  function eventDeleteRegistrantFact ()
    {
    if (props.registrantFacts.data?.id) deleteRegistrantFacts(props.registrantFacts.data.id).finally(() => { props.registrantFacts.updateData({ id: -1 }); props.registrantFactsList.refresh(); });
    }

  const regFactsListOptions = props.registrantFactsList.data?.map((d: NamedItem, i: number) => <option key={`registrantFact-${d.name}${i}`} value={d.id}>{d.name}</option>) ?? null;
  const policyListOptions = props.policyList.data?.map((policy: PolicyListItem, i: number) => { return <option key={`policy-${policy.id}-${i}`} value={policy.id}>{policy.name} {`(v.${policy.version})`}</option>; }) ?? null;

  return (!props.registration.data)
    ? <Spinner animation="border" variant="primary" className="position-absolute top-50 start-50 translate-middle" />
    : <React.Fragment>
        <Container>
          <Row>
            <Col>
              <Stack direction="vertical" gap={3}>
                <h6 className="text-center text-muted fw-medium m-0 p-0 lh-1">Registrant Fact:</h6>
                <InputGroup>
                  <DropdownButton variant="dark" title="Options" id="registrantFacts-dropdown">
                    <Dropdown.Item onClick={eventAddNewRegistrantFact}><i className="bi bi-plus"></i> Create </Dropdown.Item>
                    <Dropdown.Item onClick={() => setShowEditFactModal(true)}>Edit</Dropdown.Item>
                    <Dropdown.Item onClick={eventDeleteRegistrantFact}>Delete</Dropdown.Item>
                    </DropdownButton>
                  <Form.Select value={props.registrantFacts.data?.id ?? -1} onChange={eventChangeRegistrantFact}>
                    <option value={-1}>{"Choose a registrant fact..."}</option>
                    {regFactsListOptions}
                    </Form.Select>
                  </InputGroup>
                {props.registration.data && props.registration.data.scope_attributes
                  ? <Scope selectedScopeAttributes={props.registration.data.scope_attributes} selectedTLD={props.registration.data.tlds} comparisonTLD={props.policy.data?.tlds} defaultDropdown={1} scopeAttributes={props.dictionary.scope_attributes.map((att) => ({id: att.id, name: att.name, values: att.values.filter((val) => props.registration.singletonScopes.includes(val.value))}))} onAttributeChange={(newScopeAtts: APIRuleAttribute[]) => props.registration.updateData({ scope_attributes: newScopeAtts})} onRegistryListChange={(newTLDList: APIRegistryList | null) => props.registration.updateData({ tlds: newTLDList, tld_list_id: newTLDList?.id ?? null })} comparisonScopeAttributes={props.policy.data?.scope_attributes} />
                  : null}
                </Stack>
              </Col>
            <Col>
              <Stack direction="vertical" gap={3}>
                <h6 className="text-center text-muted fw-medium m-0 p-0 lh-1">Policy:</h6>
                <InputGroup>
                  <DropdownButton variant="dark" title="Options" id="policy-scope-dropdown">
                    <DropdownItem href={`/#/policy/${props.policy?.data?.id}`} disabled={!props.policy}>View in Policy Editor</DropdownItem>
                    <DropdownItem onClick={() => props.registrantFacts.eventCopyDefaults(props.policy.data)} disabled={!props.policy.data}>Copy Defaults</DropdownItem>
                    </DropdownButton>
                  <Form.Select value={props.policy.data?.id ?? -1} onChange={eventChangePolicy}>
                    <option value={-1}>{"Choose a policy..."}</option>
                    {policyListOptions}
                    </Form.Select>
                  </InputGroup>
                {props.policy.data && props.policy.data.scope_attributes
                  ? <Scope selectedScopeAttributes={props.policy.data.scope_attributes} selectedTLD={props.policy.data.tlds} defaultDropdown={0} scopeAttributes={props.dictionary.scope_attributes} onAttributeChange={(newScopeAtts: APIRuleAttribute[]) => props.policy.updateData({ scope_attributes: newScopeAtts })} onRegistryListChange={(newTLDList: APIRegistryList | null) => props.policy.updateData({ tlds: newTLDList, tld_list_id: newTLDList?.id ?? null})} />
                  : null}
                </Stack>
              </Col>
            </Row>
          </Container>
        <EditRegistrantFactModal show={showEditFactModal} registrantFacts={props.registrantFacts} handleClose={handleClose} handleShow={handleShow} handleSave={onEditFactModal} />
        </React.Fragment>
  }

export default ScopeCalculator;

