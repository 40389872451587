/* NODE PACKAGES */
import React from 'react';
import {Spinner, Button, ButtonGroup, Dropdown, DropdownItem, DropdownButton, OverlayTrigger, Tooltip} from 'react-bootstrap';

interface UpdateProps
  {
  onClick?: () => void | undefined;
  }

function Update (props: UpdateProps)
  {
  return props.onClick ? <span className="btn btn-sm btn-success m-0 ms-auto p-1 lh-1" onClick={props.onClick}><i className="bi bi-arrow-up-circle"></i></span> : null;
  }

export default Update;
