/* NODE PACKAGES */
import React from 'react';
/* CUSTOM COMPONENTS */
import Draggable, {DragItem} from 'components/molecules/Drag&Drop/Draggable';

interface ColumnProps
  {
  category: string;
  updateCategory: (status:string) => void;
  children?: React.ReactNode;
  }

///////////////////////////////////////
// DROPPABLE
///////////////////////////////////////

function Droppable (props:ColumnProps)
  {
  const [items, setItems] = React.useState<DragItem[]>([]);
  const [drop, setDrop] = React.useState(false);

  React.useEffect(() =>
    {
    const taskList:DragItem[] | null | undefined = React.Children.map(props.children, element =>
      {
      if (!React.isValidElement(element)) return;
      if (element.props.item) return element.props.item;
      });

    if (taskList) setItems(taskList);
    }, [props.children]);

  // React.useEffect(() =>
  //   {
  //   items.forEach(t => console.log(t.ID, t.category, t.title));
  //   }, [items]);

  const eventDragOver = (e: React.DragEvent<HTMLDivElement>) =>
    {
    e.preventDefault();
    setDrop(true);
    };

  const eventDragLeave = (e: React.DragEvent<HTMLDivElement>) =>
    {
    e.preventDefault();
    setDrop(false);
    };

  const eventDrop = (e: React.DragEvent<HTMLDivElement>) =>
    {
    props.updateCategory(props.category);
    setDrop(false);
    };

  const cssClass = drop ? "d-block w-100 bg-light w-100 mx-auto my-0 p-2 rounded-3" : "d-block w-100 bg-light m-0 p-2 rounded-3";
  const cssStyle = drop ? {border: '2px rgba(var(--bs-secondary-rgb), 0.75) dashed', minHeight: '20.0rem'} : {border: '2px rgba(var(--bs-secondary-rgb), 0.25) dashed', minHeight: '20.0rem'};
  return <div className={cssClass} style={cssStyle} onDragOver={eventDragOver} onDrop={eventDrop} onDragLeave={eventDragLeave}>
    <div className="d-flex flex-column justify-content-between align-items-stretch gap-2 m-0 p-0">
      {props.children}
      </div>
    </div>;
  }

export default Droppable;