/* NODE PACKAGES */
import React from 'react';
import _ from 'lodash';
import axios, {AxiosRequestConfig, AxiosResponse, AxiosError} from 'axios';
/* API */
import {APIDictionary, APIPolicy, APIRuleAttribute, APIRule, PolicyListItem } from "api/types";
import {getRuleForElement, getPolicy, savePolicy} from "api/utility";
/* HOOKS */
import {useAxios} from 'hooks';

export type PolicyStore =
  {
  data: APIPolicy | null;
  unsavedChanges: boolean;
  scope: JSX.Element | null;
  refreshData: () => void;
  updateData: (newValues: Partial<APIPolicy>) => void;
  updateID(id: number): void;
  clearData: () => void;
  eventSave: () => void;
  getRule: (elementID: number) => APIRule;
  getScopeAttribute(attID: number): APIRuleAttribute | undefined
  };

interface PolicyDataProps
  {
  selectedPolicyID: number;
  dictionary: APIDictionary;
  }

function usePolicyStore(props: PolicyDataProps): PolicyStore
  {
  const [policyID, setPolicyID] = React.useState<number>(props.selectedPolicyID);
  const [APIdata, APIerror, APIloading, APIcall] = useAxios({ method: 'GET' , url: `/api/policy/${policyID}`});
  const [data, setData] = React.useState<APIPolicy | null>(null);
  const [unsavedChanges, setUnsavedChanges] = React.useState<boolean>(false);

  React.useEffect(() =>
    {
    if (policyID === -1) setData(null);
    else refreshData();
    }, [policyID]);

  React.useEffect(() =>
    {
    if (!APIdata || APIerror || APIloading) return;
    setData(APIdata.policy);
    }, [APIdata]);

  function updateID (id:number)
    {
    setPolicyID(id);
    }

  function refreshData ()
    {
    APIcall();
    }

  function updateData (newValues: Partial<APIPolicy>)
    {
    if (!data) return;
    const newPolicy = Object.assign({}, data, newValues);
    setData(newPolicy as APIPolicy);
    setUnsavedChanges(true);
    }

  function clearData ()
    {
    setData(null);
    setUnsavedChanges(true);
    }

  function eventSave ()
    {
    if (!data) return;
    savePolicy(data);
    setUnsavedChanges(false);
    }

  function getRule (elementID:number)
    {
    return data?.rules?.find((r) => r.element_id === elementID);
    }

  function getScopeAttribute (attID: number): APIRuleAttribute | undefined
    {
    return data?.scope_attributes?.filter((policyAtt:APIRuleAttribute) => policyAtt.attribute_id === attID).at(0);
    }

  return {data, unsavedChanges, updateID, refreshData, updateData, clearData, eventSave, getRule, getScopeAttribute} as PolicyStore;
  }

export default usePolicyStore;

