/* NODE PACKAGES */
import React from 'react';
import ReactDOM from 'react-dom/client';

/* CUSTOM COMPONENTS */
import App from 'App';
import reportWebVitals from 'common/reportWebVitals';
import { DOM } from "common/DOM";
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'stylesheet.css';

DOM.element.setAttribute('data-bs-theme','light');
ReactDOM.createRoot(DOM.root).render(<React.StrictMode><App /></React.StrictMode>);
reportWebVitals();
