/* NODE PACKAGES */
import React from 'react';
import { AxiosRequestConfig } from 'axios';
/* TYPES */
import { PolicyListItem, APIPolicy} from 'api/types';
/* HOOKS */
import {useAxios} from 'hooks';

// CONSTANT: Defines request configuration to fetch policy list
const GetPolicies:AxiosRequestConfig = { method: 'GET', url: '/api/policies', }

// CONSTANT: Defines the ordering of policy organization types for sorting the policy list. Lower numbers appear earlier in the sorted list.
export const ORG_TYPE_ORDERING: {[key in APIPolicy['org_type']]: number} =
  {
  "policy_authority": 1,
  "registry": 2,
  "registrar": 3
  };

export type PolicyListStore =
  {
  loading: boolean;
  error:any;
  data: PolicyListItem[] | null;
  refresh: () => void;
  };

function usePolicyListStore(): PolicyListStore
  {
  const [response, error, loading, callAxios] = useAxios(GetPolicies);
  const [data, setData] = React.useState<PolicyListItem[] | null>(null);

  React.useEffect(() =>
    {
    if (response) setData(response.policies?.filter((item: PolicyListItem) => !item.deleted).sort((a:PolicyListItem, b:PolicyListItem) => a.org_type === b.org_type ? a.name.localeCompare(b.name) : ORG_TYPE_ORDERING[a.org_type] - ORG_TYPE_ORDERING[b.org_type]) ?? []);
    }, [response]);

  const refresh = () => callAxios();

  return {loading, error, data, refresh} as PolicyListStore;
  }

export default usePolicyListStore;

