/* NODE PACKAGES */
import React from 'react';
import {Spinner, Alert, Badge, Stack, Container, Row, Col, Form, InputGroup, Button, ToggleButtonGroup, ToggleButton} from 'react-bootstrap';
/* API */
import { PolicyListItem, } from 'api/types';
import {createPolicy} from 'api/utility';
/* UTILITIES */
import {usePolicyListStore} from 'hooks';
/* TEMPLATES */
import {Page, Header, Main, Section, FlexBox, Footer} from "components/atoms/Templates";
/* CUSTOM COMPONENTS */
import IndexListing, {IndexListItem} from "components/molecules/Lists/IndexListing";

///////////////////////////////////////
// POLICY INDEX LISTING
///////////////////////////////////////

function Policies ()
  {
  const policyList = usePolicyListStore();
  const listPA = React.useMemo<IndexListItem[]>(() => policyList.data?.filter(item => !item.deleted).filter(li => li.org_type === "policy_authority").map((listItem: PolicyListItem) => ({href: `/#/policy/${listItem.id}`, name: `${listItem.name} (v.${listItem.version})`})) ?? [], [policyList.data]);
  const listRR = React.useMemo<IndexListItem[]>(() => policyList.data?.filter(item => !item.deleted).filter(li => li.org_type === "registrar").map((listItem: PolicyListItem) => ({href: `/#/policy/${listItem.id}`, name: `${listItem.name} (v.${listItem.version})`})) ?? [], [policyList.data]);
  const listRY = React.useMemo<IndexListItem[]>(() => policyList.data?.filter(item => !item.deleted).filter(li => li.org_type === "registry").map((listItem: PolicyListItem) => ({href: `/#/policy/${listItem.id}`, name: `${listItem.name} (v.${listItem.version})`})) ?? [], [policyList.data]);

  const cssIcon = "d-block btn btn-outline-dark m-0 p-1 border border-2 border-dark rounded-circle fs-4 lh-1 fw-normal";
  return ((policyList.loading) ? <Spinner animation="border" variant="primary" className="position-absolute top-50 start-50 translate-middle" /> : (policyList.error || !policyList.data) ? <Alert variant="warning">{"Data is not available at this time."}</Alert> :
    <Page>
      <Header>
        <span>{`Collection Policies`} <small className="text-muted">{`(${policyList.data?.length ?? 0})`}</small></span>
        <div className="ms-auto"></div>
        <span className={cssIcon} onClick={createPolicy}><i className="bi bi-plus-lg"></i></span>
        </Header>
      <Main>
        <Section>
          <Container>
            <Row>
              <Col><h4 className="text-center">Policy Authority</h4><IndexListing data={listPA} /></Col>
              <Col><h4 className="text-center">Registry</h4><IndexListing data={listRY} /></Col>
              <Col><h4 className="text-center">Registrar</h4><IndexListing data={listRR} /></Col>
              </Row>
            </Container>
          </Section>
        </Main>
      <Footer />
      </Page>);
  }

export default Policies;

/*
Development Notes:

<span>&#187;</span>
<span>&#8250;</span>

*/
