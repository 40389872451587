/* NODE PACKAGES */
import React from 'react';
import {Offcanvas, Button, DropdownItem, ListGroup, ListGroupItem, Container, Row, Col, Card} from 'react-bootstrap';

// data representing the legend items
export const infoLegend =
  [
  {letter: "a", description: "Affirmed", notes: "Form is complete. Client has affirmed or reaffirmed." },
  {letter: "b", description: "Needs reaffirmation", notes: "One of us has modified a completed form." },
  {letter: "c", description: "Complete", notes: "Needs client affirmation."},
  {letter: "d", description: "Draft" },
  {letter: "p", description: "Missing data but no red", notes: "Form is incomplete but there aren't any red splotches." },
  {letter: "q", description: "Missing data with red", notes: "Form has obvious inconsistencies or incompleteness." },
  {letter: "x", description: "Obsolete", notes: "This should be deleted from the system." },
  {letter: "z", description: "Fictional", notes: "This policy is our own invention and does not apply to a real organization. It might be based on a real organization, but it's not intended to be attributed. This prefix can be combined with another letter." }
  ];

function HelpSheet (props: {checked: boolean, setChecked: () => void})
  {
  const cssRow = "m-0 p-0 gap-3 mb-4";
  const cssColumn = "m-0 p-0 border-0"
  const cssFlexStart = "d-flex flex-row justify-content-start align-items-start gap-3 m-0 p-0 border-0";
  return <Offcanvas show={props.checked} onHide={props.setChecked} keyboard={true} placement='start'>
      <Offcanvas.Header className="bg-light" closeButton><Offcanvas.Title>Help Sheet</Offcanvas.Title></Offcanvas.Header>
      <Offcanvas.Body>
        <Container>
          <Row className={cssRow}>
            <Col className={cssColumn}>
              <Card className="m-0 p-0 border-0 bg-transparent w-100 h-100">
                <Card.Body className={cssFlexStart}>
                  <Container>
                    <Card.Title>Help Guide</Card.Title>
                    <Card.Text> Browse help topics from the User Guide </Card.Text>
                      <ul className="text-start m-0 px-4 py-0 lh-base">
                        <li><a className="link-dark" target="_blank" rel="noreferrer" href="https://eri-md.github.io/ERI-MD/#app.md">General</a></li>
                        <li><a className="link-dark" target="_blank" rel="noreferrer" href="https://eri-md.github.io/ERI-MD/#policy.md">Policy Expression (PEX) Editor</a></li>
                        <li><a className="link-dark" target="_blank" rel="noreferrer" href="https://eri-md.github.io/ERI-MD/#requests.md">Request Templates</a></li>
                        <li><a className="link-dark" target="_blank" rel="noreferrer" href="https://eri-md.github.io/ERI-MD/#registration.md">Registration Editor</a></li>
                        <li><a className="link-dark" target="_blank" rel="noreferrer" href="https://eri-md.github.io/ERI-MD/#registrars.md">Registrar Editors</a></li>
                        <li><a className="link-dark" target="_blank" rel="noreferrer" href="https://eri-md.github.io/ERI-MD/#psl.md">Public Suffix List (PSL) Editor</a></li>
                        </ul>
                    </Container>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          <Row className={cssRow}>
            <Col className={cssColumn}>
              <Card className="m-0 p-0 border-0 bg-transparent w-100 h-100">
                <Card.Body className={cssFlexStart}>
                  <Container>
                    <Card.Title>Resources</Card.Title>
                    <Card.Text> Browse references for further information. </Card.Text>
                      <ul className="text-start m-0 px-4 py-0 lh-base">
                        <li><a className="link-dark" target="_blank" rel="noreferrer" href="https://www.edgemoorresearch.org/">Edgemoor Research Institute</a></li>
                        <li><a className="link-dark" target="_blank" rel="noreferrer" href="https://www.icann.org/rdrs-en">ICANN RDRS</a></li>
                        </ul>
                    </Container>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          <Row className={cssRow}>
            <Col className={cssColumn}>
              <Card className="m-0 p-0 border-0 bg-transparent w-100 h-100">
                <Card.Body className={cssFlexStart}>
                  <Container>
                    <Card.Title>File Name Legend</Card.Title>
                    <Card.Text> Reference for naming conventions. </Card.Text>
                      <ListGroup>
                        {infoLegend.map((item) => <ListGroupItem title={item.notes} key={item.letter} className="d-flex justify-content-start align-items-center gap-3"><small className="fw-bold">{item.letter}</small><small>{item.description}</small></ListGroupItem>)}
                        </ListGroup>
                      <p className="m-0 px-3 py-0 text-dark text-opacity-50"><small>Table 1: File Name Abbreviations </small></p>
                    </Container>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </Offcanvas.Body>
      </Offcanvas>
  }

export function HelpGuideMenuItem ()
  {
  const [checked, setChecked] = React.useState(false);

  const eventHelp = () => setChecked(!checked);

  const cssDropdownItem = "d-flex justify-content-between align-items-center lh-base";
  return (<React.Fragment>
    <DropdownItem className={cssDropdownItem} onClick={eventHelp}><span>Help</span> <i className="bi bi-question-circle"></i></DropdownItem>
    <HelpSheet checked={checked} setChecked={eventHelp} />
    </React.Fragment>);
  }

// Functional component representing a legend table
export default function HelpGuideButton ()
  {
  const [checked, setChecked] = React.useState(false);

  const eventHelp = () => setChecked(!checked);

  return (<React.Fragment>
    <Button variant="dark" className="fs-4 lh-1" disabled={false} onClick={eventHelp}><i className="bi bi-question-circle"></i></Button>
    <HelpSheet checked={checked} setChecked={eventHelp} />
    </React.Fragment>);
  }

/*
Developer Notes:

*/
