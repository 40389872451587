/* NODE PACKAGES */
import React from 'react';
import {Spinner, Button, ButtonGroup, Container, Row, Col, ListGroup, ListGroupItem, DropdownItem, DropdownButton, Stack, OverlayTrigger, Tooltip, Modal} from 'react-bootstrap';
/* API */
import {APIRule, APIDictionary, APIPolicy, PolicyListItem, PolicyVersionReference } from 'api/types';
import {getAttributeFromRule, getRuleForElement, getPolicy, getPolicyList } from 'api/utility';
/* HOOKS */
import {usePolicyListStore, PolicyListStore} from 'hooks';
/* UTILITIES */
import { redirect } from "common/window";
/* CUSTOM COMPONENTS */
import Checklist, {ChecklistItem, ChecklistHeader} from "components/molecules/Menu/Checklist";
import IndexListing, {IndexListItem} from "components/molecules/Lists/IndexListing";

///////////////////////////////////////
// SWITCH BUTTON
///////////////////////////////////////

function SwitchButton (props: {disabled: boolean; mode: boolean, eventClick: () => void;})
  {
  const buttonText = props.mode ? "Unswitch" : "Switch";
  return (<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Tooltip>{"Switch to comparison policy"}</Tooltip>}><Button variant="dark" disabled={props.disabled} onClick={props.eventClick}>{buttonText}</Button></OverlayTrigger>);
  }

///////////////////////////////////////
// COMPARE BUTTON
///////////////////////////////////////

function CompareButton (props: {disabled: boolean; eventClick: () => void; comparisonPolicy: APIPolicy | null})
  {
  const buttonName = props.comparisonPolicy ? "Uncompare" : "Compare";
  return (<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Tooltip>{"Compare selected policies"}</Tooltip>}><Button variant="dark" disabled={props.disabled} onClick={props.eventClick}>{buttonName}</Button></OverlayTrigger>);
  }

///////////////////////////////////////
// SELECT SUBJECTS BUTTON
///////////////////////////////////////

interface SubjectsCheckListProps
  {
  policy: APIPolicy;
  comparisonPolicy: APIPolicy | null;
  policyList: PolicyListItem[] | null;
  selectedSubjects: number [];
  policyMetadataCache: {[key: number]: PolicyListItem};
  disabled: boolean;
  updateSelectedSubjects: (newSubjects: number[]) => void;
  updatePolicy: (newValues: Partial<APIPolicy>) => void;
  updateMetaDataCache: (newPolicy: PolicyListItem) => void;
  }

function SelectSubjectsButton (props: SubjectsCheckListProps)
  {
  const [isLatestVersion, setIsLatestVersion] = React.useState<Record<string, boolean>>({});
  const [latestVersions, setLatestVersion] = React.useState<Record<string, number>>({});

  React.useEffect(() =>
    {
    const fetchVersionStatuses = async () =>
      {
      const statuses: Record<string, boolean> = {};
      const recents: Record<string, number> = {};

      for (const pID of props.policy.subject_to?.filter(pID => !props.policyMetadataCache[pID]?.deleted) ?? [])
        {
        const versions: PolicyVersionReference [] = await getPolicy(pID).then((p: APIPolicy) => p.versions);
        //versions.map(v => console.log(`${pID} version ${v.version}: ${v.id}`));
        statuses[pID] = versions.length <= props.policyMetadataCache[pID]?.version;
        recents[pID] = versions[0]?.id;
        }

      setIsLatestVersion(statuses);
      setLatestVersion(recents);
      };

    fetchVersionStatuses();
    }, [props.policy.subject_to, props.policyMetadataCache]);

  // console.log traces of props and states
  // React.useEffect(() => console.log(`Policy Subjects: ${props.policy.subject_to.join(",")}`), [props.policy.subject_to]);
  // React.useEffect(() => console.log(`policyMetadataCache: ${props.policyMetadataCache}`), [props.policyMetadataCache]);
  // React.useEffect(() => console.log(`selectedSubjects: ${props.selectedSubjects}`), [props.selectedSubjects]);
  // React.useEffect(() => console.log(`policyList: ${props.policyList?.map(i => `\n ${i.id} ${i.name} ${i.version}`)}`), [props.policyList]);

  const upgradeSubject = (pID: number, newPolicy: PolicyListItem | null) =>
    {
    if (!newPolicy) return;
    const subjects = props.policy.subject_to?.filter(i => i !== pID) ?? [];
    subjects?.push(newPolicy.id);
    props.updatePolicy({subject_to: subjects}); // add subject
    props.updateMetaDataCache(newPolicy);
    }

  const deleteSubject = (pID: number) =>
    {
    if (props.policy && props.policy.subject_to.includes(pID)) props.updatePolicy({subject_to: props.policy.subject_to.filter(i => i !== pID)});
    }

  // adds a policy ID to the selected comparison list
  const checkSubject = (pID: number) =>
    {
    props.updateSelectedSubjects(props.selectedSubjects.concat([pID]));
    }

  // removes a policy ID from the selected comparison list
  const uncheckSubject = (pID: number) =>
    {
    props.updateSelectedSubjects(props.selectedSubjects.filter(i => i !== pID));
    }

  // toggle policy ID from selected comparison list
  const toggleCheck = (pID: number) =>
    {
    if (props.policy) props.selectedSubjects.includes(pID) ? uncheckSubject(pID) : checkSubject(pID);
    }

  const comparisonSubjects = props.policy.subject_to?.filter(pID => !props.policyMetadataCache[pID]?.deleted);

  const checkListItems = comparisonSubjects.map((pID) =>
    {
    const key = `comparison_${pID}`;
    const checkboxState = props.selectedSubjects?.includes(pID) ? true : false;
    const checkboxName = `${props.policyMetadataCache[pID]?.name} (v.${props.policyMetadataCache[pID]?.version})` ?? `<policy ${pID}>`;
    const checkboxAction = () => toggleCheck(pID);
    //console.log(`Latest Version: ${pID}: ${latestVersions[pID]} = ${isLatestVersion[pID]}`);
    const isLatest = isLatestVersion[pID];

    const upgradeAction = isLatest ? undefined : () => upgradeSubject(pID, props.policyList?.find(p => p.id === latestVersions[pID]) ?? null);

    return <ChecklistItem key={key} eventKey={key} checked={checkboxState} text={checkboxName} onChange={checkboxAction} upgrade={upgradeAction} remove={() => deleteSubject(pID)} disabled={Boolean(props.comparisonPolicy !== null)} />;
    }) ?? [];

  const isDisabled = Boolean(props.disabled);
  return <Checklist title="Select" tooltip='Select subjects for the comparison and click Compare'>{checkListItems.length ? checkListItems : <DropdownItem disabled>No policies available</DropdownItem>} </Checklist>;
  }

///////////////////////////////////////
// EDIT SUBJECTS BUTTON
///////////////////////////////////////

interface MultiSelectPolicyListProps
  {
  policy: APIPolicy;
  policyList: PolicyListItem[] | null;
  selectedPolicies?: number[];
  showModal: boolean;
  updateMetaDataCache: (policy: PolicyListItem) => void;
  updatePolicy: (newValues: Partial<APIPolicy>) => void;
  toggle: () => void;
  }

function MultiSelectPolicyModal (props: MultiSelectPolicyListProps)
  {
  // add policy ID from subject list
  const addSubject = (pID: number) =>
    {
    if (props.policy && !props.policy.subject_to.includes(pID)) props.updatePolicy({subject_to: props.policy.subject_to.concat([pID])});
    }

  // remove policy ID from subject list
  const removeSubject = (pID: number) =>
    {
    if (props.policy && props.policy.subject_to.includes(pID)) props.updatePolicy({subject_to: props.policy.subject_to.filter(i => i !== pID)});
    }

  // toggle policy ID from subject list
  const toggleSubject = (pID: number) =>
    {
    if (props.policy) props.policy.subject_to.includes(pID) ? removeSubject(pID) : addSubject(pID);
    }

  // Updates the comparison policy list when a new policy is selected: Checks if the policy metadata is cached, and caches it if not; adds/removes policy ID subject_to list
  const updateSubjects = (newPolicy: PolicyListItem) =>
    {
    if (props.policy)
      {
      const pID = newPolicy.id;
      props.updateMetaDataCache(newPolicy);
      toggleSubject(pID);
      }
    }

  const subjects = props.policyList?.filter(policy => !policy.deleted).map(policy =>
    {
    const key = `policy-${policy.id}`;
    const checkboxState = props.selectedPolicies?.includes(policy.id) ? true : false;
    const checkboxText = `${policy.name} (v.${policy.version})`;
    const checkboxCategory = policy.org_type;
    const checkboxAction = () => updateSubjects(policy);

    return {key, checkboxState, checkboxText, checkboxCategory, checkboxAction};
    }) ?? null;

  return <Modal size="xl" centered scrollable show={props.showModal} onHide={props.toggle}>
    <Modal.Header closeButton>
      <Modal.Title>{"Edit Subjects for Policy Comparisons"}</Modal.Title>
      </Modal.Header>
    <Modal.Body>
      <Container fluid>
        <Row>
          <Col>
            <h4 className="text-center text-muted">{"Policy Authority"}</h4>
            <ListGroup>
              {subjects?.filter(subject => subject.checkboxCategory === "policy_authority")?.map(item => <ListGroupItem key={item.key} eventKey={item.key} variant="light" className="pointer" active={item.checkboxState} onClick={item.checkboxAction}>{item.checkboxText}</ListGroupItem>) ?? <ListGroupItem>{"No Policies Found"}</ListGroupItem>}
              </ListGroup>
            </Col>
          <Col>
            <h4 className="text-center text-muted">{"Registry"}</h4>
            <ListGroup>
              {subjects?.filter(subject => subject.checkboxCategory === "registry")?.map(item => <ListGroupItem key={item.key} eventKey={item.key} variant="light" className="pointer" active={item.checkboxState} onClick={item.checkboxAction}>{item.checkboxText}</ListGroupItem>) ?? <ListGroupItem>{"No Policies Found"}</ListGroupItem>}
              </ListGroup>
            </Col>
          <Col>
            <h4 className="text-center text-muted">{"Registrar"}</h4>
            <ListGroup>
              {subjects?.filter(subject => subject.checkboxCategory === "registrar")?.map(item => <ListGroupItem key={item.key} eventKey={item.key} variant="light" className="pointer" active={item.checkboxState} onClick={item.checkboxAction}>{item.checkboxText}</ListGroupItem>) ?? <ListGroupItem>{"No Policies Found"}</ListGroupItem>}
              </ListGroup>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    <Modal.Footer><Button variant="dark" onClick={props.toggle}>Done</Button></Modal.Footer>
    </Modal>
  }

interface EditSubjectsProps
  {
  policy: APIPolicy;
  policyList: PolicyListItem[] | null;
  selectedPolicies?: number[];
  disabled: boolean;
  updateMetaDataCache: (policy: PolicyListItem) => void;
  updatePolicy: (newValues: Partial<APIPolicy>) => void;
  }

function EditSubjectsButton (props: EditSubjectsProps)
  {
  const [showModal, setShowModal] = React.useState(false);
  const hide = () => setShowModal(false);
  const show = () => setShowModal(true);
  const toggle = () => setShowModal(!showModal);

  return <React.Fragment>
    <Button variant="dark" onClick={() => show()} disabled={props.disabled}>{"Edit"}</Button>
    <MultiSelectPolicyModal policy={props.policy} policyList={props.policyList} selectedPolicies={props.policy.subject_to} updateMetaDataCache={props.updateMetaDataCache} updatePolicy={props.updatePolicy} showModal={showModal} toggle={toggle} />
    </React.Fragment>
  }

///////////////////////////////////////
// COMPARISON TOOLBAR
///////////////////////////////////////

interface PolicyComparisonToolProps
  {
  dictionary: APIDictionary;
  policy: APIPolicy;
  comparisonPolicy: APIPolicy | null;
  selectedSubjects: number [];
  policyMetadataCache: {[key: number]: PolicyListItem};
  updateSelectedSubjects: (newSubjects: number[]) => void;
  updateComparisonPolicy: (p: APIPolicy | null) => void;
  updatePolicy: (newValues: Partial<APIPolicy>) => void;
  updateMetaDataCache: (newPolicy: PolicyListItem) => void;
  eventFlip: (newPolicy: APIPolicy, newComparisonPolicy: APIPolicy | null) => void;
  }

function ComparisonToolbar (props: PolicyComparisonToolProps)
  {
  const [switchMode, setSwitchMode] = React.useState<boolean>(false);
  const policyList: PolicyListStore = usePolicyListStore();

  React.useEffect(() => console.log("ComparisonToolbar: switch mode? ", switchMode), [switchMode]);

  // Returns a list of policy IDs to use for comparison, filtered from the policy's subject_to list; Removes any policies that: Are in the ignoredSubjectToPolicies list; Have been deleted (via the policyMetadataCache);
  const selectedComparisonPolicies = props.policy?.subject_to?.filter(p => props.selectedSubjects.includes(p) && props.policyMetadataCache[p]?.deleted !== true) ?? [];

  // Downloads and computes the intersection of policies for comparison.
  const downloadComparisonPolicies = async (policies: number[], setPolicyLoadingProgress: (progress: number) => void, dataDictionary: APIDictionary): Promise<APIPolicy | null> =>
    {
    if (policies.length === 0) return null; // Takes the user out of comparison mode
    let policyIntersection: APIPolicy | null = null;
    setPolicyLoadingProgress(0);

    for (let i = 0; i < policies.length; i++)
      {
      const newPolicy = await getPolicy(policies[i]);
      if (newPolicy === null) return null; // Takes the user out of comparison mode
      policyIntersection = (policyIntersection === null) ? newPolicy : policyAnd(dataDictionary, policyIntersection, newPolicy);
      setPolicyLoadingProgress((i + 1) / policies.length * 100);
      }

    setPolicyLoadingProgress(100);
    return policyIntersection as APIPolicy;
    }

  // Download and set the comparison policy based on selected policies
  const compare = () =>
    {
    try
      {
      const comparisons = (props.comparisonPolicy) ? [] : selectedComparisonPolicies; // if we are already in comparison mode, unset the comparison for toggle effect
      downloadComparisonPolicies(comparisons, (progress) => null, props.dictionary).then(props.updateComparisonPolicy);
      }
    catch (error: any)
      {
      console.error(error);
      }
    }

  // Downloads policy and sets it as the comparison, then redirects Policy Editor to the comparison as the primary active policy
  const flipComparisons = () =>
    {
    try
      {
      if (props.policy && (props.comparisonPolicy !== null) && props.dictionary)
        {
        const comparisonPolicy = props.comparisonPolicy;
        downloadComparisonPolicies([props.policy.id], (progress) => null, props.dictionary)
          //.then(props.updateComparisonPolicy)
          .then((p: APIPolicy | null) => props.eventFlip(comparisonPolicy, p))
          .finally(() =>
            {
            //redirect(`/policy/${props.comparisonPolicy?.id}`);
            //props.updatePolicy(comparisonPolicy);
            setSwitchMode(prev => !prev);
            });
        }
      }
    catch (error: any)
      {
      console.error(error);
      }
    }

  return (<Stack direction='vertical' gap={0} className="text-center">
    <h6 className="text-center text-muted fw-medium">Compare Collection Policies:</h6>
    <div>
    <ButtonGroup vertical={false} className="shadow-sm">
    <EditSubjectsButton policy={props.policy} policyList={policyList.data ?? null} selectedPolicies={props.policy.subject_to} updateMetaDataCache={props.updateMetaDataCache} updatePolicy={props.updatePolicy} disabled={(switchMode === true || props.comparisonPolicy !== null)} />
    <SelectSubjectsButton policy={props.policy} comparisonPolicy={props.comparisonPolicy} policyList={policyList.data ?? null} selectedSubjects={props.selectedSubjects} policyMetadataCache={props.policyMetadataCache} updateSelectedSubjects={props.updateSelectedSubjects} updatePolicy={props.updatePolicy} updateMetaDataCache={props.updateMetaDataCache} disabled={(switchMode === true)} />
    <CompareButton eventClick={compare} comparisonPolicy={props.comparisonPolicy} disabled={(switchMode === true)} />
    <SwitchButton disabled={!(props.comparisonPolicy && selectedComparisonPolicies.length === 1)} mode={switchMode} eventClick={flipComparisons} />
    </ButtonGroup>
    </div>
    </Stack>);
  }

export default ComparisonToolbar;

///////////////////////////////////////
// HELPERS
///////////////////////////////////////

// Compute the intersection of two policies, creating a new policy with rules that represent the bitwise AND of corresponding rules.
// - if a rule is missing in either policy, the attributes are treated as all zeros.
// TODO: Consider returning an array so that .split() is not needed to format the list of names
function policyAnd(dict: APIDictionary, pa: APIPolicy, pb: APIPolicy): APIPolicy
  {
  // Concatenate rules for each element in each element group
  const newRules = ([] as APIRule[]).concat(...dict.element_groups.map(eg =>
    {
    return eg.elements.map(el =>
      {
      const newRule: APIRule = { id: 0, element_id: el.id, attributes: [] }; // Initialize a new rule for the current element
      const paRule = getRuleForElement(pa, el.id); // Get rules for the current element from each input policy
      const pbRule = getRuleForElement(pb, el.id); // Get rules for the current element from each input policy
      // Check if both rules exist; if an attribute is missing in either rule, treat it as 0; compute the bitwise AND of attributes from both rules
      if (paRule && pbRule)
        {
        newRule.attributes = dict.rule_attributes.map(att =>
          {
          const paAtt = getAttributeFromRule(paRule, att.id);
          const pbAtt = getAttributeFromRule(pbRule, att.id);
          if (!paAtt || !pbAtt) return { attribute_id: att.id, value: 0 };
          return { attribute_id: att.id, value: paAtt.value & pbAtt.value };
          });
        }

      return newRule;
      });
    }));

  // Create a new APIPolicy object with the computed rules
  return Object.assign(pa, { name: pa.name + " ∩ " + pb.name, rules: newRules });
  }
