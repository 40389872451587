/* NODE PACKAGES */
import React from 'react';
import {Form} from 'react-bootstrap';

// Props for the Note component
interface NoteProps
  {
  value: string | null;
  onChange?: (newValue: string) => void;
  }

// State for the Note component
interface NoteState
  {
  mouseover: boolean;
  expanded: boolean;
  noteText: string;
  }

// Note component definition
export default class Note extends React.Component<NoteProps, NoteState>
  {
  constructor(props: NoteProps)
    {
    super(props);
    this.state =
      {
      mouseover: false,
      expanded: false,
      noteText: this.props.value || "",
      };
    }

  isActive()
    {
    return ((this.props.value && this.state.mouseover) || this.state.expanded)
    }

  render()
    {
    // behavior of mouseout is a little tricky, depending on whether there is an existing note or not.
    // If there is an existing note, then we will show the note and handle mouseout in that box.
    // Otherwise, we will handle mouseout on the icon since there isn't anything else.
    return (<div onMouseOver={() => this.setState({ mouseover: true })} onMouseOut={() => {if (!this.props.value) this.setState({ mouseover: false });}} className={this.props.value ? "position-relative text-black" : "position-relative text-primary"}>
      <i className="bi bi-sticky fs-5" style={{visibility: this.props.value || this.state.mouseover ? 'visible' : 'hidden', cursor: 'pointer',}} onClick={() => this.setState({ expanded: true })} title="Edit Note"></i>
      <div className={this.isActive() ? "d-block position-absolute top-100 end-0 bg-white z-3" : "d-none"} style={{boxShadow: "var(--bs-box-shadow-sm)"}} onClick={() => this.setState({ expanded: true })} onMouseOut={() => this.setState({ mouseover: false })}>
          {
          this.isActive()
          ? <>
            <Form.Control
              as="textarea"
              rows={10}
              style={{display: 'block', width: "240px", height: "180px",}}
              value={this.state.noteText}
              autoFocus
              onBlur={() => this.setState({mouseover: false, expanded: false })}
              onChange={(e) => {this.setState({noteText: e.target.value}); this.props.onChange?.(e.target.value);}}
              onKeyUp={e => {if (e.ctrlKey && e.key === 'Enter') this.setState({mouseover: false, expanded: false});}} />
            <div className="position-absolute end-0 bottom-0 text-end text-secondary text-opacity-50">Ctrl-Enter to Close</div>
            </>
          : null
          }</div></div>)
    }
  }
