/* NODE PACKAGES */
import React from'react';
import {Spinner} from 'react-bootstrap';

const Print: React.FC<{children?: React.ReactNode}> = (props) =>
  {
  return <div className="d-none d-print-block m-0 p-0">{props.children}</div>
  }

export default Print;
