/* NODE PACKAGES */
import React from'react';
import {Container} from 'react-bootstrap';

interface FlexBoxProps
  {
  direction?: "column" | "row",
  className?: string,
  children?: React.ReactNode
  }

const cssFlexRow = "d-flex justify-content-between align-items-center gap-3 m-0 p-0";
const cssFlexColumn = "d-flex flex-column justify-content-start align-items-stretch gap-3 m-0 p-0";

const FlexBox: React.FC<FlexBoxProps> = (props) =>
  {
  const cssContainer = React.useMemo(() => props.direction === "column" ? cssFlexColumn : cssFlexRow, [props.direction]);

  return <div className={cssContainer}>{props.children}</div>
  }

export default FlexBox;
