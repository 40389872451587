/* NODE PACKAGES */
import React from 'react';

/* CUSTOM COMPONENTS */
import {APIDictionary, APITypedAttribute} from 'api/types';
// import {useAxios} from 'hooks';

/* ASSETS */
let dictionary:APIDictionary = require('api/dictionary.json');

export type DataDictionaryStore =
  {
  data: APIDictionary | null;
  };

interface DictionaryDataProps
  {
  authenticated: boolean;
  }

function useDictionaryStore(props:DictionaryDataProps): DataDictionaryStore
  {
  //const [response, error, loading, callAxios] = useAxios({ method: 'GET', url: '/api/data_dictionary', });
  const [data, setData] = React.useState<APIDictionary | null>(null);

  // React.useEffect(() => console.log(data), [data]);

  // only execute upon valid authentication
  React.useEffect(() =>
    {
    if (!props.authenticated) return;
    setData(dictionary);
    }, [props.authenticated]);

  // const refresh = React.useCallback(() => callAxios(), []);

  return {data} as DataDictionaryStore;
  }

export default useDictionaryStore;

/////////////////////////////////////////////////
// DICTIONARY CONSTANTS
/////////////////////////////////////////////////

// Request Templates
export enum TemplateAttributes
  {
  Rule_RequesterID	      =  1, // rule - remove from database migration
  Rule_SearchContact	    =  2, // rule - remove from database migration
  Rule_SearchEmail	      =  3, // rule - remove from database migration
  Rule_Processing	        =  4, // rule
  Rule_ExigentOK	        =  5, // rule
  Rule_DNS	              =  6, // rule
  Rule_RegOp	            =  7, // rule
  Rule_Forensic	          =  8, // rule
  Rule_Org	              =  9, // rule
  Rule_Name	              = 10, // rule
  Rule_Phone	            = 11, // rule
  Rule_Email	            = 12, // rule
  Rule_Location	          = 13, // rule
  Rule_UniqueID	          = 14, // rule
  Rule_Sensitivity	      = 15, // rule
  Rule_Logging	          = 16, // rule
  Metadata_OrgName	      = 17, // wrapper
  Metadata_OrgType	      = 18, // wrapper
  Metadata_PrimePOC	      = 19, // wrapper
  Metadata_PrimeEmail     = 20, // wrapper
  Metadata_AltPOC	        = 21, // wrapper
  Metadata_AltEmail	      = 22, // wrapper
  Metadata_Updated	      = 23, // wrapper
  Metadata_Version	      = 24, // wrapper
  Metadata_Completion	    = 25, // wrapper
  Metadata_Status	        = 26, // wrapper
  Metadata_Date	          = 27, // wrapper
  Metadata_Distribution   = 28, // wrapper
  Header_AccredAuth	      = 29, // header
  Header_Description	    = 30, // header
  UI_Date	                = 31, // fill
  UI_RequesterID	        = 32, // fill
  UI_Exigent	            = 33, // fill
  UI_Domain	              = 34, // fill
  UI_Contact	            = 35, // fill
  UI_Email	              = 36, // fill
  UI_ProcessingNotes      = 37, // fill
  UI_Organization         = 38, // fill    - add to database migration
  Expected_Fields         = 39, // wrapper - add to database migration
  Identity_Service        = 40, // wrapper - add to database migration
  Accredting_Enforcement  = 41, // wrapper - add to database migration
  Purposes_Protection     = 42, // wrapper - add to database migration
  Jurisdictions           = 43, // wrapper - add to database migration
  }
