/* NODE PACKAGES */
import React from 'react';
import {Form, FloatingLabel} from 'react-bootstrap';

/* CUSTOM COMPONENTS */
import {APITypedAttribute} from 'api/types';

const validateDomain = (domain: string): boolean =>
  {
  const regex = /^([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
  return regex.test(domain);
  };

interface InputProps
  {
  value: string | undefined;
  eventChange: (key: number, value: string) => void;
  definition: APITypedAttribute;
  disabled?: boolean;
  required?: boolean;
  }

function DomainBox (props: InputProps)
  {
  const cssValidationTooltip = "position-absolute top-0 start-50 translate-middle text-nowrap";
  return <FloatingLabel key={`email_${props.definition.attribute.id}`} controlId={`${props.definition.attribute.id}`} label={props.definition.attribute.name} className="d-block position-relative w-100">
    <Form.Control
      type="text"
      value={props.value ?? ""}
      placeholder={props.definition.attribute.name}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.eventChange(props.definition.attribute.id, e.target.value)}
      disabled={props.disabled}
      required={props.required ?? false}
      isValid={props.value ? validateDomain(props.value) : !props.required}
      isInvalid={props.value ? !validateDomain(props.value) : props.required}
      />
    <Form.Control.Feedback className={cssValidationTooltip} type='valid' tooltip>Excellent!</Form.Control.Feedback>
    <Form.Control.Feedback className={cssValidationTooltip} type='invalid' tooltip>Error: Invalid Domain</Form.Control.Feedback>
    </FloatingLabel>
  }

export default DomainBox;
