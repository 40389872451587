/* NODE PACKAGES */
import React from 'react';
import _, { set } from 'lodash';
import { AxiosRequestConfig } from 'axios';
/* API */
import { APIDictionary, APIRegistrantFacts, APIRegistrantFactItem, APIRegistration, APIPolicy, APIElement} from 'api/types';
import {getRuleForElement, getRegistrantFacts, saveRegistrantFacts} from 'api/utility';
/* HOOKS */
import {RegistrationStore, useAxios} from 'hooks';
/* CUSTOM COMPONENTS */
import { RULE_ATTRIBUTES } from 'components/organisms/PolicyRule';

// RegistrantFacts

export type RegistrantFactsStore =
  {
  data: APIRegistrantFacts | null;
  unsavedChanges: boolean;
  updateID(id: number): void;
  refreshData: () => void;
  updateData: (newValues: Partial<APIRegistrantFacts>) => void;
  clearData: () => void;
  eventSave: () => void;
  eventCopyDefaults: (policy: APIPolicy | null) => void;
  getRegistrantFact: (elementID: number) => APIRegistrantFactItem | undefined;
  updateFact: (el: APIElement, newValue: Partial<APIRegistrantFactItem>) => void;
  };

interface RegistrantFactsProps
  {
  registrantFactsID: number;
  dictionary: APIDictionary;
  }

function useRegistrantFactsStore(props: RegistrantFactsProps)
  {
  const [factID, setFactID] = React.useState<number>(props.registrantFactsID);
  const [APIdata, APIerror, APIloading, APIcall] = useAxios({ method: 'GET', url: `/api/registrant_facts/${factID}`});
  const [data, setData] = React.useState<APIRegistrantFacts | null>(null);
  const [unsavedChanges, setUnsavedChanges] = React.useState<boolean>(false);

  // React.useEffect(() => { console.log("RegistrantFactsStore[data]: ", data); }, [data]);

  React.useEffect(() =>
    {
    if (factID === -1) setData(null);
    else refreshData();
    }, [factID]);

  React.useEffect(() =>
    {
    if (!APIdata || APIerror || APIloading) return;
    setData(APIdata.registrant_facts);
    }, [APIdata]);

  function updateID (id:number)
    {
    setFactID(id);
    }

  function refreshData ()
    {
    APIcall();
    }

  // React.useEffect(() =>
  //   {
  //   if (!props.registrantFactsID || loading) return;
  //   fetchData(props.registrantFactsID);
  //   }, []);

  // const fetchData = React.useCallback((factID: number) =>
  //   {
  //   if (factID === -1)
  //     {
  //     setData(null);
  //     }
  //   else
  //     {
  //     setLoading(true);
  //     getRegistrantFacts(factID).then(setData).catch(setError).finally(() => setLoading(false));
  //     }
  //   }, []);

  const updateData = React.useCallback((newValues: Partial<APIRegistrantFacts>) =>
    {
    if (!data) return;
    const newRegistration = Object.assign({}, data, newValues);
    setData(newRegistration as APIRegistrantFacts);
    setUnsavedChanges(true);
    }, [data]);

  const clearData = React.useCallback(() =>
    {
    setData(null);
    setUnsavedChanges(true);
    }, []);

  const eventSave = React.useCallback(() =>
    {
    if (data) saveRegistrantFacts(data);
    setUnsavedChanges(false);
    }, [data]);

  const eventCopyDefaults = (policy: APIPolicy | null) =>
    {
    const RULE_NULL = 15;
    let newFacts = props.dictionary.element_groups.map(eg => (eg.elements.map(el =>
      {
      if (data && policy)
        {
        const rule = getRuleForElement(policy, el.id);
        if (rule)
          {
          let V3RQ = rule.attributes?.filter(a => a.attribute_id === RULE_ATTRIBUTES.V3RQ)[0]?.value || RULE_NULL;
          let DG = rule.attributes?.filter(a => a.attribute_id === RULE_ATTRIBUTES.DG)[0]?.value || RULE_NULL;
          let currentValue = data.facts?.find(rf => rf.element_id === el.id)?.value ?? el.name;

          if (V3RQ !== RULE_NULL || DG !== RULE_NULL)
            {
            const newFactObject: APIRegistrantFacts = _.clone(data);
            newFactObject.facts = newFactObject.facts?.slice() || [];
            const newFact: APIRegistrantFactItem = {element_id: el.id, value: currentValue, V3RQ: String(V3RQ), DG: String(DG),};
            return newFact;
            }
          }
        }
      }))).flat().filter(x => x !== undefined);

    if (data)
      {
      const newFactsObject: APIRegistrantFacts = _.clone(data);
      newFactsObject.facts = newFacts?.filter(x => x !== undefined) as APIRegistrantFactItem[];
      updateData(newFactsObject);
      }
    }

  const getRegistrantFact = (elementID: number) => data?.facts?.filter(rf => rf.element_id === elementID)[0];

  const updateFact = (el: APIElement, newValues: Partial<APIRegistrantFactItem>) =>
    {
    if (!data) return;
    const existingFactIndex:number         = data.facts?.findIndex((rf:APIRegistrantFactItem) => rf.element_id === el.id) ?? -1;
    const newFacts:APIRegistrantFactItem[] = data.facts?.slice();
    const newFact: APIRegistrantFactItem   = (existingFactIndex !== -1) ? Object.assign({}, data.facts[existingFactIndex], newValues) : Object.assign({}, { element_id: el.id, value: el.name ?? "", V3RQ: "15", DG: "15" }, newValues);

    if (existingFactIndex !== -1)
      {
      newFacts[existingFactIndex] = newFact;
      }
    else
      {
      newFacts.push(newFact);
      }

    updateData({facts: newFacts});
    }

  return {data, unsavedChanges, updateID, refreshData, updateData, clearData, eventSave, eventCopyDefaults, getRegistrantFact, updateFact} as RegistrantFactsStore;
  }

export default useRegistrantFactsStore;
