/* NODE PACKAGES */
import _ from 'lodash';
import React from 'react';
import {Spinner, Form, Table, InputGroup, DropdownButton, DropdownItem} from 'react-bootstrap';
/* TYPES */
import { NamedItem, APIDictionary, APIElement, APIRegistration, APIRule, PolicyListItem, APIRegistrantFactItem, APIRegistrantFacts, APIPolicy,} from 'api/types';
/* UTILITES */
import { getAttributeFromRule, getRuleForElement, getPolicy, savePolicy, getPolicyList, getRegistrantFactsList, getRegistration, saveNewRegistration, saveRegistration, deleteRegistration, getRegistrantFacts, saveRegistrantFacts} from 'api/utility';
import { redirect } from "common/window";
/* TEMPLATES */
import {Page, Header, Main, Section, FlexBox, Footer, Print} from "components/atoms/Templates";
/* CUSTOM COMPONENTS */
import {updatePolicyRuleAttributeValue } from 'pages/Policies/PolicyEditor';
import { PolicyRuleCell } from 'components/organisms/PolicyRuleCell';
import { RULE_ATTRIBUTES } from 'components/organisms/PolicyRule';
import DataBox from 'components/atoms/Inputs/DataBox';
import EditableLabel from "components/atoms/EditableText/EditableLabel";
import File from 'components/molecules/Menu/File';
import ColorKey from 'components/molecules/Sheet/ColorKey';

///////////////////////////////////////
// REGISTRATION EDITOR (ALPHA)
///////////////////////////////////////

interface RegistrationEditorProps
  {
  selectedRegistrationID: number | null;
  dataDictionary: APIDictionary;
  unsavedChanges: (flag: boolean) => void;
  }

export default function RegistrationEditor(props: RegistrationEditorProps)
  {
  const [ isLoadingRegistration, setIsLoadingRegistration ] = React.useState<boolean>(false);
  const [ isLoadingPolicyList, setIsLoadingPolicyList, ] = React.useState<boolean>(false);
  const [ isLoadingRegistrantFactsList, setIsLoadingRegistrantFactsList ] = React.useState<boolean>(false);
  const [ isLoadingPolicy, setIsLoadingPolicy ] = React.useState<boolean>(false);
  const [ isLoadingRegistrantFacts, setIsLoadingRegistrantFacts ] = React.useState<boolean>(false);
  const [ registration, setRegistration ] = React.useState<APIRegistration | null>(null);
  const [ registrantFactsList, setRegistrantFactsList ] = React.useState<NamedItem[] | null>(null);
  const [ policyList, setPolicyList ] = React.useState<PolicyListItem[] | null>(null);
  const [ registrantFacts, setRegistrantFacts ] = React.useState<APIRegistrantFacts | null>(null);
  const [ policy, setPolicy ] = React.useState<APIPolicy | null>(null);
  const [unsavedChanges, setUnsavedChanges] = React.useState<boolean>(false);

  // set global flag to trigger "unsaved changes" prompt on hash changes
  React.useEffect(() =>
    {
    props.unsavedChanges(unsavedChanges);
    }, [unsavedChanges]);

  React.useEffect(() =>
    {
    if (registration)
      {
      if (!policy && registration.policy_id !== -1 && !isLoadingPolicy)
        {
        setIsLoadingPolicy(true);
        getPolicy(registration.policy_id).then(setPolicy).finally(() => setIsLoadingPolicy(false));
        }

      if (!registrantFacts && registration.registrant_facts_id !== -1 && !isLoadingRegistrantFacts)
        {
        setIsLoadingRegistrantFacts(true);
        getRegistrantFacts(registration.registrant_facts_id).then(setRegistrantFacts).finally(() => setIsLoadingRegistrantFacts(false));
        }

      if (!registrantFactsList && !isLoadingRegistrantFactsList)
        {
        setIsLoadingRegistrantFactsList(true);
        getRegistrantFactsList().then(setRegistrantFactsList).finally(() => setIsLoadingRegistrantFactsList(false));
        }

      if (!policyList && !isLoadingPolicyList)
        {
        setIsLoadingPolicyList(true);
        getPolicyList()
          .then(policies => {if (policies !== null) setPolicyList(policies.filter(policy => policy.org_type === "registrar"))})
          .finally(() => setIsLoadingPolicyList(false));
        }

      if (registration.policy_id === -1 && policyList)
        { // default to first item in list
        let newReg = {...registration};
        newReg.policy_id = policyList.at(0)?.id ?? -1;
        if (newReg !== registration)
          {
          setRegistration(newReg);
          setUnsavedChanges(true);
          }
        }

      if (registration.registrant_facts_id === -1 && registrantFactsList)
        { // default to first item in list
        let newReg = {...registration};
        newReg.registrant_facts_id = registrantFactsList.at(0)?.id ?? -1;
        if (newReg !== registration)
          {
          setRegistration(newReg);
          setUnsavedChanges(true);
          }
        }
      }
    }, [registration]);

  //const REG_ATTRIBUTES = {V3RQ: 14, DG: 15};
  const eventUpdateFactValue = React.useCallback((newValue: string, registrantFacts: APIRegistrantFacts, el: APIElement) =>
    {
    // empty string removes the value
    if (newValue.trim() === '')
      {
      return setRegistrantFacts(Object.assign({}, registrantFacts, { facts: registrantFacts.facts.filter(rf => rf.element_id !== el.id)}));
      }
    else
      {
      const existingFactIndex = registrantFacts.facts.findIndex(rf => rf.element_id === el.id);
      const newFactObject: APIRegistrantFacts = _.clone(registrantFacts);
      newFactObject.facts = newFactObject.facts.slice();
      // if modifying an existing fact, update it
      if (existingFactIndex !== -1)
        {
        const newFact = Object.assign({}, registrantFacts.facts[existingFactIndex], { value: newValue });
        newFactObject.facts[existingFactIndex] = newFact;
        }
      else
        { // otherwise, add it to the set of facts
        const newFact: APIRegistrantFactItem =
          {
          element_id: el.id,
          value: newValue,
          V3RQ: "default",
          DG: "no dg"
          };
        newFactObject.facts.push(newFact);
        }
      setRegistrantFacts(newFactObject);
      setUnsavedChanges(true);
      }
    }, [setRegistrantFacts, setUnsavedChanges]);

  const eventUpdateFactValueV3RQ = React.useCallback((V3RQ_value: string, registrantFacts: APIRegistrantFacts, el: APIElement) =>
    {
    const existingFactIndex = registrantFacts.facts.findIndex(rf => rf.element_id === el.id);
    const newFactObject: APIRegistrantFacts = _.clone(registrantFacts);
    newFactObject.facts = newFactObject.facts.slice();
    if (existingFactIndex !== -1)
      {
      // if modifying an existing fact, update it
      const newFact = Object.assign({}, registrantFacts.facts[existingFactIndex], { V3RQ: V3RQ_value });
      newFactObject.facts[existingFactIndex] = newFact;
      }
    setRegistrantFacts(newFactObject);
    setUnsavedChanges(true);
    }, [setRegistrantFacts, setUnsavedChanges]);

  const eventUpdateFactValueDG = React.useCallback((DG_value: string, registrantFacts: APIRegistrantFacts, el: APIElement) =>
    {
    const existingFactIndex = registrantFacts.facts.findIndex(rf => rf.element_id === el.id);
    const newFactObject: APIRegistrantFacts = _.clone(registrantFacts);
    newFactObject.facts = newFactObject.facts.slice();
    // if modifying an existing fact, update it
    if (existingFactIndex !== -1)
      {
      const newFact = Object.assign({}, registrantFacts.facts[existingFactIndex], { DG: DG_value });
      newFactObject.facts[existingFactIndex] = newFact;
      }
    setRegistrantFacts(newFactObject);
    setUnsavedChanges(true);
    }, [setRegistrantFacts, setUnsavedChanges]);

  if (isLoadingRegistration)
    {
    return (<Spinner animation="border" variant="primary" className="position-absolute top-50 start-50 translate-middle" />);
    }

  if (!registration || (registration.id !== props.selectedRegistrationID && registration.id !== 0 && props.selectedRegistrationID !== null))
    {
    if (props.selectedRegistrationID)
      {
      console.log("Initiating load of registration information");
      // TODO: handle error case (shouldn't endlessly retry)
      setIsLoadingRegistration(true);
      getRegistration(props.selectedRegistrationID).then(setRegistration).finally(() => setIsLoadingRegistration(false));
      }
    if (registration)
      {
      saveNewRegistration(registration);
      }
    return null;
    }

  const eventDelete = () =>
    {
    if (!registration) return;
    if (window.confirm('Are you sure you wish to delete this item?'))
    deleteRegistration(registration.id).then(() => redirect('/registrations/'));
    };

  return (<Page>
    <Header>
      <EditableLabel value={registration?.name ?? "Untitled Registration"} onValueChange={(newValue) => setRegistration({...registration, name: newValue})} />
      <div className="ms-auto"></div>
      <a className="link link-secondary fs-6 lh-1" href={`/#/registration/${registration?.id}`}>{"(Editor v2.0)"}</a>
      <ColorKey />
      <File eventSave={() => {saveRegistration(registration); if (registrantFacts) saveRegistrantFacts(registrantFacts); setUnsavedChanges(false);}} eventDelete={eventDelete} dirtyFlag={unsavedChanges} />
      </Header>
    <Main>
      <Section>
        <FlexBox>
          <InputGroup>
            <Form.Control type="text" value={registration?.name || ""} onChange={(e) => {setRegistration({...registration, name: e.target.value}); setUnsavedChanges(true);}} />
            </InputGroup>
          <InputGroup>
            <DropdownButton variant="dark" title="Policy" id="policy-dropdown">
              <DropdownItem onClick={() => {if (policy) savePolicy(policy);}}> Save Policy </DropdownItem>
              <DropdownItem href={`/#/policy/${policy?.id}`}>Go to Policy</DropdownItem>
              </DropdownButton>
            <Form.Select value={registration.policy_id.toString()} onChange={e => {getPolicy(parseInt(e.target.value)).then(setPolicy); var newRegistration = _.clone(registration); newRegistration.policy_id = parseInt(e.target.value); setRegistration(newRegistration);  setUnsavedChanges(true);}}>{policyList?.map((policy, i) => <option key={`policy-${policy.org_type}-${i}`} value={policy.id}>{policy.name}</option>)}</Form.Select>
            </InputGroup>
            </FlexBox>
        </Section>
      <Section className="d-print-none d-block mx-auto my-0 px-0 py-4">
        {registrationEditorTable()}
        </Section>
      </Main>
    <Footer />
    </Page>);

  function registrationEditorTable(): JSX.Element
    {
    return (<Table responsive size='sm' className="font-monospace m-0 p-0">
      <tbody>
        {
        props.dataDictionary.element_groups.map(eg => (eg.elements.map((el, i) =>
          {
          const regFact = registrantFacts?.facts.find(rf => rf.element_id === el.id);
          var coll: number = 0;
          var validation: string = "";
          var sensitivity: string = "";
          var V3RQ: number = 0;
          var DG: number = 0;
          if (policy)
            {
            const rule = getRuleForElement(policy, el.id);
            if (rule)
              {
              coll = getAttribute(RULE_ATTRIBUTES.COLL, rule, props.dataDictionary)?.value || 0;
              validation = getAttribute(RULE_ATTRIBUTES.VAL, rule, props.dataDictionary)?.name || "";
              sensitivity = getAttribute(RULE_ATTRIBUTES.SENS, rule, props.dataDictionary)?.name || "";
              V3RQ = getAttribute(RULE_ATTRIBUTES.V3RQ, rule, props.dataDictionary)?.value || 0;
              DG = getAttribute(RULE_ATTRIBUTES.DG, rule, props.dataDictionary)?.value || 0;
              }
            }

          let groupCell: React.ReactNode = (i !== 0) ? null :
            // ? <tr><td key={`eg-cell-${i}`} colSpan={15} align="left" valign="middle"></td></tr>
            <React.Fragment key={`eg-cell-${i}`}>
              <tr className="m-0 px-2 py-0 border-2 border-start-0 border-top-0 border-end-0 border-bottom border-dark">
                <th colSpan={3}>Registrant Facts: {eg.name} </th>
                <th colSpan={props.dataDictionary.rule_attributes.length}>Policy</th>
                <th colSpan={3}>Registration</th>
                </tr>
              <tr>
                <th className="text-secondary">Value</th>
                <th className="text-secondary">Vrq</th>
                <th className="text-secondary">Srq</th>
                {props.dataDictionary.rule_attributes.map(att => <th key={`rule-attributes-${att.id}`} className="text-secondary">{att.name}</th>)}
                <th className="text-secondary">Value</th>
                <th className="text-secondary">Validation</th>
                <th className="text-secondary">Sensitivity</th>
                </tr>
              </React.Fragment>;

          let rule = policy ? getRuleForElement(policy, el.id) ?? {id: 0, element_id: el.id, attributes: []} : { id: 0, element_id: el.id, attributes: [] };
          const PolicyRuleColumns = props.dataDictionary.rule_attributes.map(att => <td key={`policy-rule-cell-${att.id}`}><PolicyRuleCell rule={rule} attribute={att} onChange={(newVal) => {if (policy) {setPolicy(updatePolicyRuleAttributeValue(policy, rule.element_id, att.id, newVal));}}} /></td>);
          let regValidationToDisplay = rule.attributes.find(att => att.attribute_id === RULE_ATTRIBUTES.VAL)?.value ?? 0;
          if (regFact?.V3RQ === "V3 please" && (V3RQ === 2 || V3RQ === 3)) regValidationToDisplay = 8;
          // if (coll === 1 && (getAttribute(RULE_ATTRIBUTES.VAL, rule, props.dataDictionary)?.value??0) === 0) regValidationToDisplay = -1; //collect is required but no value provided
          let regSensToDisplay = rule.attributes.find(att => att.attribute_id === RULE_ATTRIBUTES.SENS)?.value??0;
          if (regFact?.DG === "DG please" && (DG === 3 || DG === 2)) regSensToDisplay = 1;
          // if (coll === 1 && (getAttribute(RULE_ATTRIBUTES.SENS, rule, props.dataDictionary)?.value??0) === 0) regSensToDisplay = -1; //collect is required but no value provided

          return (registrantFacts)

            ? (<React.Fragment key={`reg-fact-${el.id}`}>

                {groupCell}

                <tr>
                  <td valign="middle"><div hidden>{el.id}</div>
                    <Form.Group controlId={`factValue${el.id}`}>
                      <Form.Control className="w-100" type="text" value={regFact?.value || ""} placeholder={el.name} onChange={(e: React.ChangeEvent<HTMLInputElement>) => eventUpdateFactValue(e.target.value, registrantFacts, el)} />
                      </Form.Group>
                    </td>
                  <td valign="middle"><div hidden>{el.id}</div>
                    <Form.Select value={registrantFacts.facts[registrantFacts.facts.findIndex(rf => rf.element_id === el.id)]?.V3RQ || "default"} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => eventUpdateFactValueV3RQ(e.target.value, registrantFacts, el)}>
                      <option value="default">Default</option>
                      <option value="V3 please">V3 Please</option>
                      <option value="ø">ø</option>
                      </Form.Select>
                    </td>
                  <td valign="middle"><div hidden>{el.id}</div>
                    <Form.Select defaultValue={registrantFacts.facts[registrantFacts.facts.findIndex(rf => rf.element_id === el.id)]?.DG || "NODG"} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => eventUpdateFactValueDG(e.target.value, registrantFacts, el)}>
                      <option value="no DG">No DG</option>
                      <option value="DG please">DG Please</option>
                      <option value="ø">ø</option>
                      </Form.Select>
                    </td>
                  {PolicyRuleColumns}
                  <td valign="middle">{filterFact(regFact, coll, props.dataDictionary)}</td>
                  <td valign="middle"><DataBox ruleValue={regValidationToDisplay} onChange={(newVal) => {}} attribute={props.dataDictionary.rule_attributes.find(att => att.id === RULE_ATTRIBUTES.VAL) ?? {id: 0, name: "", values: []}} /></td>
                  <td valign="middle"><DataBox ruleValue={regSensToDisplay} onChange={(newVal) => {}} attribute={props.dataDictionary.rule_attributes.find(att => att.id === RULE_ATTRIBUTES.SENS) ?? {id: 0, name: "", values: []}} /></td>
                  </tr>
                </React.Fragment>)

            : (<tr key={`reg-fact-${el.id}`}><td colSpan={50} valign="middle">No facts</td></tr>);
          })))
        }
      </tbody>
    </Table>);
    }
  }

/**
 * Gets the attribute value object for the given attribute ID from the provided rule and data dictionary.
 * @param id - The ID of the attribute to get.
 * @param rule - The rule to get the attribute value from.
 * @param dataDictionary - The data dictionary to use to resolve the attribute's value object.
 * @returns The resolved attribute value object, or null if not found.
 */
function getAttribute(id: number, rule: APIRule, dataDictionary: APIDictionary)
  {
  const attribute_validation = getAttributeFromRule(rule, id);
  if (attribute_validation)
    {
    const attribute_value = dataDictionary.rule_attributes.filter(a => a.id === attribute_validation.attribute_id).at(0)?.values.at(attribute_validation.value);
    return attribute_value ? attribute_value : null;
    }

  return null;
  }

// 0: {value: 0, color: 'ff0000', name: 'ø'}
// 1: {value: 1, color: 'ffff00', name: 'Collect'}
// 2: {value: 2, color: '3165ff', name: "Don't Collect"}
// 3: {value: 3, color: 'ffc000', name: "Coll or Don't"}
// 4: {value: 4, color: 'b8ff82', name: 'Optional'}
// 5: {value: 5, color: '00efd2', name: 'Coll or Opt'}
// 6: {value: 6, color: 'ff5cff', name: "Opt or Don't"}
// 7: {value: 7, color: 'ffffff', name: 'Any'}
function filterFact(fact: APIRegistrantFactItem | undefined, attributeVal: number, dataDictionary: APIDictionary)
  {
  if (!fact) return (attributeVal === 1) ? (<div className="d-flex flex-row justify-content-start align-items-center gap-2 m-0 p-0 fs-6 lh-1 fst-italic"><span className="d-inline-block bg-warning text-dark m-0 p-1 border-0 rounded-2 fs-4 lh-1"><i className="bi bi-exclamation-diamond"></i></span><span>Missing</span></div>) : ""; // the fact is missing and the attribute is set to collect

  switch (attributeVal)
    {
    case 0: return "ø";
    case 1: return fact.value;
    case 2: return "";
    case 4: return fact.value;
    //TODO build other cases
    default: return "";
    }
  }

/*

*/
