/* Node Packages */
import React from 'react';
import axios, {AxiosRequestConfig, AxiosResponse, AxiosError} from 'axios';

//////////////////////////////////////////
// LOCAL STORAGE OBJECT
//////////////////////////////////////////

export const AuthToken = Object.freeze(
  {
  client: axios.create({headers: { 'Content-Type': 'application/json' }, }),
  storageKey: 'jakeSharedPassword',
  // headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${AuthToken.use()}`},
  store: (value: string) =>
    {
    window.localStorage.setItem(AuthToken.storageKey, value);
    AuthToken.client.defaults.headers.common['Authorization'] = 'Bearer ' + value;
    },
  use: () => window.localStorage.getItem(AuthToken.storageKey) ?? null,
  clear: () =>
    {
    window.localStorage.removeItem(AuthToken.storageKey);
    delete AuthToken.client.defaults.headers.common['Authorization'];
    },
  });


//////////////////////////////////////////
// RE-USABLE METHOD: callAPI
//////////////////////////////////////////

export const callAPI = (config:AxiosRequestConfig) =>
  {
  const eventResponse = (response: AxiosResponse<any, any>) => response.data;
  const eventError    = (error:AxiosError<any, any>)        => {if (error.status === 401) AuthToken.clear(); console.error(error.toJSON()); return;}
  const eventTrace    = ()                                  => console.log(`${config.method} ${config.url}`);

  return AuthToken.client.request(config).then(eventResponse).catch(eventError).finally(eventTrace);
  }


//////////////////////////////////////////
// RE-USABLE CUSTOM HOOK: useAxios
//////////////////////////////////////////

const useAxios = (config:AxiosRequestConfig) =>
  {
  const [response, setResponse] = React.useState<any>(null);
  const [error, setError] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() =>
    {
    callAxios();
    }, []);

  function callAxios ()
    {
    AuthToken.client
      .request<any>(config)
      .then((response: AxiosResponse<any, any>) => setResponse(response.data))
      .catch((error:AxiosError<any, any>) => {if (error.status === 401) AuthToken.clear(); setError(true); console.error(error.message);})
      .finally(() => {setLoading(false); console.log(`${config.method} ${config.url}`);});
    }

  return [response, error, loading, callAxios] as const;
  };

export default useAxios;


