/* NODE PACKAGES */
import React from "react";
import {Table} from 'react-bootstrap';

export interface TableRowData
  {
  key: number;
  description: string;
  objectData: string;
  templates: string;
  }

// React functional component for displaying Registrar Group Table
const GroupTable: React.FC<{currentObjID: number; currentObjType: "RqG" | "Rr"; tableData: TableRowData[];}> = (props) =>
  {
  return (!props.tableData ? null : <Table responsive size="sm" className="m-0 p-0">
    <thead>
      <tr>
        <th className="col-4">Description</th>
        <th className="col-4">{props.currentObjType === "Rr" ? "Requester Group(s)" : "Registrar(s)"}</th>
        <th className="col-4">Templates</th>
        </tr>
      </thead>
    <tbody>
      {props.tableData.map((row) =>
        <tr key={`dataTableKey_${row.key}`}>
          <td className="col-4">{row.description}</td>
          <td className="col-4">{row.objectData}</td>
          <td className="col-4">{row.templates}</td>
          </tr>)}
      </tbody>
    </Table>);
  };

export default GroupTable;
