/* NODE PACKAGES */
import React from 'react';
import {ButtonGroup, Button, Dropdown, DropdownButton, OverlayTrigger, Tooltip, Popover, Stack} from 'react-bootstrap';

/* CUSTOM COMPONENTS */
import { APIAttribute, APIRuleAttribute, APIRegistryList, APIComparison} from "api/types";
import {useRegistryListStore} from "hooks";
import DataBox from 'components/atoms/Inputs/DataBox';
import styles from 'components/atoms/Inputs/DataBox.module.css';

///////////////////////////////////////
// SCOPE TOOLBAR
///////////////////////////////////////

interface ScopeProps
  {
  scopeAttributes: APIAttribute[]; // Available scope attributes to choose from
  selectedScopeAttributes: APIRuleAttribute[]; // Array of currently selected scope attributes
  comparisonScopeAttributes?: APIRuleAttribute[]; // Array of currently selected scope attributes
  selectedTLD: APIRegistryList | null; // Selected TLD list, or null if none selected
  comparisonTLD?: APIRegistryList | null; // Selected TLD list, or null if none selected
  defaultDropdown: number; // Default value for dropdown selects
  onAttributeChange: (newScopeAttr: APIRuleAttribute[]) => void; // Callback when scope attributes change
  onRegistryListChange: (registry: APIRegistryList | null) => void; // Callback when TLD list changes
  }

function Scope (props: ScopeProps)
  {
  const registryList = useRegistryListStore();
  const attribute = { id: 0, name: "Registry List", values: registryList.data?.map(item => ({ value: item.id, name: item.name, className: "dictionary" })) ?? [] };

  const eventChangeAttribute = (attID: number, newVal: number) =>
    {
    const newScopeAtts = [...props.selectedScopeAttributes];
    const existingAttIndex = newScopeAtts.findIndex(att => att.attribute_id === attID);
    if (existingAttIndex !== -1) newScopeAtts[existingAttIndex] = {...newScopeAtts[existingAttIndex], value: newVal };
    else newScopeAtts.push({ attribute_id: attID, value: newVal });
    props.onAttributeChange(newScopeAtts);
    };

  const attributes = props.scopeAttributes.map((att) =>
    {
    const value           = props.selectedScopeAttributes?.find(sa => sa.attribute_id === att.id)?.value;
    const comparisonValue = props.comparisonScopeAttributes?.find(c => c.attribute_id === att.id)?.value ?? undefined;
    const attributeName   = att.name ?? att.id;
    return <div key={`att-${att.id}`}><div className="text-muted"><small>{attributeName}</small></div><DataBox ruleValue={value} comparisonValue={comparisonValue} attribute={att} onChange={(newValue: number) => eventChangeAttribute(att.id, newValue)} /></div>
    });

  return (<Stack direction="horizontal" gap={1} className="d-flex flex-row justify-content-center align-items-start mx-auto my-0 p-0" aria-description='Scope attributes'>
    <div><div className="text-muted"><small>{"Registry List"}</small></div><DataBox ruleValue={props.selectedTLD?.id} attribute={attribute} onChange={(newValue: number) => props.onRegistryListChange(registryList.data?.find(item => item.id === newValue) ?? null)} /></div>
    {attributes}
    </Stack>);
  }

export default Scope;

///////////////////////////////////////
// TLD SELECTOR
///////////////////////////////////////

interface TLDItemProps
  {
  registryList: APIRegistryList | null; // Selected TLD list
  onTLDListChange: (tldList: APIRegistryList | null) => void; // Callback when TLD list changes
  isButtonDisplayed?: boolean; // Whether to display the "Choose..." button
  }

// Renders a selector for choosing a TLD list, with modal popup.
export function TLDItem (props: TLDItemProps)
  {
  const tldList = useRegistryListStore();
  const attribute = { id: 0, name: "Registry List", values: tldList.data?.map(item => ({ value: item.id, name: item.name, className: "dictionary" })) ?? [] };

  return <div><div className="text-muted"><small>{"Registry List"}</small></div><DataBox ruleValue={props.registryList?.id} attribute={attribute} onChange={(newValue: number) => props.onTLDListChange(tldList.data?.find(item => item.id === newValue) ?? null)} /></div>
  }

// ///////////////////////////////////////
// // ELIDED LIST
// ///////////////////////////////////////

// interface ElidedListProps
//   {
//   list: APITLDList;
//   selectTLDList: (newTLDList: APITLDList) => void;
//   active: boolean;
//   }

// export function ElidedList(props: ElidedListProps)
//   {
//   return (<Dropdown.Item active={props.active} eventKey={props.list.id} onClick={() => props.selectTLDList(props.list)} className={`${styles.data_option}`}><span><small>{props.list.name} {`(${props.list.tlds.length})`}</small></span></Dropdown.Item>);
//   }

// Popover disabled
// const popover = (<Popover className={props.list.tlds.length ? "d-block bg-white text-dark font-monospace lh-base border-0" : "d-none"} style={{maxWidth: '500px', overflowY: 'scroll'}}>
//   <Popover.Header as="h6" className="border-0">TLD List</Popover.Header>
//   <Popover.Body>{props.list.tlds.join(', ')}</Popover.Body>
//   </Popover>);
// return (<OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={popover}>
//   <Dropdown.Item active={props.active} eventKey={props.list.id} onClick={() => props.selectTLDList(props.list)} className="d-flex flex-row justify-content-between align-items-center m-0 px-2 py-2 font-monospace lh-base">
//     <span>{props.list.name}</span>
//     <span className={props.list.tlds.length > 0 ? "d-block fst-italic border-bottom lh-base" : "d-none"}><small>{`(${props.list.tlds.length})`}</small></span>
//     </Dropdown.Item>
//   </OverlayTrigger>);
