/*
WINDOW LOCATION AND HASH FUCNTIONS
*/
// given a url, return the hash part of it not including the "#" character, or "" if there is no hash


// given a url, return the hash part of it not including the "#" character, or "" if there is no hash
export function getURLHash(url: string): string
  {
  if (url.indexOf('#'))
    {
    return url.substring(url.indexOf('#') + 1);
    }
  else
    {
    return "";
    }
  }

export function redirect(hash: string)
  {
  window.location.hash = hash;
  }

export function checkUnsavedBeforeUnload(): (unsavedChanges: () => boolean) => void
  {
  return (unsavedChanges: () => boolean) =>
    {
    window.onbeforeunload = (e) =>
      {
      if (unsavedChanges()) e.preventDefault();
      return (unsavedChanges() ? "There are unsaved changes. Are you sure you want to leave?" : undefined);
      };
    };
  }

export function restoreOldHashChange(oldOnhashchange: (((this: WindowEventHandlers, ev: HashChangeEvent) => any) & ((this: Window, ev: HashChangeEvent) => any)) | null | undefined)
  {
  // @ts-ignore
  window.onbeforeunload = null;
  window.onhashchange = oldOnhashchange ?? null;
  }

