/* NODE PACKAGES */
import _, { over, parseInt } from 'lodash';
import React from 'react';
import { Spinner, Table, Form, OverlayTrigger, Tooltip} from 'react-bootstrap';
/* CUSTOM COMPONENTS */
import { APIDictionary, APIAttribute, APIAttributeOption, APIRegistration, APIRuleAttribute, APIRegistrantFactItem, APIRegistrantFacts, APIRule, APIPolicy, APIRegistryList , APIElementGroup, APIElement} from 'api/types';
import { getAttributeFromRule, getRuleForElement } from 'api/utility';
import { updatePolicyRuleAttributeValue } from 'pages/Policies/PolicyEditor';
import { PolicyRuleCell } from 'components/organisms/PolicyRuleCell';
import { RULE_ATTRIBUTES, Collection} from 'components/organisms/PolicyRule';
import { TLDItem } from 'components/molecules/Toolbars/Scope';
import DataBox from 'components/atoms/Inputs/DataBox';
//import {RegistrationEditorData} from 'hooks/useRegistrationData';
import {PolicyStore, RegistrationStore, RegistrantFactsStore, RegistryListStore} from 'hooks';

///////////////////////////////////////
// CONSTANTS
///////////////////////////////////////

const REG_ATTRIBUTES = {V3RQ: 14, DG: 15 };
const ANY = "15";
const GROUP_REGSCOPE = 2;
const ELEMENT_REGISTRY = 4;

///////////////////////////////////////
// HELPER: SINGLETON TEST
///////////////////////////////////////

function isSingleton (value: number)
  {
  return (value & value - 1) === 0;
  }

///////////////////////////////////////
// HELPER: RANGE TEST (partial solution)
///////////////////////////////////////

const ranges : {[key: number]: number[]; } = { 3: [1,2,3], 6: [2,3,4,6], 7: [1,2,3,4,6,7], 12: [4,6,7,8], 14: [2,3,4,8,12,14], 15: [1,2,3,4,6,7,8,12,14,15] };

function isWithinRange (value: number, range: [number, number])
  {
  return (value >= range[0] && value <= range[1]);
  }

///////////////////////////////////////
// REGISTRATION TABLE DATA
///////////////////////////////////////

interface RegistrationEditorTableProps
  {
  dictionary: APIDictionary;
  registrantFacts: RegistrantFactsStore;
  registration: RegistrationStore;
  policy: PolicyStore;
  registryList: RegistryListStore;
  }

/*
///////////////////////////////////////
// SUMMARY
///////////////////////////////////////

The RegistrationEditorTable.tsx component and its sub-components create a complex table for editing and displaying registration data. Here's a summary of what it does:

  * It renders a table with rows for each element in the data dictionary, grouped by element groups.
  * The table displays information about registrant facts, policy rules, and registration details for each element.
  * It allows editing of registrant facts, validation requests (VRQ), and sensitivity requests (SRQ).
  * It shows policy rules for each element, including collection status, validation, and sensitivity.
  * The component calculates and displays registration values, validation, and sensitivity based on the policy rules and registrant facts.

The main calculations in simple terms:

  * Registration Value: It filters the registrant fact based on the collection status from the policy rule.
  * Registration Validation: It combines the validation rule from the policy with the VRQ from the registrant fact, using bitwise OR operation. It displays the stricter of the two values.
  * Registration Sensitivity: Similar to validation, it combines the sensitivity rule from the policy with the SRQ from the registrant fact, displaying the stricter value.

These calculations ensure that the displayed registration data adheres to both the policy rules and the registrant's requests, always showing the more restrictive option when there's a difference between policy and registrant preferences.
*/

function RegistrationData (props: RegistrationEditorTableProps)
  {
  const empty_attribute         : APIAttribute             = { id: 0, name: "", values: [] };
  const regValidationDefinition : APIAttribute             = props.dictionary.rule_attributes.find(att => att.id === RULE_ATTRIBUTES.VAL ) ?? empty_attribute;
  const regSensitivtyDefinition : APIAttribute             = props.dictionary.rule_attributes.find(att => att.id === RULE_ATTRIBUTES.SENS) ?? empty_attribute;
  const V3RQ_attribute          : APIAttribute | undefined = props.dictionary.registration_attributes.find((att: {id: number}) => att.id === REG_ATTRIBUTES.V3RQ);
  const SRQ_attribute           : APIAttribute | undefined = props.dictionary.registration_attributes.find((att: { id: number; }) => att.id === REG_ATTRIBUTES.DG);

  function getAttribute (id: number, rule: APIRule)
    {
    const attribute: APIRuleAttribute | undefined = getAttributeFromRule(rule, id);
    return attribute ? props.dictionary.rule_attributes?.filter(a => a.id === attribute.attribute_id).at(0)?.values.at(attribute.value) ?? null : null;
    };

  const data_extraction: JSX.Element[][] = React.useMemo(() => props.dictionary.element_groups.map((group: APIElementGroup) => group.elements.map((element: APIElement, index: number) =>
    {
    const fact    : APIRegistrantFactItem = props.registrantFacts.getRegistrantFact(element.id) ?? { element_id: element.id, value: element.name, V3RQ: ANY, DG: ANY };
    const rule    : APIRule               = props.policy.getRule(element.id) ?? { id: 0, element_id: element.id, attributes: [] };
    const collect : number                = (rule) ? getAttribute(RULE_ATTRIBUTES.COLL, rule)?.value ?? 0 : 0;
    const key     : string                = `rowData${group.id}${element.id}`;
    const scope   : JSX.Element           = (<RegistrationScopeAttribute elementID={element.id} dictionary={props.dictionary} registration={props.registration} registryList={props.registryList}  />);

    return <React.Fragment key={`${key}`}>
      <DataTableHeader index={index} groupName={group.name} rule_attributes={props.dictionary.rule_attributes} />
      <tbody>
        <tr>
          <RegistrantFactCell groupID={group.id} element={element} regFact={fact} scope={scope} registrantFacts={props.registrantFacts} />
          <VrqCell element={element} attribute={V3RQ_attribute} regFact={fact} definition={props.dictionary.registration_attributes} registrantFacts={props.registrantFacts} />
          <SrqCell element={element} attribute={SRQ_attribute} regFact={fact} definition={props.dictionary.registration_attributes} registrantFacts={props.registrantFacts} />
          <td className="border"></td>
          <PolicyRules rule={rule} definitions={props.dictionary.rule_attributes} policy={props.policy} />
          <td className="border"></td>
          <RegistrationValue groupID={group.id} scope={scope} regFact={fact} collect={collect} />
          <RegistrationValidation collect={collect} rule={rule} regFact={fact} definition={regValidationDefinition} />
          <RegistrationSensitivity collect={collect} rule={rule} regFact={fact} definition={regSensitivtyDefinition} />
          </tr>
        </tbody>
      </React.Fragment>
    })), [props.dictionary.element_groups, props.dictionary.rule_attributes, props.registrantFacts.data, props.policy.data, props.registration.data]);

  // Renders the data table generated by mapping over the `data_extraction` array, which contains objects representing the data for each row in the table. For each row, it renders:
  return (!props.registration.data)
    ? <Spinner animation="border" variant="primary" className="position-absolute top-50 start-50 translate-middle" />
    : <Table responsive size="sm">
        {data_extraction}
        </Table>
  }

export default RegistrationData;

///////////////////////////////////////
// COLUMN: REGISTRATION SCOPE ATTRIBUTE
///////////////////////////////////////
// Retrieves the registration scope attribute for the given element ID. If the element ID is 4, it returns the name of the TLD from the registration data, or "<none>" if not found. For other element IDs, it looks up the scope attribute ID in a join table, finds the corresponding scope attribute value in the registration data, and returns the name of that value, or "ø" if not found.
const RegistrationScopeAttribute = React.memo((props: {elementID: number, dictionary: APIDictionary, registration: RegistrationStore, registryList: RegistryListStore}) =>
  {
  const findPSLValue = () =>
    {
    const onRegistryListChange = (newTLDList: APIRegistryList | null) => props.registration.updateData({ tlds: newTLDList, tld_list_id: newTLDList?.id ?? null });
    const attribute: APIAttribute | undefined = { id: 0, name: "Registry List", values: props.registryList.data?.map(item => ({ value: item.id, name: item.name, className: "dictionary" })) ?? [] };
    return <DataBox ruleValue={props.registration.data?.tld_list_id ?? undefined} attribute={attribute} onChange={(newValue: number) => onRegistryListChange(props.registryList.data?.find(item => item.id === newValue) ?? null)} />
    }

  const findScopeValue = () =>
    {
    const UNKNOWN = 1;
    const joinTable : { [key: number]: number; } = { 84: 10, 5: 11, 6: 12, 85: 13 };
    const scopeAttribute : APIAttribute | undefined = props.dictionary.scope_attributes?.find(sa => sa.id === joinTable[props.elementID]);

    if (scopeAttribute)
      {
      const existingValue : number                           = props.registration.data?.scope_attributes?.find((sa:APIRuleAttribute) => sa.attribute_id === joinTable[props.elementID])?.value ?? UNKNOWN;
      const altDropdown   : APIAttributeOption[] | undefined = scopeAttribute.values?.filter((val) => props.registration.singletonScopes?.includes(val.value)) ?? undefined;
      const eventOnChange : (newVal: number) => void         = (newVal:number) => props.registration.updateScopeAttribute(scopeAttribute.id, newVal);

      return <DataBox attribute={scopeAttribute} ruleValue={existingValue} alternateDropdown={altDropdown} onChange={eventOnChange} />
      }
    else
      {
      return null;
      }
    }

  return (props.elementID === ELEMENT_REGISTRY) ? findPSLValue() : findScopeValue();
  });

//////////////////////////////////////////////////////
// REGISTRATION TABLE HEADERS (repeats for each group)
//////////////////////////////////////////////////////

function DataTableHeader (props: {index:number, groupName:string, rule_attributes: APIAttribute[]})
  {
  const rule_attributes = props.rule_attributes.map(att => <th key={`attrName-${att.id}`} className="bg-dark bg-opacity-10">{att.name}</th>);
  const policyColSpan = props.rule_attributes.length;
  const groupName = props.groupName;

  const cssRowStyle = "m-0 p-0 border-2 border-start-0 border-end-0 border-dark";
  return (props.index !== 0)
    ? null
    : <thead>
        <tr>
          <th colSpan={3} className="text-nowrap overflow-hidden text-secondary">Registrant Facts </th>
          <th></th>
          <th colSpan={policyColSpan} className="text-secondary">Policy</th>
          <th></th>
          <th colSpan={3} className="text-secondary">Registration</th>
          </tr>
        <tr className={cssRowStyle}>
          <th className="text-nowrap overflow-hidden">{groupName}</th>
          <th>Vrq</th>
          <th>Srq</th>
          <th className="border"></th>
          {rule_attributes}
          <th className="border"></th>
          <th>Value</th>
          <th>Val</th>
          <th>Sens</th>
          </tr>
        </thead>
  }

///////////////////////////////////////
// COLUMN: REGISTRANT FACT
///////////////////////////////////////

const styleRegistrationFactCell = {maxWidth: '175px', textOverflow: "ellipsis"};
function RegistrantFactCell (props: {groupID:number, element: APIElement, regFact: APIRegistrantFactItem, registrantFacts: RegistrantFactsStore, scope:React.ReactNode})
  {
  const [value, setValue] = React.useState<string>(props.regFact?.value ?? props.element?.name);

  // React.useEffect(() => { console.log("RegistrationValue", props); }, [props]);
  React.useEffect(() => props.registrantFacts?.updateFact(props.element, {value: value}), [value]);

  const toolTip = <Tooltip id={`factTT-${props.element.id}`}>{props.element.name}</Tooltip>
  return (props.groupID === GROUP_REGSCOPE)
    ? <td align="left" className="fst-italic overflow-hidden text-nowrap" style={styleRegistrationFactCell}>{props.scope}</td>
    : <td align="left" className="fst-italic overflow-hidden text-nowrap" style={styleRegistrationFactCell}><OverlayTrigger overlay={toolTip}><Form.Control id={`registrantFact${props.element.id}`} type="text" placeholder={props.element.name} value={value} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value)} /></OverlayTrigger></td>
  }

///////////////////////////////////////
// COLUMN: VALIDATION REQUEST
///////////////////////////////////////

function VrqCell (props: {element: APIElement, attribute: APIAttribute | undefined, regFact:APIRegistrantFactItem | undefined, definition:APIAttribute[], registrantFacts: RegistrantFactsStore,})
  {
  const value:number = parseInt(props.regFact?.V3RQ ?? ANY);
  const VrqChangeEvent = (newVal:number) => props.registrantFacts.updateFact(props.element, { V3RQ: String(newVal) });

  // TODO: if VAL is a range, restrict options within range of VAL

  return <td><DataBox ruleValue={value} attribute={props.attribute} onChange={VrqChangeEvent} /></td>
  }

///////////////////////////////////////
// COLUMN: SENSITIVITY REQUEST
///////////////////////////////////////

function SrqCell (props: {element: APIElement, attribute: APIAttribute | undefined, regFact:APIRegistrantFactItem | undefined, definition:APIAttribute[], registrantFacts: RegistrantFactsStore,})
  {
  const value: number = parseInt(props.regFact?.DG ?? ANY);
  const SrqChangeEvent = (newVal:number) => props.registrantFacts.updateFact(props.element, { DG: String(newVal) });

  // TODO: if SENS is a range, restrict options within range of SENS

  return <td><DataBox ruleValue={value} attribute={props.attribute} onChange={SrqChangeEvent} /></td>
  }

///////////////////////////////////////
// COLUMN: POLICY RULES
///////////////////////////////////////

const stylePolicyRuleCell = {backgroundColor: 'var(--bs-gray-200)'}

function PolicyRules (props: {rule:APIRule, definitions:APIAttribute[], policy: PolicyStore})
  {
  function eventChange (attID: number, newVal:number)
    {
    if (props.policy.data?.rules) props.policy.updateData(updatePolicyRuleAttributeValue(props.policy.data, props.rule.element_id, attID, newVal));
    }

  return props.definitions.map(att => <td key={`policyRuleCell-${att.id}`} style={stylePolicyRuleCell}><PolicyRuleCell key={`policyRuleCell-${att.id}`} rule={props.rule} attribute={att} onChange={(value:number) => eventChange(att.id, value)} /></td>);
  }

///////////////////////////////////////
// COLUMN: REGISTRATION VALUE
///////////////////////////////////////

const styleRegistrationValueCell = {maxWidth: '175px', textOverflow: "ellipsis"};
function RegistrationValue (props: {groupID:number, scope: React.ReactNode, regFact: APIRegistrantFactItem | undefined, collect: number})
  {
  // React.useEffect(() => { console.log("RegistrationValue", props); }, [props]);

  /**
   * Determines the appropriate value to display for a registration fact based on the collection status.
   *
   * @param props - An object containing the following properties:
   *   - groupID: The ID of the registration group.
   *   - scope: The scope of the registration.
   *   - regFact: The registration fact item.
   *   - collect: The collection status of the registration fact.
   * @returns The value to display for the registration fact.
   */
  const filterFact = () =>
    {
    const UNSET   = "ø";
    const MISSING = "MISSING";
    const EMPTY   = null;
    const FACT    = props.regFact?.value ?? null;
    if (props.groupID === GROUP_REGSCOPE) return props.scope;
    switch (props.collect)
      {
      case Collection.NULL: return UNSET;
      case Collection.COLLECT: return FACT ?? MISSING;
      case Collection.DONOTCOLLECT: return EMPTY;
      case Collection.OPTIONAL: return FACT ?? EMPTY;
      // todo: build other cases
      default: return EMPTY;
      }
    }

  return <td align="left" className="fst-italic overflow-hidden text-break" style={styleRegistrationValueCell}><small>{filterFact()}</small></td>
  }

///////////////////////////////////////
// COLUMN: REGISTRATION VALIDATION
///////////////////////////////////////

function RegistrationValidation (props: {collect:number, rule:APIRule, regFact: APIRegistrantFactItem | undefined, definition:APIAttribute})
  {
  // React.useEffect(() => { console.log("RegistrationValidation", props); }, [props]);

  function calculateValidation (rule: APIRule)
    {
    const Vrq: number = getAttributeFromRule(rule, REG_ATTRIBUTES.V3RQ)?.value ?? 0;
    const val: number = getAttributeFromRule(rule, RULE_ATTRIBUTES.VAL)?.value ?? 0;
    const v_dflt: number = getAttributeFromRule(rule, RULE_ATTRIBUTES.V3RQ)?.value ?? 0;
    const validation: number = isSingleton(val) ? val : Vrq === parseInt(ANY) ? v_dflt : isWithinRange(Vrq, [0, 2]) ? Vrq : v_dflt;
    return validation;
    }

  const regValidationValue = calculateValidation(props.rule);
  const validationValue = (props.collect === Collection.DONOTCOLLECT) ? null : props.definition.values?.filter((v) => v.value === regValidationValue).at(0)?.name ?? "";

  return <td><small>{validationValue}</small></td>
  }

///////////////////////////////////////
// COLUMN: REGISTRATION SENSITIVITY
///////////////////////////////////////

function RegistrationSensitivity (props: {collect:number, rule:APIRule, regFact: APIRegistrantFactItem | undefined, definition:APIAttribute})
  {
  // React.useEffect(() => { console.log("RegSensitivity", props); }, [props]);

  function calculateSensitivity (rule: APIRule)
    {
    const Srq: number = getAttributeFromRule(rule, REG_ATTRIBUTES.DG)?.value ?? 0;
    const sens: number = getAttributeFromRule(rule, RULE_ATTRIBUTES.SENS)?.value ?? 0;
    const s_dflt: number = getAttributeFromRule(rule, RULE_ATTRIBUTES.DG)?.value ?? 0;
    const sensitivity: number = isSingleton(sens) ? sens : Srq === parseInt(ANY) ? s_dflt : isWithinRange(Srq, [0, 2]) ? Srq : s_dflt;
    return sensitivity;
    }

  let regSensToDisplay = calculateSensitivity(props.rule);
  const sensitivityValue = (props.collect === Collection.DONOTCOLLECT) ? null : props.definition.values?.filter((v) => v.value === regSensToDisplay).at(0)?.name ?? "";

  return <td><small>{sensitivityValue}</small></td>
  }

