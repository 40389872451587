/* NODE PACKAGES */
import React from 'react';
import { Container, Row, Col, OverlayTrigger, Tooltip} from 'react-bootstrap';
/* TYPES */
import { APIAttribute, APIPolicy, APIRuleAttribute, APIRegistryList, Comparison} from 'api/types';
/* CUSTOM COMPONENTS */
import Scope from 'components/molecules/Toolbars/Scope';

interface PolicyScopeCalculatorProps
  {
  dictionary: APIAttribute[];
  policy: APIPolicy | null;
  comparisonPolicy: APIPolicy | null;
  updatePolicy: (newValues: Partial<APIPolicy>) => void;
  }

function ScopeCalculator (props: PolicyScopeCalculatorProps)
  {
  return (<Scope scopeAttributes={props.dictionary} selectedScopeAttributes={props.policy?.scope_attributes ?? []} comparisonScopeAttributes={props.comparisonPolicy?.scope_attributes} selectedTLD={props.policy?.tlds ?? null} comparisonTLD={props.comparisonPolicy?.tlds} defaultDropdown={1} onAttributeChange={(newScopeAtts: APIRuleAttribute[]) => props.updatePolicy({ scope_attributes: newScopeAtts })} onRegistryListChange={(newTLDList: APIRegistryList | null) => props.updatePolicy({ tlds: newTLDList, tld_list_id: newTLDList?.id ?? null })} />);
  };

export default ScopeCalculator;
