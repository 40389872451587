/* NODE PACKAGES */
import React from'react';
import {Container} from 'react-bootstrap';

const Footer: React.FC<{}> = () =>
  {
  return <footer className="d-print-none d-block m-0 px-0 py-5"><Container className="d-flex justify-content-between align-items-center gap-3">
    <div><small>2024 &copy; Edgemoor Research Institute</small></div>
    <div><a className="link-secondary" href="https://eri-md.github.io/ERI-MD/changelog.html" target="_blank" rel="noreferrer">Changelog</a></div>
    </Container></footer>
  }

export default Footer;
