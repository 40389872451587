/* NODE PACKAGES */
import React from 'react';
import { Nav, Navbar, Container} from 'react-bootstrap';
/* CUSTOM COMPONENTS */
import HelpGuideButton from 'components/molecules/Sheet/HelpGuide';
import { HashMode } from 'App';
//import {ReactComponent as ReactLogo} from 'assets/logo.svg';

interface NavigationBarProps
  {
  hashMode: HashMode
  }

function NavigationBar (props: NavigationBarProps)
  {
  const renderNavLinks = React.useMemo(() =>
    {
    const pages =
      [
      { label: 'Dashboard', hash: HashMode.default, disable: false},
      { label: 'Policies', hash: HashMode.policies, disable: false },
      { label: 'Requests', hash: HashMode.requests, disable: false},
      { label: 'Registrations', hash: HashMode.registrations, disable: false},
      { label: 'Registrars', hash: HashMode.registrars, disable: false},
      { label: 'Registries', hash: HashMode.registries, disable: false},
      ];

    return pages.map((item, index) => <Nav.Link key={`navlink_${index}`} active={props.hashMode === item.hash} disabled={item.disable} href={item.hash} className="m-0 p-2">{item.label}</Nav.Link>);
    }, [props.hashMode]);

  return <Navbar variant="dark" data-bs-theme="dark" className="d-print-none d-block position-sticky bg-dark border-top border-4 border-black top-0 m-0 px-0 py-2" style={{zIndex: 1045}}>
    <Container className="d-flex justify-content-between align-items-center gap-2">
      <Navbar.Brand href="/#/" className='pe-auto m-0 p-0'>
        {/* <div className="d-block position-relative fs-6 fw-lighter align-text-bottom m-0 p-0 border-0"><ReactLogo className='align-text-bottom m-0 me-2 p-0 border-0' height="30" width="60" /></div> */}
        <div className="d-block position-relative fs-4 fw-normal align-text-bottom m-0 p-0 border-0">Policy Editor</div>
        </Navbar.Brand>
      <Nav className="m-0 p-0" variant='dark'>
        {renderNavLinks}
        <HelpGuideButton />
        </Nav>
      </Container>
    {/* <SubnavBar hashMode={props.hashMode} /> */}
    </Navbar>
  }

export default NavigationBar;

/* Breadcrumbs
const SubnavBar: React.FC<{hashMode: HashMode}> = (props) =>
  {
  const renderBreadcrumbs = React.useMemo(() =>
    {
    const cssBreadcrumbLink = "link-success link-offset-2 link-underline-opacity-25 link-underline-opacity-75-hover";
    const cssSubnav = "d-flex justify-content-start align-items-center gap-2 text-success text-capitalize fw-medium";
    switch (props.hashMode)
      {
      case HashMode.policy: return <small className={cssSubnav}><a href={HashMode.default} className={cssBreadcrumbLink}>Dashboard</a> <span>&#8250;</span> <a className={cssBreadcrumbLink} href={HashMode.policy_list}>Policy List</a> <span>&#8250;</span> <span>Policy Editor (1.0)</span></small>
      case HashMode.registration: return <small className={cssSubnav}><a href="/#/" className={cssBreadcrumbLink}>Dashboard</a> <span>&#8250;</span> <a className={cssBreadcrumbLink} href={HashMode.registration_list}>Registration List</a> <span>&#8250;</span> <span>Registration Editor</span></small>
      case HashMode.registration_old: return <small className={cssSubnav}><a href="/#/" className={cssBreadcrumbLink}>Dashboard</a> <span>&#8250;</span> <a className={cssBreadcrumbLink} href={HashMode.registration_list}>Registration List</a> <span>&#8250;</span> <span>Registration Editor (old)</span></small>
      case HashMode.registrant_facts: return <small className={cssSubnav}></small>
      case HashMode.request_template: return <small className={cssSubnav}><a href="/#/" className={cssBreadcrumbLink}>Dashboard</a> <span>&#8250;</span> <a className={cssBreadcrumbLink} href="/#/request_template_list/">Request Template List</a> <span>&#8250;</span> <span>Request Template Editor</span></small>
      case HashMode.requester: return <small className={cssSubnav}><a href="/#/" className={cssBreadcrumbLink}>Dashboard</a> <span>&#8250;</span> <a className={cssBreadcrumbLink} href="/#/registrar_group_list/">Registrars List</a> <span>&#8250;</span> <span>Requester Editor</span></small>
      case HashMode.requester_group: return <small className={cssSubnav}><a href="/#/" className={cssBreadcrumbLink}>Dashboard</a> <span>&#8250;</span> <a className={cssBreadcrumbLink} href="/#/registrar_group_list/">Registrars List</a> <span>&#8250;</span> <span>Requester Group Editor</span></small>
      case HashMode.registrar: return <small className={cssSubnav}><a href="/#/" className={cssBreadcrumbLink}>Dashboard</a> <span>&#8250;</span> <a className={cssBreadcrumbLink} href="/#/registrar_group_list/">Registrars List</a> <span>&#8250;</span> <span>Registrar Editor</span></small>
      case HashMode.registrar_group: return <small className={cssSubnav}><a href="/#/" className={cssBreadcrumbLink}>Dashboard</a> <span>&#8250;</span> <a className={cssBreadcrumbLink} href="/#/registrar_group_list/">Registrars List</a> <span>&#8250;</span> <span>Registrar Group Editor</span></small>
      case HashMode.tld: return <small className={cssSubnav}><a href="/#/" className={cssBreadcrumbLink}>Dashboard</a> <span>&#8250;</span> <a className={cssBreadcrumbLink} href="/#/tld_list/">PSL List</a> <span>&#8250;</span> <span>PSL Editor</span></small>
      case HashMode.registration_list: return <small className={cssSubnav}><a href="/#/" className={cssBreadcrumbLink}>Dashboard</a> <span>&#8250;</span> <span>Registration List</span></small>
      case HashMode.registrar_group_list: return <small className={cssSubnav}><a href="/#/" className={cssBreadcrumbLink}>Dashboard</a> <span>&#8250;</span> <span>Registrars List</span></small>
      case HashMode.request_template_list: return <small className={cssSubnav}><a href="/#/" className={cssBreadcrumbLink}>Dashboard</a> <span>&#8250;</span> <span>Request Template List</span></small>
      case HashMode.tld_list: return <small className={cssSubnav}><a href="/#/" className={cssBreadcrumbLink}>Dashboard</a> <span>&#8250;</span> <span>PSL List</span></small>
      case HashMode.default: default: return <small className={cssSubnav}><a href="/#/" className={cssBreadcrumbLink}>Dashboard</a> <span>&#8250;</span> <span>Policy List</span></small>
      // case HashMode.default: default: return <small className={cssSubnav}>Dashboard</small>
      }
    }, [props.hashMode]);

  return <Container className="bg-light mx-auto my-0 px-0 py-0">{renderBreadcrumbs}</Container>
  }
/**/

/* Developer Notes:

@web/src/pages/NavigationBar.tsx:1-75 is a React component that renders a navigation bar and breadcrumbs for a web application. The purpose of this code is to provide a consistent navigation experience across different pages of the application.

The component takes an input prop called hashMode, which is a string representing the current mode or page of the application. This prop is used to determine which navigation links should be active and what breadcrumbs should be displayed.

The output of this component is a rendered navigation bar and breadcrumbs section. The navigation bar contains links to different pages of the application, and the breadcrumbs show the user's current location within the application's hierarchy.

To achieve its purpose, the code follows these steps:

It defines some constants for styling and formatting the navigation bar and breadcrumbs.
It uses the React.useMemo hook to render the navigation links based on the hashMode prop and a predefined list of pages.
It defines a function called renderBreadcrumbs that generates the breadcrumbs based on the hashMode prop. This function uses a switch statement to determine the appropriate breadcrumbs for each mode.
The component renders the navigation bar and breadcrumbs sections using Bootstrap components and the rendered navigation links and breadcrumbs.
The main logic flow in this code is the rendering of the navigation links and breadcrumbs based on the hashMode prop. The React.useMemo hook is used to memoize the rendering of these elements, which means they will only be re-rendered if their dependencies (in this case, the hashMode prop) change.

There are no significant data transformations happening in this code. The main purpose is to generate the appropriate navigation links and breadcrumbs based on the current application mode.

Overall, this code provides a reusable and dynamic way to display navigation elements in a web application, allowing users to easily navigate between different pages and understand their current location within the application's structure.

*/